import axios from 'axios';

class APIService {
  constructor() {
    this.axios = axios;
    this.config = {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    };
  }
  async postAPIRequest(url, data) {
    try {
      const responseData = await this.axios.post(url, data, this.config);
      return responseData;
    } catch (error) {
      console.error('There is a problem with the request', error);
      return error;
    }
  }

  async getAPIRequest(url) {
    try {
      return await this.axios.get(url, this.config);
    } catch (error) {
      console.error('There is a problem with the request', error);
      return error;
    }
  }
}

export default new APIService();
