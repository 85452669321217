import store from '@/simpleStore';
import apiService from '@/components/service/api-service';
import { ENROLLMENT_PATH } from '@/constants';

const enrollPpt = async () => {
  const errorObj = { status: 'error' };

  try {
    const apiResponse = await apiService.postAPIRequest(
      ENROLLMENT_PATH,
      store.getEligibleInformation()
    );
    const hasResponse = apiResponse && apiResponse.data;

    return hasResponse ? { status: apiResponse.data.StatusCode } : errorObj;
  } catch {
    return errorObj;
  }
};

export default enrollPpt;
