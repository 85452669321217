<template>
  <StatusCardContainer>
    <MdsLoader size="large" />
  </StatusCardContainer>
</template>

<script>
import StatusCardContainer from './StatusCardContainer';
import MdsLoader from '@mds/loader';

export default {
  components: {
    StatusCardContainer,
    MdsLoader
  }
};
</script>
