class LocalStorageService {
  setItem(key, value) {
    window.localStorage.setItem(key, JSON.stringify(value));
  }
  getItem(key) {
    return window.localStorage.getItem(key)
      ? JSON.parse(window.localStorage.getItem(key))
      : null;
  }
  clear() {
    window.localStorage.clear();
  }
}
export default new LocalStorageService();
