export default {
  content: null,
  programFee: null,
  showConfirmEnrollmentModal: false,
  showLoader: false,
  config: {
    heroText: '',
    clientCustomization: null,
    env: null
  },
  savingRecommendation: {
    showLoader: false,
    savingRateDetail: null
  },
  isEnrollButtonDisable: false,
  proposalAdvice: {},
  incomeProjectionDataAvailable: false,
  ages: '[]',
  recaptchaV2: false
};
