export default `
<p class="paragraph-small-custom margin-3-x-top important-note">
  <strong>Important Note</strong>:  This website uses a streamlined process to enroll you in our retirement planning service and may not consider all information relevant to your financial situation. You can access our full enrollment process by logging into the Blueprint Managed Advice website. The full enrollment process allows you to provide us with additional information about your retirement situation and goals so that we can further customize your retirement strategy. If you have additional assets outside your employer’s retirement plan, have a spouse or partner you’d like us to consider, want to restrict certain securities from being used in your retirement account, or want to change the suggestions we’ve made for you here or see how changes would impact your retirement strategy, we encourage you to use our full enrollment process instead of the streamlined process.
</p>
<p class="paragraph-small-custom">
  The level of strategy customization you receive depends on the amount of information you share with us. The streamlined process takes into account your age, retirement plan type, and the balance, fund allocation, and contributions for your retirement plan account as provided by your retirement plan’s plan provider. We assume a retirement age of 65.
</p>
<div class="custom-methodology">
  <h2 class="section-header4 margin-top-none nopadding-top title">Your Strategy Using This Site</h2>
  <h4 class="paragraph-margin-12">Principles</h4>
  <p class="paragraph-small-custom paragraph-margin-12 intropara1">
    Blueprint Managed Advice (or "Managed Acccounts" and if applicable, "Online Advice") is a retirement planning service offered through your employer's retirement plan to help you make more informed decisions about investing your retirement account. Unless otherwise noted herein, all references to Managed Accounts also apply to Online Advice. The fundamental principles of Managed Accounts are:
  </p>
  <ul class="list margin-top-full">
    <li>
      <p class="paragraph-small2 paragraph-margin-0 personalizedheader"><strong>Personalized:</strong></p>
      <p class="paragraph-small2 paragraph-margin-12 personalizedpara1">
        The primary objective of Managed Accounts is to help you reach your desired retirement income goal by furnishing you with a personalized strategy on asset allocation, investments, savings, and retirement age. Your strategy is tailored to your specific circumstances, including financial situation, future retirement goals, and risk capacity (the amount of risk you may want to take to help reach your goals).
      </p>
    </li>
    <li>
      <p class="paragraph-small2 paragraph-margin-0 goalsbasedheader"><strong>Goals-Based:</strong></p>
      <p class="paragraph-small2 paragraph-margin-12 goalsbasedpara1">
        A prudent strategy must be built in relation to specific goals, and Managed Accounts helps you define those goals and develop a strategy aimed at reaching them.
      </p>
    </li>
    <li><p class="paragraph-small2 paragraph-margin-0 forwardlookingheader"><strong>Forward-Looking:</strong></p>
        <p class="paragraph-small2 paragraph-margin-12 forwardlookingpara1">
          Rather than relying only on historical data (which may or may not have any relevance to future conditions), Managed Accounts incorporates forward-looking estimates for assumptions about investment returns and performance behavior.
        </p>
    </li>
  </ul>

  <h4 class="feature-header4 rmageheader">Retirement Age</h4>
  <p class="paragraph-small-custom rmagepara1">
    Managed Accounts assumes a default retirement age of your "Full Retirement Age," as defined by the Social Security Administration. Your Full Retirement Age depends on your birthday or your current age plus one year if you are older than your Full Retirement Age.
  </p>
  <p class="paragraph-small-custom rmagepara2">
    Managed Accounts defines retirement age as the age at which you will begin withdrawing money from your primary Employer-sponsored retirement plan or retirement account. 
  </p>
  <p class="paragraph-small-custom rmagepara3">
    One effective way to improve your chances of meeting your retirement goal is to delay your retirement. That’s because delaying retirement allows your assets to stay invested longer and it enables you to continue contributing to your account. As part of your strategy, Managed Accounts may recommend that you delay your retirement by two or more years to help you meet your retirement goal.
  </p>

  <h4 class="feature-header4 salarygrowthheader">Salary Growth</h4>
  <p class="paragraph-small-custom salarygrowthpara1">
    To estimate your future salary, Managed Accounts uses a "salary growth curve" based on academic research rather than assuming a single, fixed growth rate (for example, 5% per year). This curve takes into account the fact that salaries tend to grow most rapidly for young employees, peak around age 51 and then slightly decline later in life.
  </p>

  <h4 class="feature-header4 esttaxheader">Estimated Tax</h4>
  <p class="paragraph-small-custom esttaxpara1">
    Managed Accounts estimates your federal, state income, and capital gains taxes based on marginal tax rate calculations (the marginal tax rate is the rate you pay on the taxable income that falls into the highest bracket you reach). These calculations are used when conducting income simulations. Tax data is updated annually based on United States Internal Revenue Code (IRC) and similar state tax data. Managed Accounts uses income data for you, as well as your spouse/partner, to estimate federal and state tax exposure. Your tax exposure is appropriately reduced for pre-tax deferrals, tax-deferred capital gains, and yield and distribution of Roth proceeds. Based on the information you provide, you’ll receive an estimate of your tax exposure, but it may not include all tax considerations. Please consult a tax adviser for a complete understanding of your tax situation.
  </p>

  <h4 class="feature-header4 inflationasmheader">Inflation Assumptions</h4>
  <p class="paragraph-small-custom inflationasmpara1">
    When projecting the growth of your various income sources (such as pension, Social Security, outside account balances, etc.), and expenses, Managed Accounts uses a variety of different inflation rates. These rates are set by our investment research team. Different inflation rates are used for different projections. For instance, Managed Accounts uses a long-term inflation rate to help calculate retirement need and cash flows, and a simulated inflation rate for Social Security calculations, pensions, and cost of living adjustments.
  </p>

  <h4 class="feature-header4 irslapheader">IRS Limitations and Application of Penalties</h4>
  <p class="paragraph-small-custom irslappara1">
    The IRS establishes rules for tax-deferred, tax-free, and taxable investment accounts. These rules define the maximum contribution rates and amounts, eligibility for contributing to an account type, and penalties for withdrawal. Managed Accounts incorporates these IRS contribution limits, eligibility requirements, and withdrawal penalties into its retirement strategies.
  </p>

  <h4 class="feature-header4 savingstrategyheader">Savings Strategy</h4>
  <p class="paragraph-small-custom savingstrategynote">
    The strategy provided by Managed Accounts exemplify the belief that increasing the amount you save in your retirement account is one of the most effective ways to improve the probability that you will reach your retirement goal; therefore, whenever possible, your proposed strategy will include a recommendation for an optimal savings allocation. Managed Accounts may recommend an increase in your savings rate to help you get closer to your retirement goal.
  </p>

  <h4 class="feature-header4 riskstrategyheader">Risk Strategy</h4>
  <p class="paragraph-small-custom riskstrategypara1">
    Based on the information you have provided, Managed Accounts assigns you to one of 19 possible asset mixes (the combination of stocks, bonds, and cash) ranging from 97% stock to 10% stock.
  </p>
  <p class="paragraph-small-custom riskstrategypara2">
    To determine your risk capacity, Managed Accounts uses a concept called human capital. This helps determine an appropriate target risk level for your retirement portfolio by considering your risk exposure in all your other accounts that you've added to the site. The human capital methodology accounts for an investor’s financial capital (total saved assets and tradable assets such as stocks and bonds) as well as their human capital (future earnings and savings potential). Using this methodology, Managed Accounts can assign a target risk level that is based on your total economic worth rather than just on a subjective assessment, such as a risk tolerance questionnaire.
  </p> 
  <p class="paragraph-small-custom riskstrategypara3">
    In general, human capital is a large percentage of total wealth for younger investors, which means attaining the overall market portfolio allocation (the optimal portfolio for every investor based upon each asset’s current market value) requires younger investors to allocate their financial portfolio more heavily in equities. As the investor ages, the human capital portion of total wealth declines, which means that older investors should consider investing their financial portfolios more heavily in fixed-income investments, resulting in a more conservative risk capacity.
  </p>

  <h4 class="feature-header4 investstrategyheader">Investment Strategy</h4>
  <p class="paragraph-small-custom investstrategypara1">
    Managed Accounts will build a portfolio of investments designed to meet your assigned asset mix while using funds available in your plan to do so.
  </p>
  <p class="paragraph-small-custom investstrategypara2">
    You may place reasonable restrictions on your account by contacting us, however, at a minimum,the following broad asset classes must be available: U.S. core/core plus bond, U.S.stock, and international stock.
  </p>
  <p class="paragraph-small-custom investstrategypara3">
    Plan-specific portfolios will be built in a manner consistent with the pillars of Modern Portfolio Theory, prevailing and prudent investment management best practices, and the Plan's Investment Policy Statement (IPS). The Plan’s Designated Investment Alternatives (DIAs) will be utilized to create and maintain risk-based models that range from 97% equity at the most aggressive to 10% equity at the most conservative. The models will be monitored and updated as needed based on changes to market conditions, the Plan’s DIAs, and/or the Plan’s IPS.
  </p>

  <h4 class="feature-header4 comstockstrategyheader">Company Stock Strategy</h4>
  <p class="paragraph-small-custom comstockstrategypara1">
    Managed Accounts will have no responsibility for company stock, although retirement income projections will take into account any allocation to company stock. If your account includes a company stock position, Managed Accounts will model it using the stock’s ticker. The ticker tells us the exact investment category (large, mid or small) for that specific stock. Additionally, Managed Accounts assumes that all company stock has a standard deviation (a measure of a fund’s risk) of twice its category. Managed Accounts models it this way because this may more accurately mirror the risk associated with holding company stock.
  </p>
  
  <h4 class="feature-header4 monitoringrebalancingheader">Monitoring and Rebalancing</h4>
  <p class="paragraph-small"><strong>Managed Accounts Only:</strong></p>
  <p class="paragraph-small-custom monitoringrebalancingpara1">
    If you have selected the Managed Account service option, your account will be periodically reviewed (typically quarterly), and when necessary, your investment positions will be rebalanced to the asset allocation targets. Your portfolio allocations will be adjusted annually, taking into account your change in age and any other significant personal or financial changes to your situation that you have added to the site. Managed Accounts’ methodology has a built-in mechanism to help prevent unnecessary trading on your part. Therefore, if it’s determined that any adjustments to your strategy are relatively small and fall within a predetermined range, then no changes to your investment allocations will be proposed. If the changes are larger,then an updated strategy may be proposed.
  </p>
  <p class="paragraph-small"><strong>Online Advice Only:</strong></p>
  <p class="paragraph-small-custom monitoringrebalancingpara1">
    If you have selected the Online Advice service option, it’s recommended that you return to the site at least once a year to receive an updated strategy, or sooner if you have had any significant changes in your personal or financial situation, or if there has been a change in available investment options in your plan lineup. Online Advice does not adjust portfolio allocations automatically.
  </p>

  <h4 class="feature-header4 disclosureheader">Important Disclosure Information</h4>
  <p class="paragraph-small-custom disclosurepara1">
    The methodologies described in this document are the proprietary material of Morningstar Investment Management LLC, a registered investment adviser and subsidiary of Morningstar, Inc., and Managed Accounts third-party technology provider. Under an agreement with your retirement plan sponsor,CapFinancial Partners, Inc. (“CAPTRUST”), a registered investment adviser, is responsible for creating the portfolios that are available to you. As a part of the Managed Accounts service, CAPTRUST is also responsible for assigning you to a specific portfolio. Morningstar Investment Management and CAPTRUST (unaffiliated with Morningstar Investment Management) are each individually responsible for performance of their respective duties, and are not responsible for actions of the other party. Please ask CAPTRUST for additional information, including a copy of their Form ADV Part 2 disclosure document by contacting <a href="mailto:compliance@captrust.com">compliance@captrust.com</a>, calling toll-free (800) 216-0645 or accessing <a href="https://www.adviserinfo.sec.gov/IAPD/Default.aspx" target="_blank">https://www.adviserinfo.sec.gov/IAPD/Default.aspx</a>. Form ADV part 2 for Morningstar Investment Management can be found at <a href="https://www.adviserinfo.sec.gov/IAPD/Default.aspx" target="_blank">https://www.adviserinfo.sec.gov/IAPD/Default.aspx</a>. Managed Accounts are intended for citizens or legal residents of the United States or its territories.
  </p>
  <p class="paragraph-small-custom disclosurepara2">
    Investment advice delivered through Managed Accounts is based on the information provided about you and limited to the investment options available in your defined contribution plan. Projections and other information regarding the likelihood of various retirement income and/or investment outcomes are hypothetical in nature, do not reflect actual results, and are not guarantees of future results. Results may vary with each use and over time. All investments involve risk, including the loss of principal. There can be no assurance that any financial strategy will be successful.
  </p>
  <p class="paragraph-small-custom disclosurepara3">
    Reproduction, transcription, or other use of the information in this document, by any means, in whole or in part, without the prior written consent of Morningstar Investment Management, is prohibited. Opinions expressed are as of the current date; such opinions are subject to change without notice. Morningstar Investment Management nor CAPTRUST shall not be responsible for any trading decisions, damages, or other losses resulting from, or related to, the information, data, analyses or opinions or their use outside of Managed Accounts. Past performance does not guarantee future results. Morningstar Investment Management nor CAPTRUST guarantees that the results of their methodologies or the objectives of a strategy will be achieved.
  </p>
  <p class="paragraph-small-custom disclosurepara4">
    Assumptions involve known and unknown risks, uncertainties and other factors which may cause actual results to differ materially and/or substantially from any expected future result, performance or achievement expressed or implied by those assumptions for any reason.
  </p>
  <p class="paragraph-small-custom disclosurepara5">
    Asset allocation and diversification are investment methodologies that do not ensure a profit or protect against a loss in a declining market.
  </p>
</div>

<div class="rm-methodology">
  <h2 class="section-header4 margin-top-none nopadding-top title">Your Strategy Using Blueprint Managed Advice</h2>
  <h4 class="paragraph-margin-12">Principles</h4>

  <p class="paragraph-small-custom paragraph-margin-12 intropara1">
    This retirement planning service (“Blueprint Managed Advice” or “Managed Accounts”, and if applicable, Online Advice) is offered through your employer's retirement plan to help you make more informed decisions about investing your retirement account. (Unless otherwise noted herein, all references to Managed Accounts also apply to Online Advice.) The fundamental principles of Managed Accounts are:
  </p>
  <ul class="list margin-top-full">
    <li>
      <p class="paragraph-small2 paragraph-margin-12 personalizedpara1">
        <strong>Personalized:</strong> The primary objective of Managed Accounts is to help you reach your desired retirement income goal by furnishing you with a personalized strategy on asset allocation, investments, savings, and retirement age. Your strategy is tailored to your specific circumstances, including financial situation, future retirement goals, and risk capacity (the amount of risk you may want to take to help reach your goals).
      </p>
    </li>
    <li>
      <p class="paragraph-small2 paragraph-margin-12 goalsbasedpara1">
        <strong>Goals-Based:</strong> A prudent strategy must be built in relation to specific goals, and Managed Accounts helps you define those goals and develop a strategy aimed at reaching them.
      </p>
    </li>
    <li>
      <p class="paragraph-small2 paragraph-margin-12 forwardlookingpara1">
        <strong>Forward-Looking:</strong> Rather than relying only on historical data (which may or may not have any relevance to future conditions), Managed Accounts incorporates forward-looking estimates for assumptions about investment returns and performance behavior.
      </p>
    </li>
  </ul>

  <h4 class="feature-header4 draheader">Data and Related Assumptions</h4>

  <p class="paragraph-small-custom drapara1">
    While Managed Accounts may help you build a strategy for your retirement account, the appropriateness of the advice you receive is dependent on the personal information received from you, your employer, and your account administrator. While Managed Accounts strives to provide the most accurate and timely economic forecast and financial information, it’s important for you to provide the most accurate assessment of your financial status and goals.
  </p>
  <p class="paragraph-small-custom drapara2">
    In the questionnaire and profile section of the site, you’ll be asked to supply relevant personal and financial data about yourself (and, if applicable, your spouse or partner). To build a comprehensive strategy, you should provide as much information about your financial situation as possible.
  </p>
  <p class="paragraph-small-custom drapara3">
    This information may include (but is not limited to) your age, salary, state of residence, current savings rate, Employer Contributions (if applicable), balance of your retirement account, any outstanding loans from your retirement plan, balances and contributions to any other investment accounts intended for retirement, expected pensions, future incoming and outgoing cash flows, and balances in company stock.
  </p>
  <p class="paragraph-small-custom drapara4">
    Your account administrator may provide some of these data points on your behalf. However, you are strongly encouraged to review this information for accuracy and provide comparable information about your spouse/partner, as well as any information about your assets held outside your retirement plan, pensions, and cash flows, etc. This will help further personalize your advice to your unique financial situation. Additionally, you can further personalize your advice by providing information about your anticipated income sources in retirement (such as a part-time job), as well as any major expenses that you foresee incurring during your retirement. Those expenses could include such things as college tuition, healthcare costs, a vacation, or a wedding. Please note, though, that the advice provided today is for your retirement account(s) only. It is not designed to help you save toward any other savings or financial goal.
  </p>
  <p class="paragraph-small-custom drapara5">
    Managed Accounts makes assumptions about the information you and/or your account administrator provide. These assumptions have a significant impact on your strategy. In particular, these assumptions relate to Social Security income, potential salary growth, inflation rates, retirement income goal, and your risk capacity.
  </p>

  <h4 class="feature-header4 ssheader">Social Security</h4>

  <p class="paragraph-small-custom sspara1">
    You (and, if applicable, your spouse or partner) will receive a Social Security estimate based on calculations/formulas from the Social Security Administration (SSA). You’re defaulted to the age at which you will receive full benefits from the SSA. For people born after 1959, the default age is 67. In determining your projected retirement income, Managed Accounts’ modeling also accounts for any additional SSA benefits you might receive, such as survivor benefits. If you choose to lower the default start age, the dollar amount for your Social Security estimate will decrease. Likewise, if you raise the start age above the default, the estimate may increase. However, your start age must be between the ages of 62 and 70. You do have the ability to input your own estimate.
  </p>
  <p class="paragraph-small-custom sspara2">
    Deciding when to begin receiving benefits is a complex and personal decision. You can learn about the benefits you would receive at different ages by checking your Social Security statement or visiting the SSA online at <a href="http://www.ssa.gov" target="_blank">www.ssa.gov</a>. Social Security benefits are not automatically distributed; you must apply for the benefits. Managed Accounts assumes that you or your spouse/partner will complete all applications required to collect any benefit.
  </p>

  <h4 class="feature-header4 rmageheader">Retirement Age</h4>

  <p class="paragraph-small-custom rmagepara1">
    Managed Accounts assumes a default retirement age of your "Full Retirement Age," as defined by the Social Security Administration. Your Full Retirement Age depends on your birthday or your current age plus one year if you are older than your Full Retirement Age. You have the option to change this to a different retirement age. Managed Accounts defines retirement age as the age at which you will begin withdrawing money from your primary Employer-sponsored retirement plan or retirement account.
  </p>

  <h4 class="feature-header4 salarygrowthheader">Salary Growth</h4>

  <p class="paragraph-small-custom salarygrowthpara1">
    To estimate your future salary, Managed Accounts uses a "salary growth curve" based on academic research rather than assuming a single, fixed growth rate (for example, 5% per year). This curve takes into account the fact that salaries tend to grow most rapidly for young employees, peak around age 51 and then slightly decline later in life.
  </p>

  <h4 class="feature-header4 esttaxheader">Estimated Tax</h4>

  <p class="paragraph-small-custom esttaxpara1">
    Managed Accounts estimates your federal, state income, and capital gains taxes based on marginal tax rate calculations (the marginal tax rate is the rate you pay on the taxable income that falls into the highest bracket you reach). These calculations are used when conducting income simulations. Tax data is updated annually based on United States Internal Revenue Code (IRC) and similar state tax data. Managed Accounts uses income data for you, as well as your spouse/partner, to estimate federal and state tax exposure. Your tax exposure is appropriately reduced for pre-tax deferrals, tax-deferred capital gains, and yield and distribution of Roth proceeds. Based on the information you provide, you’ll receive an estimate of your tax exposure, but it may not include all tax considerations. Please consult a tax adviser for a complete understanding of your tax situation.
  </p>

  <h4 class="feature-header4 inflationasmheader">Inflation Assumptions</h4>

  <p class="paragraph-small-custom inflationasmpara1">
    When projecting the growth of your various income sources (such as pension, Social Security, outside account balances, etc.), and expenses, Managed Accounts uses a variety of different inflation rates. Different inflation rates are used for different projections. For instance, Managed Accounts uses a long-term inflation rate to help calculate retirement need and cash flows, and a simulated inflation rate for Social Security calculations, pensions, and cost of living adjustments. Additionally, different inflation rates may be used for major expenses. For example, if you wish to include college expenses in your retirement strategy, a different inflation rate will be used to project future college costs because tuition increases are expected to outpace inflation. This approach is based on the belief that using different rates to calculate inflation results in more realistic and accurate projections than using one set rate.
  </p>

  <h4 class="feature-header4 irslapheader">IRS Limitations and Application of Penalties</h4>

  <p class="paragraph-small-custom irslappara1">
    The IRS establishes rules for tax-deferred, tax-free, and taxable investment accounts. These rules define the maximum contribution rates and amounts, eligibility for contributing to an account type, and penalties for withdrawal. Managed Accounts incorporates these IRS contribution limits, eligibility requirements, and withdrawal penalties into its retirement strategies.
  </p>

  <h4 class="feature-header4 rmincomegoalheader">Retirement Income Goal</h4>

  <p class="paragraph-small-custom rmincomegoalpara1">
    On the chart on the main dashboard page, you’ll see a default retirement income goal. Your retirement income goal is the amount of income that Managed Accounts projects you will need to achieve your desired retirement lifestyle. This goal is determined by asking you a question when you first enter the site about the desired lifestyle that you want to lead. You have the choice of selecting 80%, 100%, or 120% (thrifty, comfortable, and lavish) of your projected post-tax, post-contribution salary (or take-home salary) at retirement, expressed in today’s dollars (if you have a spouse/ partner, Managed Accounts takes 80%, 100%, or 120% of the combined salaries.). Your salary at retirement is determined based on the salary curve methodology described above. If you believe a different income goal is more appropriate, you can change it, either by selecting a different salary replacement percentage or a specific dollar amount.
  </p>

  <h4 class="feature-header4 incomeprojheader">Income Projections</h4>

  <p class="paragraph-small-custom incomeprojpara1">
    Your income projection is the level of annual income that Managed Accounts projects you have at least a 70% chance of achieving. We provide an income projection for both your current strategy as well as our proposed strategy. The projection on your current strategy assumes that you will gradually reduce your equity exposure over time, making your portfolio more conservative. Additionally, it assumes that you won’t change your current saving rate.
  </p>
  <p class="paragraph-small-custom incomeprojpara2">
    When projecting your income, Managed Accounts forecasts investment returns, portfolio risk (measured by standard deviation), and correlation (the degree to which one asset class moves in tandem with others) for 12 asset classes. (Asset classes include cash, stocks, and bonds; stocks and bonds are subdivided into 11 more specific subcategories.) Your Plan may not have exposure to all 12 asset classes and/or all available assets classes in your Plan may not be utilized in Managed Accounts. We use an average expense ratio for each asset class to estimate investment fees.  (An expense ratio is the annual fee all funds charge their shareholders.  It expresses the percentage of assets deducted each fiscal year for fund expenses including management fees, administrative fees, operating costs, and all other asset-based costs incurred by the fund.) The projections also consider different scenarios for your life span, based on standard published mortality tables (based on the Society of Actuaries Individual Annuity Mortality (IAM) table). We assume that your risk capacity (and corresponding asset allocation) will change over time, generally growing more conservative as you approach retirement.
  </p>

  <h4 class="feature-header4 savingstrategyheader">Savings Strategy</h4>

  <p class="paragraph-small-custom savingstrategynote">
    <em>Note: A savings strategy will not be presented if you no longer work for the Employer that sponsors your retirement account(s). However, Managed Accounts may recommend additional savings to an outside taxable account to help you meet your retirement goal.</em>
  </p>
  <p class="paragraph-small-custom savingstrategypara1">
    The strategy provided by Managed Accounts exemplify the belief that increasing the amount you save in your retirement account is one of the most effective ways to improve the probability that you will reach your retirement goal; therefore, whenever possible, your proposed strategy or strategies will include a recommendation for an optimal savings allocation. Managed Accounts may recommend an increase in your savings rate to help you get closer to your retirement goal.
  </p>
  <p class="paragraph-small-custom savingstrategypara2">
    If you enter a savings rate that exceeds a plan or federal limit, the savings rate or amount that exceeds the limit will be reduced to the limit and the new savings rate will become the current savings rate.
  </p>
  <p class="paragraph-small-custom savingstrategypara3">
    It's recommended that you maximize your employer contribution or match whenever possible to take advantage of compensation you'd otherwise be leaving on the table. Accordingly, your initial recommendation will bump up your savings rate to at least make sure you're getting all of your eligible match dollars. Now, if your employer's contribution or match is discretionary (has the potential to change every year), you’ll need to come back every year and update the value in order for the projections and advice to be the most accurate. If you don't update the value, Managed Accounts will assume it was the value you last provided, and that value will be used in projections and advice until you update the value.
  </p>
  <p class="paragraph-small-custom savingstrategypara4">
    Employers have an option of offering one of two strategies within the site—"Make Small Changes" (which is the default strategy) and "Make Big Changes". In the Small Changes strategy, if you are already saving at or above the company match, Managed Accounts will propose a savings rate increase of 2%. Employer and federally mandated contribution limits are always taken into account. If you are enrolled in a managed savings program, your projections and proposed strategy will take into account your planned future increases to your savings rate.
  </p>
  <p class="paragraph-small-custom savingstrategypara5">
    In the Big Changes strategy, Managed Accounts will recommend a savings rate that, when combined with retirement age and asset allocation, will help you meet your retirement goal. Managed Accounts will never recommend a savings rate in excess of 25% of your salary; however, if your current savings rate exceeds 25%, Managed Accounts will continue to use your defined savings rate.
  </p>

  <h4 class="feature-header4 rmagestrategyheader">Retirement Age Strategy</h4>

  <p class="paragraph-small-custom rmagestrategypara1">
    Another effective way to improve your chances of meeting your retirement goal is to delay your retirement. That’s because delaying retirement allows your assets to stay invested longer and it enables you to continue contributing to your account. As part of your strategy, Managed Accounts may recommend that you delay your retirement by two or more years to help you meet your retirement goal.
  </p>

  <h4 class="feature-header4 riskstrategyheader">Risk Strategy</h4>

  <p class="paragraph-small-custom riskstrategypara1">
    Based on the information you have provided, Managed Accounts assigns you to one of 19 possible asset mixes (the combination of stocks, bonds, and cash) ranging from 97% stock to 10% stock.
  </p>
  <p class="paragraph-small-custom riskstrategypara2">
    To determine your risk capacity, Managed Accounts uses a concept called human capital. This helps determine an appropriate target risk level for your retirement portfolio by considering your risk exposure in all your other accounts that you've added to the site. The human capital methodology accounts for an investor’s financial capital (total saved assets and tradable assets such as stocks and bonds) as well as their human capital (future earnings and savings potential). Using this methodology, Managed Accounts can assign a target risk level that is based on your total economic worth rather than just on a subjective assessment, such as a risk tolerance questionnaire.
  </p> 
  <p class="paragraph-small-custom riskstrategypara3">
    In general, human capital is a large percentage of total wealth for younger investors, which means attaining the overall market portfolio allocation (the optimal portfolio for every investor based upon each asset’s current market value) requires younger investors to allocate their financial portfolio more heavily in equities. As the investor ages, the human capital portion of total wealth declines, which means that older investors should consider investing their financial portfolios more heavily in fixed-income investments, resulting in a more conservative risk capacity.
  </p>
  <p class="paragraph-small-custom riskstrategypara4">
    The target risk level changes over time to help ensure you are still investing in an optimal portfolio for your specific situation and risk capacity. In general, Managed Accounts tries to provide a smooth transition from an aggressive equity portfolio to a more conservative fixed portfolio as you near retirement.
  </p>

  <h4 class="feature-header4 investstrategyheader">Investment Strategy</h4>

  <p class="paragraph-small-custom investstrategypara1">
    Managed Accounts will build a portfolio of investments designed to meet your assigned asset mix while using funds available in your plan to do so. You may place reasonable restrictions on your account by contacting us, however, at a minimum, the following broad asset classes must be available: U.S. core/core plus bond, U.S. stock, and international stock.
  </p>
  <p class="paragraph-small-custom investstrategypara2">
    Plan-specific portfolios will be built in a manner consistent with the pillars of Modern Portfolio Theory, prevailing and prudent investment management best practices, and the Plan's Investment Policy Statement (IPS). The Plan’s Designated Investment Alternatives (DIAs) will be utilized to create and maintain risk-based models that range from 97% equity at the most aggressive to 10% equity at the most conservative. The models will be monitored and updated as needed based on changes to market conditions, the Plan’s DIAs, and/or the Plan’s IPS. <em>Note: If you are no longer employed by the company sponsoring this retirement account, Managed Accounts will not propose any future allocation as it is not applicable</em>.
  </p>

  <h4 class="feature-header4 comstockstrategyheader">Company Stock Strategy</h4>

  <p class="paragraph-small-custom comstockstrategypara1">
    Managed Accounts will have no responsibility for company stock, although retirement income projections will take into account any allocation to company stock. If your account includes a company stock position, Managed Accounts will model it using the stock’s ticker. The ticker tells us the exact investment category (large, mid or small) for that specific stock. Additionally, Managed Accounts assumes that all company stock has a standard deviation (a measure of a fund’s risk) of twice its category. Managed Accounts models it this way because this may more accurately mirror the risk associated with holding company stock.
  </p>

  <h4 class="feature-header4 monitoringrebalancingheader">Monitoring and Rebalancing</h4>

  <p class="paragraph-small"><strong>Managed Accounts Only:</strong></p>
  <p class="paragraph-small-custom monitoringrebalancingpara1">
    If you have selected the Managed Account service option, your account will be periodically reviewed (typically quarterly), and when necessary, your investment positions will be rebalanced to the asset allocation targets. Your portfolio allocations will be adjusted annually, taking into account your change in age and any other significant personal or financial changes to your situation that you have added to the site.
  </p>
  <p class="paragraph-small-custom monitoringrebalancingpara2">
    Managed Accounts’ methodology has a built-in mechanism to help prevent unnecessary trading on your part. Therefore, if it’s determined that any adjustments to your strategy are relatively small and fall within a predetermined range, then no changes to your investment allocations will be proposed. If the changes are larger, then an updated strategy may be proposed.
  </p>

  <p class="paragraph-small"><strong>Online Advice Only:</strong></p>
  <p class="paragraph-small-custom monitoringrebalancingpara3">
    If you have selected the Online Advice service option, it’s recommended that you return to the site at least once a year to receive an updated strategy, or sooner if you have had any significant changes in your personal or financial situation, or if there has been a change in available investment options in your plan lineup. Online Advice does not adjust portfolio allocations automatically.
  </p>

  <h4 class="feature-header4 disclosureheader">Important Disclosure Information</h4>

  <p class="paragraph-small-custom disclosurepara1">
    The methodologies described in this document are the proprietary material of Morningstar Investment Management LLC, a registered investment adviser and subsidiary of Morningstar, Inc., and Managed Accounts third-party technology provider. Under an agreement with your retirement plan sponsor, CapFinancial Partners, Inc. (“CAPTRUST”), a registered investment adviser, is responsible for creating the portfolios that are available to you. As a part of the Managed Accounts service, CAPTRUST is also responsible for assigning you to a specific portfolio. Morningstar Investment Management and CAPTRUST (unaffiliated with Morningstar Investment Management) are each individually responsible for performance of their respective duties, and are not responsible for actions of the other party. Please ask CAPTRUST for additional information, including a copy of their Form ADV Part 2 disclosure document by contacting <a href="&#109;&#97;&#105;&#108;&#116;&#111;&#58;&#99;&#111;&#109;&#112;&#108;&#105;&#97;&#110;&#99;&#101;&#64;&#99;&#97;&#112;&#116;&#114;&#117;&#115;&#116;&#46;&#99;&#111;&#109;">compliance&#64;captrust.com</a>, calling toll-free (800)216-0645 or accessing <a href="https://www.adviserinfo.sec.gov/IAPD/Default.aspx" target="_blank">https://www.adviserinfo.sec.gov/IAPD/Default.aspx</a>. Form ADV part 2 for Morningstar Investment Management can be found at <a href="https://www.adviserinfo.sec.gov/IAPD/Default.aspx" target="_blank">https://www.adviserinfo.sec.gov/IAPD/Default.aspx</a>. Managed Accounts are intended for citizens or legal residents of the United States or its territories.
  </p>
  <p class="paragraph-small-custom disclosurepara2">
    Investment advice delivered through Managed Accounts is based on the information provided about you and limited to the investment options available in your defined contribution plan. Projections and other information regarding the likelihood of various retirement income and/or investment outcomes are hypothetical in nature, do not reflect actual results, and are not guarantees of future results. Results may vary with each use and over time. All investments involve risk, including the loss of principal. There can be no assurance that any financial strategy will be successful.
  </p>
  <p class="paragraph-small-custom disclosurepara3">
    Reproduction, transcription, or other use of the information in this document, by any means, in whole or in part, without the prior written consent of Morningstar Investment Management, is prohibited. Opinions expressed are as of the current date; such opinions are subject to change without notice. Morningstar Investment Management nor CAPTRUST shall not be responsible for any trading decisions, damages, or other losses resulting from, or related to, the information, data, analyses or opinions or their use outside of Managed Accounts. Past performance does not guarantee future results. Morningstar Investment Management nor CAPTRUST guarantees that the results of their methodologies or the objectives of a strategy will be achieved.
  </p>
  <p class="paragraph-small-custom disclosurepara4">
    Assumptions involve known and unknown risks, uncertainties and other factors which may cause actual results to differ materially and/or substantially from any expected future result, performance or achievement expressed or implied by those assumptions for any reason.
  </p>
  <p class="paragraph-small-custom disclosurepara5">
    Asset allocation and diversification are investment methodologies that do not ensure a profit or protect against a loss in a declining market.
  </p>
</div>
`;
