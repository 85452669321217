<template>
  <div :id="chartIds.outer" class="mbc-donut-chart-with-legend">
    <div :id="chartIds.chart" class="mbc-donut-chart-with-legend__chart"></div>
    <div
      :id="chartIds.legend"
      class="mbc-donut-chart-with-legend__legend"
    ></div>
  </div>
</template>
<script>
import * as d3 from 'd3';
import eeDonutChart from './EasyEnrollmentChart';

export default {
  props: {
    chartData: {
      type: Array,
      required: true
    },
    chartColors: {
      type: Array,
      required: true
    },
    name: {
      type: String,
      required: true
    }
  },
  computed: {
    chartIds() {
      return {
        chart: `${this.name}-donut-chart-container`,
        outer: `${this.name}-donut-chart-outer-container`,
        legend: `${this.name}-donut-chart-legend-container`
      };
    }
  },
  watch: {
    chartData: {
      immediate: true,
      // eslint-disable-next-line no-unused-vars
      handler(newData, oldData) {
        if (this.donutChart) {
          this.donutChart.setData(newData);
          this.donutChart.update();
        }
      }
    }
  },
  mounted() {
    const donutChartOptions = {
      colors: this.chartColors,
      showLegend: true,
      donutLegendContainer: `#${this.chartIds.legend}`,
      legendHeader: ' ',
      legendPadding: 50
    };
    const donutChart = new eeDonutChart(donutChartOptions);
    this.donutChart = donutChart;
    const container = d3.select(`#${this.chartIds.chart}`);
    container.datum(this.chartData).call(donutChart.init());
  }
};
</script>
<style lang="scss" scoped>
@import '@/assets/css/client/default/donut.scss';
</style>
