import store from '@/simpleStore';
import { newrelicSetAttribute } from '../Utils';
import { uuid } from 'vue-uuid';

const newRelicTracking = (additionalData = []) => {
  const { cl, ra, code } = store.getQueryParams();
  let sessionUserId = sessionStorage.getItem('sessionUserId');

  if (!sessionStorage.getItem('sessionUserId')) {
    sessionUserId = uuid.v4();
    sessionStorage.setItem('sessionUserId', sessionUserId);
  }

  const atrributesList = [];
  const clientId = store.getClientId();
  const enrollmentCode =
    sessionStorage.getItem('EnrollmentCode') ||
    sessionStorage.getItem('URLEntryCode');

  atrributesList.push({ key: 'ClientId', value: clientId });
  atrributesList.push({ key: 'SessionUserId', value: sessionUserId });
  atrributesList.push({ key: 'EnrollmentCode', value: enrollmentCode });
  atrributesList.push({ key: 'urlClientId', value: cl });
  atrributesList.push({ key: 'urlAdvisoryFirmId', value: ra });
  atrributesList.push({ key: 'urlEntryCode', value: code });
  newrelicSetAttribute(atrributesList.concat(additionalData));
};

export default newRelicTracking;
