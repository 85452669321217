<template>
  <div class="custom-saving-recommendation">
    <p v-if="showDescription">
      Input your salary and current savings rate to calculate our recommended
      savings rate.
    </p>
    <MdsInput
      id="salaryInput"
      v-if="!proposedSavingsRate"
      v-model="salary"
      @input="value => handleSalaryChange(value)"
      @keypress="handleKeyPress($event)"
      :style="{ marginTop: '15px' }"
      :microcopy-above="
        !proposedSavingsRate
          ? 'Input your salary to calculate your recommended saving.'
          : ''
      "
      :microcopy-below="
        'We\'ll use this custom salary for today and all future sessions. You can update your salary information at any time post enrollment by visiting your plan provider website.'
      "
      class="input"
      size="large"
      label="Salary"
      required
    />
    <MdsAlert
      id="invalidSalary"
      v-if="!invalidSalary"
      variation="error"
      title="Input Error"
      text="Invalid Salary, please try again. (Salary cannot be negative value)"
      persistent
    >
    </MdsAlert>
    <MdsInput
      id="savingRateInput"
      v-if="!currentSavingsRate"
      v-model="savingRate"
      @input="value => handleSavingRateChange(value)"
      @keypress="handleKeyPress($event)"
      @keydown="handleKeyDownRate($event)"
      :microcopy-above="
        isActive && !currentSavingsRate
          ? 'Input your saving rate to calculate your recommended saving.'
          : ''
      "
      :disabled="!isActive"
      :microcopy-below="
        !isActive && isSuspended
          ? 'Your Employer has suspended your ability to contribute to your ' +
            'Account(s). However, you can still open and/or contribute to Your ' +
            'Other Accounts to get closer to your retirement goal. Do so by ' +
            'visiting the Accounts section within the full Retirement Manager ' +
            'experience through your plan provider website.'
          : !isActive && (isInactive || isTermed)
          ? 'You cannot contribute to this account anymore, because you no longer ' +
            'work for the Employer that sponsored it. However, you can still open ' +
            'and/or contribute to other accounts to get closer to your retirement goal.'
          : ''
      "
      :required="isActive"
      class="input"
      size="large"
      label="Savings Rate"
    />
    <MdsAlert
      id="invalidSavingRate"
      v-if="!invalidSavingRate"
      variation="error"
      title="Input Error"
      text="Invalid Savings Rate, please try again. (Rate should be between 0 - 100)"
      persistent
    >
    </MdsAlert>
    <div class="action">
      <mds-button
        v-if="!getSavingRecommendationShowLoader"
        @click="handleClick()"
        :disabled="isButtonDisabled"
        loading
        size="touch"
      >
        Calculate
      </mds-button>
      <MdsLoader v-else size="small" />
    </div>
    <p>
      Based on your inputs, we recommend you
      <strong
        >save {{ calculatedSavingRecommendation }} of each paycheck</strong
      >
    </p>
  </div>
</template>

<script>
import MdsInput from '@mds/input';
import { MdsButton } from '@mds/button';
import MdsLoader from '@mds/loader';
import { calculateSavingRate } from '../service/recommendation-calculator-service';
import { mapGetters } from 'vuex';
import store from '@/simpleStore';
import MdsAlert from '@mds/alert';

export default {
  name: 'CustomSavingRecommendation',
  components: {
    MdsInput,
    MdsButton,
    MdsLoader,
    MdsAlert
  },
  props: {
    selectedAccountIndex: {
      type: Number,
      required: true
    },
    proposedSavingsRate: {
      type: Number,
      required: true
    },
    currentSavingsRate: {
      type: Number,
      required: true
    },
    accounts: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      salary: '$',
      savingRate: '%',
      invalidSalary: true,
      invalidSavingRate: true
    };
  },
  computed: {
    ...mapGetters({
      getSavingRecommendationShowLoader: 'getSavingRecommendationShowLoader',
      getSavingRateDetail: 'getSavingRateDetail',
      getIsEnrollButtonDisable: 'getIsEnrollButtonDisable'
    }),
    calculatedSavingRecommendation() {
      if (this.getSavingRateDetail) {
        return (
          Math.round(
            this.getSavingRateDetail[
              this.selectedAccountIndex
            ]?.SavingsComponent?.reduce(function(total, arr) {
              return total + arr.Value;
            }, 0)
          ) + '%'
        );
      } else {
        return '—%';
      }
    },
    showDescription() {
      return this.proposedSavingsRate === 0 && this.currentSavingsRate === 0;
    },
    isButtonDisabled() {
      if (!this.isActive) {
        return this.proposedSavingsRate === 0 && this.salary === '$';
      } else {
        return (
          (this.proposedSavingsRate === 0 && this.salary === '$') ||
          (this.currentSavingsRate === 0 && this.savingRate === '%')
        );
      }
    },
    isActive() {
      return this.accounts.some(account => account.Status === 'Active');
    },
    isTermed() {
      return this.accounts.some(account => account.Status === 'Termed');
    },
    isSuspended() {
      return this.accounts.some(account => account.Status === 'Suspended');
    },
    isInactive() {
      return this.accounts.some(account => account.Status === 'Inactive');
    }
  },
  created() {
    this.$store.dispatch('setIsEnrollButtonDisable', true);
  },
  methods: {
    async handleClick() {
      this.invalidSalary = this.validateInput(this.salary, 'salary');
      this.invalidSavingRate = this.validateInput(this.savingRate, 'rate');

      if (this.invalidSalary && this.invalidSavingRate) {
        this.$store.dispatch('setSavingRecommendationShowLoader', true);
        this.$store.dispatch('setSavingRecommendationpSavingRateDetail', null);
        const proposedAdviceInfo = await calculateSavingRate(
          store.getParticipantId(),
          this.salary.replace('$', ''),
          this.savingRate.replace('%', '')
        );
        const proposedAdvice =
          proposedAdviceInfo.ProposedAdviceStrategy.AccountSavingsRateDetail;
        store.setproposalAdvice(proposedAdviceInfo);
        this.$store.dispatch('setProposalAdvice', proposedAdviceInfo);
        this.$store.dispatch('setIncomeProjection', proposedAdviceInfo);

        this.$store.dispatch('setSavingRecommendationShowLoader', false);
        this.$store.dispatch(
          'setSavingRecommendationpSavingRateDetail',
          proposedAdvice
        );

        this.$store.dispatch('setIsEnrollButtonDisable', false);
      }
    },
    handleSalaryChange(value) {
      if (value.length === 0) {
        this.salary = '$';
      } else {
        this.maskedSalary(value);
      }
    },
    maskedSalary(value) {
      const unMaskValue = value.replace(/[$,]/g, '');
      this.salary = '$' + unMaskValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    handleSavingRateChange(value) {
      if (value.length === 0) {
        this.savingRate = '%';
      } else {
        this.maskedRate(value);
      }
    },
    maskedRate(value) {
      this.savingRate = value.replace('%', '') + '%';
    },
    cursorPosition(event) {
      event.target.value = event.target.value.replace('%', '');
    },
    handleKeyDownRate(event) {
      if (event.key === 'Backspace') {
        this.cursorPosition(event);
      }
    },
    validateInput(value, type) {
      const parseValue = parseFloat(value.replace(/[$%]/gi, ''));

      if (type === 'salary') {
        if (parseValue < 0) {
          return false;
        }
      }

      if (type === 'rate') {
        if (parseValue < 0 || parseValue > 100) {
          return false;
        }
      }
      return true;
    },
    validateSalary(value, key) {
      const pattern = /^(?!(0\d))\d{1,8}$/;
      return pattern.test(value.replace(/[$,]/gi, '') + key);
    },
    validateRate(value, key) {
      const pattern = /^100(\.0{0,2}?)?$|^\d{0,2}(\.\d{0,2})?$/;
      return pattern.test(value.replace(/%/gi, '') + key);
    },
    handleKeyPress(event) {
      let res = true;
      if (event.target.id === 'salaryInput') {
        res = this.validateSalary(event.target.value, event.key);
        this.invalidSalary = this.validateInput(event.target.value, 'salary');
      } else {
        res = this.validateRate(event.target.value, event.key);
        this.invalidSavingRate = this.validateInput(event.target.value, 'rate');
      }
      if (!res) {
        event.preventDefault();
        return res;
      }
      return res;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/client/default/custom-saving-recommendation.scss';
</style>
