export default {
  advice: {
    fee: {
      feeText: `This service requires a {{percentage}}% fee, or \${{amount}} every month, calculated based on your account balance.
        Included in this fee is expert advice that is tailored to your personal financial situation,
        as well as continuous management of your account to help it work harder for you on an ongoing basis.`
    }
  },
  footer: {
    contact: {
      content:
        'Please contact your plan provider, {{planProvider}} at {{phoneNumber}}'
    }
  }
};
