export default {
  advice: {
    fee: {
      feeText: `<strong>Special offer for you today</strong>: Try out this service for
        <strong>90 days with no obligation or fee</strong> and you can cancel anytime. After
        that, if you would like to keep this service, there will be a {{percentage}}% fee.`,
      expirationText: `This offer expires on {{date}}`
    }
  },
  footer: {
    disclaimerSpecialOffer: `<p class="ee__body-text--s">Morningstar Investment Management's
      fee for the service provided through the Managed Account service (the "Managed Account
      Fee") will be waived for a period of 90 calendar days from your date of enrollment in
      the Managed Accounts service. This offer expires on {{dynamicExpiryDate}}. If you
      continue to use the Managed Account service after 90 days, your account will be
      charged a fee between 0.50%-0.60%, as negotiated by your plan sponsor.<p>
      <p class="ee__body-text--s">The Managed Account Fee is deducted from your account on a
      calendar quarter basis and is prorated based on the number of days you are enrolled in
      the service for that period. The Managed Account Fee is deducted from your account at
      the end of each calendar quarter in arrears by applying the basis point rate to the
      average assets in your retirement plan account during the quarter. You can terminate
      the Managed Account service without penalty at any time as outlined in the contractual
      agreement between you and Morningstar Investment Management.<p>
      <p class="ee__body-text--s">The Managed Account service fee is separate from fees and
      expenses charged by the investment options in your retirement plan account or fees
      that may be charged by a third party, such as your plan provider or recordkeeper.
      The investment options' fees and expenses are described in the prospectus or
      equivalent and generally include a management fee, other investment expenses, and
      possibly distribution fees (e.g. 12b-1 fees). In some cases, an investment option may
      also charge an initial or deferred sales charge. You may incur custodian, brokerage,
      and other transaction costs from third parties. Your plan provider or recordkeeper can
      provide you with specific fee information for your plan. Your account will incur these
      fees and expenses even if you take advantage of Morningstar Investment Management's
      offer to waive the Managed Account service fee for 90 days. Please see the Investment
      Advisory Agreement for the Managed Account service for additional details.<p>`,
    footerLinks: {
      advisoryAgreement: {
        url:
          'https://work-rm-compliance-docs.s3.amazonaws.com/Advisory_Agreement_Voya.pdf'
      }
    }
  }
};
