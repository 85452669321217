<template>
  <div class="ee-content-wrapper">
    <Header />
    <Hero />
    <div class="easy-enroll-content">
      <div class="wrapper">
        <div class="main-content">
          <slot />
        </div>
        <Footer :has-special-offer="hasSpecialOffer" />
      </div>
    </div>
    <div v-if="getShowLoader" class="loader-container">
      <mds-loader on-dark />
    </div>
  </div>
</template>

<script>
import Header from '../header';
import Hero from '../hero';
import Footer from '../footer/Footer';
import MdsLoader from '@mds/loader';
import { mapGetters } from 'vuex';

export default {
  components: {
    Header,
    Hero,
    Footer,
    MdsLoader
  },
  props: {
    hasSpecialOffer: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      getShowLoader: 'getShowLoader'
    })
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/client/default/ee-content-wrapper.scss';
</style>
