export default `
<h2 class="feature-header5 noborder-top margin-top-none nopadding-top title">Important Note About Service Names</h2>
<p class="paragraph-small2 intropara1">This website is an online platform designed to help retirement plan participants
  make more informed decisions about investing in their employer-sponsored retirement accounts. It includes three
  service options – Managed Accounts, Advice, and Guidance (collectively, the “Services”, each individually is a
  “Service”). The Services available to you are chosen by your employer and may be branded under different names chosen
  by clients who license our platform. These names include, but are not limited to, “Managed by Morningstar” (Managed
  Accounts), “Managed by You” (Advice), or “Personalized Portfolios” (Managed Accounts). If you access a version of our
  platform with customized Service names, please note that we use “Managed Accounts”, “Advice”, and “Guidance”
  throughout our disclosures and other documents to describe the Services, but the information included still applies to
  your Service regardless of the name shown in this website. Please contact your plan sponsor, plan provider, or
  Morningstar Investment Management if you are unsure what Service(s) apply to you.
</p>
`;
