<template>
  <div v-if="closeAlert" class="session-expired">
    <MdsAlert
      @mds-alert-dismissed="dismissAlert()"
      title="Your Session has Expired."
      variation="informational"
      data-ee-test="session-expired-text"
    >
      {{ $contentTransposer('sessionExpired.signOutMsg') }}
    </MdsAlert>
  </div>
</template>

<script>
import MdsAlert from '@mds/alert';
export default {
  components: {
    MdsAlert
  },
  data() {
    return {
      closeAlert: true
    };
  },
  methods: {
    dismissAlert() {
      this.closeAlert = false;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/client/default/session-expired.scss';
</style>
