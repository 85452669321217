export default `
<div class="row">
  <div class="col-xs-12">
    <p class="paragraph-small">TERMS OF SERVICE - Blueprint Managed Advice Services and Online Advice Services</p>
  </div>
</div>

<div class="row">
  <div class="col-xs-12">
    <h2 class="subfeature-subheader">
      PLEASE READ THE FOLLOWING CAREFULLY
    </h2>
    <div class="paragraph-small">
      <p>
        By electing to receive the Blueprint Managed Advice Services and/or Online Advice Services (collectively, the
        "Services") from CAPFINANCIAL PARTNERS, LLC, d/b/a CAPTRUST ("CAPTRUST" or "we", "us", or "our"), you have: (i)
        acknowledged having read and understood these Terms of Service (this "Agreement"); (ii) agreed that the
        Services will be provided and received solely on the terms of this Agreement; and (iii) represented that
        you are a citizen and/or legal resident of the United States or one of its territories.
      </p>
      <p>
        <strong>You also acknowledge receipt of the CAPTRUST Form ADV, Part 2, which contains our Privacy Policy and
          Supplement, (the "Disclosure Brochure") for the Services. (Please refer to the Disclosure Brochure for the
          entire Privacy Policy. You may call (800) 216-0645 with any questions.)</strong> The Disclosure Brochure is
        intended to disclose information about CAPTRUST's qualifications and business practices and any conflicts of
        interest; but is not intended to modify or expand the terms of this Agreement. If a conflict arises between
        the terms of this Agreement and the terms of the Disclosure Brochure, the terms of this Agreement shall control.
      </p>
      <p>
        <strong>
          THIS AGREEMENT CONTAINS A PRE-DISPUTE ARBITRATION CLAUSE IN THE SECTION ENTITLED "ARBITRATION". BY
          ACCEPTING THIS AGREEMENT, THE PARTIES ARE AGREEING TO ARBITRATE ALL DISPUTES ARISING FROM THIS AGREEMENT.
        </strong>
      </p>
      <p>
        CAPTRUST is an Investment Adviser firm registered with the United States Securities and Exchange Commission
        under the Investment Advisers Act of 1940, as amended (the "Advisers Act").
      </p>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-xs-12">
    <h2 class="subfeature-subheader">Blueprint Managed Advice Services:</h2>
    <p class="paragraph-small">
      Blueprint Managed Advice Services is a discretionary asset management program designed for a participant of a
      defined contribution or deferred compensation retirement plan or owner of other types of retirement accounts
      (your "Plan") seeking a financial professional to manage the assets within his/her employer-sponsored Plan
      account (your "Account"). In providing the Blueprint Managed Advice Services, we will act as your investment
      adviser and will provide individual investment management services on a discretionary basis to you for the
      selection of investments for your Account. We will act as a fiduciary as defined in ERISA Section 3(38) when
      providing the Blueprint Managed Advice Services. You acknowledge that CAPTRUST will exercise final
      decision-making authority and responsibility for implementing our investment decisions and that CAPTRUST
      will have authority to implement those decisions without having to consult you. You appoint CAPTRUST as your
      agent to buy, sell, or otherwise effect transactions in securities in your Account. We will also review your
      Account on a periodic basis and rebalance it if necessary. However, please note that your Plan recordkeeper or
      other service provider other than CAPTRUST (collectively, your "Other Providers") may not be able to process
      rebalancing transactions if any investment option in your Account has any restriction (e.g., equity wash
      restriction) at the time the rebalancing transaction instruction is received by the Other Provider. In addition,
      rebalancing transaction instructions may be rejected if any data validation error exists on your Account. In
      these instances, your Account may not be rebalanced until the next review period when all restrictions have been
      lifted and/or data validation errors have been corrected.
    </p>
  </div>
</div>

<div class="row">
  <div class="col-xs-12">
    <h2 class="subfeature-subheader">Online Advice Services:</h2>
    <div class="paragraph-small">
      <p>
        Online Advice Services is a one-time investment recommendation with respect to the allocation of your Account.
        This recommendation will be generated within your online account. We will act as a fiduciary as defined in
        ERISA Section 3(21) when providing the recommendation. Online Advice Services is available to and may be
        selected by you as an alternate option to the Blueprint Managed Advice Services. There is no additional fee
        for use of Online Advice Services.
      </p>
      <p>
        You acknowledge that you will retain, and will exercise, final decision-making authority and responsibility
        for the implementation of any recommendations made to you by CAPTRUST. It is recommended that you return to the
        Blueprint Managed Advice website at least once a year to receive an updated strategy. You may want to access
        the website sooner if you have any significant changes in your personal or financial situation or if there is
        a change in the investment options made available in your Plan. Online Advice Services does not adjust
        portfolio allocations automatically.
      </p>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-xs-12">
    <h2 class="subfeature-subheader">Plan Sponsor:</h2>
    <p class="paragraph-small">
      Your Plan sponsor (your "Plan Sponsor") has retained CAPTRUST to offer the Services to you under a separate
      agreement (the "Plan Sponsor Agreement") whereby CAPTRUST is responsible for creating various Plan-level
      investment portfolios from the designated investment alternatives available under your Plan and assigning one of
      those portfolios to your Account. You acknowledge that a breach under the Plan Sponsor Agreement shall not be a
      breach under this Agreement. You also acknowledge that: (i) either your Plan Sponsor or CAPTRUST is responsible
      for selecting the universe of investment options that are to be used in your Account; (ii) your Plan Sponsor,
      CAPTRUST, or one of your Other Providers may change these options over time; and (iii) these investment options
      may include investments affiliated with your Other Providers.
    </p>
  </div>
</div>

<div class="row">
  <div class="col-xs-12">
    <h2 class="subfeature-subheader">Technology Service Provider – Morningstar:</h2>
    <p class="paragraph-small">
      CAPTRUST has licensed a proprietary software and technology platform, participant level portfolio assignment
      methodology, and rebalancing functions (collectively, the "Technology Services") from Morningstar Investment
      Management LLC ("Morningstar").  Morningstar is not affiliated with CAPTRUST and is not acting in the capacity
      of investment adviser to the Plan or participants. With respect to both the Services and the Technology
      Services, neither CAPTRUST nor Morningstar makes any warranties as to the absence of viruses or damaging or
      disabling code, accuracy of informational content, or uninterrupted or error-free access, or that defects will
      be correctable or corrected.
    </p>
  </div>
</div>

<div class="row">
  <div class="col-xs-12">
    <h2 class="subfeature-subheader">Exclusions from Services:</h2>
    <p class="paragraph-small">
      We will have no responsibility or authority over: (i) any assets that are subject to restrictions imposed by your
      Plan Sponsor; (ii) any assets held in a self-directed brokerage window (if available under your Plan); (iii) any
      stock, equity interests, or debt of your Plan Sponsor or any company related to your Plan Sponsor held in your
      Account; or (iv) any assets held outside of your Account. We <strong>will not</strong> vote proxies for the
      investment options in which you will be invested in your Account.
    </p>
  </div>
</div>

<div class="row">
  <div class="col-xs-12">
    <h2 class="subfeature-subheader">Use of Services:</h2>
    <p class="paragraph-small">
      The Services offered by us are strictly for your personal use and only in connection with your Plan. The
      Services are not designed to provide investment advice for non-retirement purposes. The Services estimate your
      federal, state income, and capital gains taxes based on marginal tax rate calculations (the marginal tax rate is
      the rate you pay on the taxable income that falls into the highest bracket you reach). These calculations are
      used when CAPTRUST performs income simulations. Tax data is updated annually based on United States Internal
      Revenue Code and similar state tax data. We use income data for you, as well as your spouse/partner (if provided),
      to estimate federal and state tax exposure. Your tax exposure is appropriately reduced for pre-tax deferrals,
      tax-deferred capital gains, and yield and distribution of Roth proceeds. Based on the information you have
      provided to us, we will provide an estimate of your tax exposure, but may not include all tax considerations.
      We will not, and cannot, provide legal, accounting, actuarial, or tax advice to you. Please consult a tax
      adviser or other competent advisors for a complete understanding of your tax situation and all related matters.
    </p>
    <p class="paragraph-small">
      <strong>
        You are responsible for reviewing your Account periodically to monitor changes in your Account, including
        changes in the value of the investments in your Account.
      </strong>
    </p>
  </div>
</div>

<div class="row">
  <div class="col-xs-12">
    <h2 class="subfeature-subheader">Annual Spending Amount:</h2>
    <p class="paragraph-small">
      If you have begun to take withdrawals from your Account, we will display an Annual Spending Amount on your
      reports. The Annual Spending Amount does not represent a guarantee that you will receive a specific annual amount.
      Rather, we use your available information to come up with a projection that should assist you in evaluating the
      amount of money derived from your Account and any other sources of income that you have added to the Blueprint
      Managed Advice website to fund your retirement. You should periodically revisit the Blueprint Managed Advice
      website to review the Annual Spending Amount as changes to your financial situation may occur throughout the year.
    </p>
  </div>
</div>

<div class="row">
  <div class="col-xs-12">
    <h2 class="subfeature-subheader">Fees:</h2>
    <div class="paragraph-small">
      <p>
        You agree to pay a percentage fee for the Blueprint Managed Advice Services (the “Blueprint MAS Fee”), which
        is {{planFeeText}}. Blueprint MAS Fees include: (i) CAPTRUST's fees for the Blueprint Managed Advice Services;
        (ii) Morningstar's fees for the Technology Services; and (iii) the administrative and other recordkeeping
        costs, if any, associated with the Blueprint Managed Advice Services. Blueprint MAS Fees are calculated on a
        quarterly basis and will be payable in arrears on an approximate 90-day cycle (a "Billing Period"), starting
        from the date the Plan makes the Blueprint Managed Advice Services available to its participants. Fees will be
        calculated on the average daily value of the assets in your Account for each day of the immediately preceding
        Billing Period. Notwithstanding the foregoing, the value of: (i) any assets held in a self-directed brokerage
        window (if available under your Plan); (ii) any stock, equity interests, or debt of your Plan Sponsor or any
        company related to your Plan Sponsor held in your Account; and (iii) any frozen assets held in your Account
        shall all be excluded from the calculation of the Blueprint MAS Fee. If your Blueprint Managed Advice Services
        commence on a day other than the first day of a Billing Period or if such Services are terminated effective
        on a day other than the last day of a Billing Period, the Blueprint MAS Fees for that Billing Period shall be
        prorated (calculated on a per diem basis) and the applicable amount promptly paid by you. You authorize the
        custodian of your Account to debit your Account for the amount of the Blueprint MAS Fees and to remit those
        amounts to CAPTRUST, Morningstar, and, if applicable, your Plan recordkeeper. <strong>If your Plan Sponsor
        has elected a different payment arrangement in the Plan Sponsor Agreement, the Blueprint MAS Fee identified
        in this Agreement and displayed on the Blueprint Managed Advice website may not include the full amount of
        CAPTRUST's fees for the Blueprint Managed Advice Services. Please contact your Plan Sponsor for specific fee
        information regarding CAPTRUST's fees for the Blueprint Managed Advice Services.</strong>
      </p>
      <p>
        If your Plan Sponsor has elected the automatic enrollment plan feature for the Services and you have not
        actively enrolled in the Blueprint Managed Advice Services, no Blueprint MAS Fee will accrue for the first 90
        days after that automatic enrollment. If you have actively enrolled in the Blueprint Managed Advice Services,
        Blueprint MAS Fees will accrue immediately upon enrollment.
      </p>
      <p>
        The Blueprint MAS Fee does not include any redemption fees, charges, or expenses imposed by any investment
        options (e.g., mutual funds) held within your Account. These investment options may be subject to separate
        investment advisory, administration, transfer agency, distribution, shareholder service, and other expenses
        that are paid by you, indirectly, as a shareholder/unit holder. You may invest in the investment options
        without participating in the Services (and paying us the Blueprint MAS Fee), however, if you do so, you will
        not receive the discretionary asset management contemplated by this Agreement. The Blueprint MAS Fee paid may
        not be the same as that charged to other clients of comparable size or with similar investment objectives.
      </p>
      <p>
        For specific fee information, including the calculation and the schedule of fee deductions from your Account,
        please access the Blueprint Managed Advice website or contact your Plan Sponsor or Other Provider.
      </p>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-xs-12">
    <h2 class="subfeature-subheader">Your Information:</h2>
    <div class="paragraph-small">
      <p>
        The projections, recommendations, and suggestions offered under the Services are based on information you
        provide about your current financial situation, personal status, as well as general market and financial
        conditions existing on the date you use the Services. You agree to provide us, in writing, with all pertinent
        information regarding your investment objectives, risk tolerances, asset allocation, income and liquidity
        requirements, and any other relevant information reasonably requested by us in connection with the Services.
        You will also timely update such information as your financial circumstances change and provide such other
        information as we may reasonably request. You agree that we may assume that all information provided to us by
        you, your Plan Sponsor, or your Other Providers in connection with the Services is true and complete. CAPTRUST
        will rely upon the accuracy and material completeness of all of that information in providing the Services.
        CAPTRUST is not responsible for any delay or failure in providing the Services to the extent attributable to
        your failure to timely provide the necessary information to us.
      </p>
      <p>
        While the Services take into consideration all assets that you advise us of to determine our investment
        allocations, the Services are not designed to provide advice on your overall retirement holdings (i.e.,
        your assets both inside and outside of your Account). You should consider your other assets, income, and
        investments in addition to your Account. The Services only provide allocations for the holdings within your
        Account and are limited by the investment choices available within your Account. You should consider consulting
        a professional financial adviser to discuss how other investment options outside of your Account might be
        combined with the Services to best meet your overall retirement goals.
      </p>
      <p>
        You can request that investment restriction(s) be placed on your Account at any time, where "restriction"
        means the exclusion from your Account of one or more specific investments.  If you seek to impose a
        restriction on your Account, you may communicate the request by calling (888) 697-5908. CAPTRUST will
        evaluate your request to determine whether it is reasonable, as determined by CAPTRUST.  If your request is
        deemed inconsistent with the purpose of the Services or the investment objective of your Account, you can
        modify your request or discontinue using the Services.
      </p>
      <p>
        Your information provided to us in connection with the Services and any materials, reports, and other
        information to be provided by CAPTRUST to you may be transmitted electronically (including email and Internet
        or intranet websites, including your retirement Plan website). Each party consents to using electronic
        communication and acknowledges that the delivery of information sent over the Internet is sometimes regarded
        as unsecure because unauthorized or unintended third parties could access or intercept such information.
        Each party must maintain commercially reasonable and customary administrative, physical, and technical
        security precautions to protect the privacy, and prevent any unauthorized use, access, destruction, or
        disclosure, of any electronically transmitted information. You also consent to the electronic transmission
        of your personal information between us and your Other Providers.
      </p>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-xs-12">
    <h2 class="subfeature-subheader">Our Proprietary Information:</h2>
    <p class="paragraph-small">
      All trademarks, service marks, trade names, and other intellectual property displayed in connection with the
      Services are the property of CAPTRUST or Morningstar. The Services contain proprietary data and information of
      CAPTRUST and Morningstar, and you agree that you will not use such data or information for any unlawful purpose
      or any commercial or business purpose.
    </p>
  </div>
</div>

<div class="row">
  <div class="col-xs-12">
    <h2 class="subfeature-subheader">Warranty/Limitation of Liability:</h2>
    <div class="paragraph-small">
      <p>
        CAPTRUST warrants that: (i) it is qualified to provide the Services; and (ii) the Services shall be performed
        in good faith, in accordance with all applicable standards in the industry and all applicable laws and
        governmental regulations and with the degree of diligence, care, and skill that a prudent person rendering
        similar services would exercise under similar circumstances. Any information provided by CAPTRUST obtained,
        computed, formatted, or displayed by outside sources is believed to be accurate, but may not have been
        independently verified by CAPTRUST and cannot be guaranteed. You acknowledge that: (i) all trading done in
        your Account is subject to various market, political, currency, economic, and business risks and may not
        always be profitable; (ii) CAPTRUST cannot guarantee current or future financial results, nor do we promise
        that our investment portfolios or allocations will be profitable or that you will meet your retirement income
        goals; and (iii) any information provided by CAPTRUST regarding past performance cannot guarantee future
        results. We cannot guarantee that the Services will be delivered to you without interruption, timely,
        error-free, or secure. Errors may occur in software-based Services as a result of programming errors,
        database errors, or other causes. If an error occurs in our software-based Services, we reserve the right to
        correct such error in a manner that we deem prudent, subject to any applicable federal and state securities
        laws. <strong>There are no other warranties, express or implied (including any implied warranties of
        merchantability or fitness for a particular purpose), relating to the Services.</strong>
      </p>
      <p>
        <strong>Except for amounts payable by a party pursuant to its indemnity obligations under this Agreement,
          neither you nor we, under any circumstances, shall be liable for any indirect, special, consequential,
          punitive, or exemplary damages, or similar damages arising out of or relating to this Agreement or the
          relationship of the parties under this Agreement.</strong> You acknowledge that: (i) the Blueprint MAS Fees
        charged by us reflect the agreed upon allocation of risks between you and us as contemplated by this Agreement,
        including the limitation of liability described above; and (ii) a modification of this allocation of risks
        would affect the Blueprint MAS Fees.
      </p>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-xs-12">
    <h2 class="subfeature-subheader">Indemnification:</h2>
    <p class="paragraph-small">
      Except to the extent attributable to a Breach (defined below) of the party otherwise to be indemnified, you and
      CAPTRUST shall each indemnify and hold the other harmless against all third party claims, loss, damage, and
      expense (including reasonable attorneys' fees, court costs, and other defense costs) that result from the
      indemnifying party's: (i) breach of this Agreement; (ii) negligence or willful misconduct; or (iii) breach of
      its fiduciary duty (collectively, a "Breach"). For purposes of this Section: (a) all acts attributable to a
      party shall include the acts of that party's officers, directors, members/managers, employees, and agents; and
      (b) all indemnification rights shall extend to the indemnified party's owners, officers, directors, managers,
      employees, and agents. The indemnification obligations and rights under this Section shall survive a termination
      of this Agreement. In no event shall CAPTRUST be responsible for acts of the Plan Sponsor or any Other Providers.
    </p>
  </div>
</div>

<div class="row">
  <div class="col-xs-12">
    <h2 class="subfeature-subheader">Termination:</h2>
    <p class="paragraph-small">
      You may terminate this Agreement, without penalty, at any time. CAPTRUST may terminate this Agreement, with or
      without cause, upon not less than 30 days' prior written notice given to you. Such termination will be effective
      on the date specified in that notice; provided it is at least 30 days after your receipt of such. If your Plan
      Sponsor terminates its service agreement for recordkeeping services with your current Plan recordkeeper, unless
      your Plan Sponsor engages a new Plan recordkeeper that has the ability to provide the administrative and other
      recordkeeping services associated with the Services, this Agreement shall automatically terminate. A termination
      will not affect a party's responsibilities under this Agreement for transactions already initiated or your
      responsibilities under this Agreement for Blueprint MAS Fees owed for Blueprint Managed Advice Services rendered
      by us through the effective date of termination. On and after the effective date of termination, we will have no
      further obligation to provide any of the Services or to otherwise advise you with respect to any assets in your
      Account or your Plan.
    </p>
  </div>
</div>

<div class="row">
  <div class="col-xs-12">
    <h2 class="subfeature-subheader">Arbitration:</h2>
    <div class="paragraph-small">
      <p>
        Except as expressly provided in the Miscellaneous Section, all controversies and disputes between you and us
        (including our managers, officers, employees, and affiliates) arising out of or related to this Agreement or
        the relationship of the parties under this Agreement, shall be determined by binding arbitration under the
        rules of JAMS or the American Arbitration Association (as applicable, the "Association"), as elected by the
        party initiating the arbitration. The arbitrator's decision shall be final and binding on all parties and a
        judgment upon the arbitrator's award may be entered in any court having jurisdiction thereof. <strong>This
        arbitration agreement includes all controversies involving the advice rendered, the discretionary authority you
        have given us, and transactions of any kind made on your behalf by us, or the performance, construction, or
        breach of this Agreement or any other written agreement between the parties. The parties make this arbitration
        agreement on behalf of themselves, and their heirs, administrators, representatives, executors, successors,
        assigns and all other persons claiming a legal or beneficial interest in your Account.</strong>
      </p>
      <p>
        A party may initiate an arbitration by sending written notice (the "Arbitration Demand") to the other party at
        any time prior to the date the dispute is barred by the applicable statute of limitations. That notice shall
        specify the nature of the dispute and the Association selected. If you file an Arbitration Demand, the
        arbitration shall be held in or around Raleigh, North Carolina; and if CAPTRUST files an Arbitration Demand,
        the arbitration shall be held within 25 miles of your address. The arbitration shall be conducted by a single
        arbitrator mutually acceptable to the parties, or, if the parties are unable to agree upon a single arbitrator
        within 20 days of receipt of the Arbitration Demand, then by a single arbitrator appointed by the applicable
        Association in accordance with its rules. Unless the arbitrator awards otherwise, the cost of the arbitration
        shall be ratably borne by the parties to the proceeding. The obligations of this Section shall survive a
        termination of this Agreement.
      </p>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-xs-12">
    <h2 class="subfeature-subheader">Miscellaneous</h2>
    <div class="paragraph-small">
      <p>
        This Agreement constitutes the entire and exclusive agreement between you and us on this subject and supersedes
        any and all prior agreements and understandings (whether written, oral, electronic, or otherwise) between the
        parties. To the extent possible, each provision of this Agreement and every related document shall be
        interpreted as to be valid under applicable law. If any provision of this Agreement is found to be illegal,
        invalid, or unenforceable, the remaining provisions shall remain valid if doing so does not materially
        adversely affect the economic or legal substance of the contemplated transactions. In that case, in lieu of the
        illegal, invalid, or unenforceable provision a provision as similar in terms as necessary to render the
        provision legal, valid, and/or enforceable shall be automatically added to this Agreement.
      </p>
      <p>
        We reserve the right, in our complete and sole discretion, to modify this Agreement at any time upon at least
        30 days advance written notice to you, given either directly or by posting on the Blueprint Managed Advice
        website. Your acceptance and use of Services after the 30-day period following your receiving written notice,
        or our posting on the Blueprint Managed Advice website, of a modification to this Agreement constitutes your
        acceptance of such modified terms.
      </p>
      <p>
        If either party shall be delayed, hindered, or prevented from the performance of any obligations required
        under this Agreement (other than the payment of money) by reason of acts of war, acts of God, governmental
        restrictions, strikes, failure of the Internet, or any other reasons beyond its reasonable control, the
        performance of the act shall be excused for the period of delay, and the period for the performance of the act
        shall be extended for the period necessary to complete performance after the end of the period of the delay.
      </p>
      <p>
        A party may not assign any of its rights or delegate any of its obligations under this Agreement (whether
        voluntarily or by operation of law) without the other party's prior written consent. If CAPTRUST has a change
        in control or ownership that would constitute an assignment under the Advisers Act, it will provide written
        notice to you, and you will deemed to have consented to the assignment unless you give written notice of
        termination of this Agreement within 30 days after your receipt of the notice. Transactions that do not result
        in a change of actual control or management of CAPTRUST shall not be considered an assignment under the
        Advisers Act. This Agreement shall be binding upon and shall inure to the benefit of the parties and their
        permitted successors and assigns.
      </p>
      <p>
        <strong>This Agreement shall be deemed executed and completed in North Carolina; and this Agreement along with
          all claims or causes of action (whether in contract, tort, or statute) arising out of or relating to this
          Agreement, the relationship of the parties under this Agreement, or the negotiation, execution, or
          performance of this Agreement, shall be governed by the laws of North Carolina (including its statutes of
          limitations), without giving effect to its conflict of laws principles.</strong> In any court action seeking
        injunctive relief (in which case, such action shall be strictly limited to an action for injunctive relief
        only); in any court action seeking to challenge the enforceability of the binding arbitration provisions of this
        Agreement; or in any court action filed after a court of competent jurisdiction has declared such arbitration
        provisions to be unenforceable, the courts of North Carolina shall have exclusive jurisdiction over such legal
        actions, venue to be in Wake County, North Carolina. By accepting this Agreement, each party consents to such
        exclusive, personal jurisdiction and venue and waives any objection thereto.
      </p>
      <p>
        Except where another form of notice is specifically permitted in this Agreement, to be effective, a notice
        required under this Agreement must be in writing, addressed to the appropriate address noted in this document
        or as otherwise noted in writing in accordance with this provision, and must either be: (a) personally served
        (deemed received on receipt or refusal of delivery); (b) delivered by a nationally recognized overnight express
        delivery service (deemed received the next business day after posting); (c) deposited in the United States Mail,
        registered or certified mail, postage prepaid, return receipt requested (deemed received the third business day
        after posting); or (d) sent by confirmed email transmission during normal business hours (deemed received on
        confirmed receipt of transmission).
      </p>
    </div>
  </div>
</div>
`;
