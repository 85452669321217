export default `<h2 class="subfeature-subheader">
PLEASE READ THE FOLLOWING CAREFULLY<br>
It contains important information about Morningstar® Retirement Manager<sup>
<small class="dark">SM</small>
</sup>
</h2>
<div class="paragraph-small">
<p>Morningstar Investment Management LLC (“Morningstar”, “we”, “us”, or “our”), is a registered investment adviser
  with the United States Securities and Exchange Commission (the “SEC”) pursuant to the Investment Advisers Act of
  1940, as amended (the “Advisers Act”).
</p>
<p>Please carefully review this Investment Advisory Agreement (the “Agreement”). By clicking “Continue”, by stating
  your acceptance to a call center representative (“Representative”), or by signing a paper
  enrollment form, you (i) acknowledge having received, read and understood the Agreement and agree to be bound by
  it; and (ii) represent to us that you are a citizen and/or legal resident of the United States or any of its
  territories.
</p>
<p>If viewing this document online, click here to view our <a id="brochure" target="_blank"
 href="https://s3.amazonaws.com/work-rm-compliance-docs/FORM_ADV.pdf" style="color:#0000ff;">Form ADV Part 2 (the "Firm Brochure and Brochure Supplement")</a>, and <a id="relationship-summary" data-rm-test="advisory-agreement__relationship-summary-link" target="_blank" href="https://work-rm-compliance-docs.s3.amazonaws.com/Form_ADV_Part_3_RS.pdf">ADV Part 3 (“Relationship Summary”)</a>. A paper
  copy of our Relationship Summary, Firm Brochure and Brochure Supplement is available by mail. To obtain one, please send your request with your name and address
  either by email to <a href="mailto:compliancemail@morningstar.com">compliancemail@morningstar.com</a> or by regular
  mail to the address listed below.
</p>
<p>By clicking "Continue", or by stating your acceptance to a Representative, or by signing a paper
  enrollment form, you acknowledge that you have either agreed to the electronic delivery of our Relationship Summary, Firm Brochure and Brochure Supplement and
  have reviewed it, or that you have received a paper copy of our Relationship Summary, Firm Brochure and Brochure Supplement and have reviewed it. You also agree
  that all communications from us may be sent to you by email or by other electronic format such as posting on our
  web site. You understand that your consent to such electronic delivery is effective immediately upon your
  acceptance of this Agreement and will remain in effect unless and until either you or we withdraw it. You may
  withdraw your consent to electronic delivery or request a paper copy of this Agreement and/or the Relationship Summary, Firm Brochure and Brochure Supplement
  by contacting a Representative at the telephone number listed in the “Contact Us” link on our web site. You may
  also contact us by writing Morningstar Investment Management LLC, 22 W. Washington Street, Chicago, IL 60602,
  Attn: Compliance Dept.
</p>
<p><u>You have the right to terminate this Agreement without penalty at any time after entering into this
  Agreement.</u> Federal law prohibits us from assigning this Agreement (within the meaning of the Investment
  Advisers Act of 1940) to another investment adviser without your consent. Where applicable, federal law governs
  the terms of this Agreement and the provision of our Services.
</p>
<p>We agree to provide you with investment advisory services (the “Services”) and other recommendations (“Retirement Recommendations”) that your plan sponsor (the “Plan
  Sponsor”), recordkeeper or service provider has decided to offer and that you have decided to accept. You
  understand that your Plan Sponsor or service provider is responsible for selecting the universe of investment
  options that are to be used in your employer-sponsored retirement plan or other retirement account that they have offered the Services for
   (“Account”), and that your Plan Sponsor or service provider may change these options over time and that these
  investment options may include those that are affiliated with your service provider. (For the avoidance of doubt, “Account” does not include those accounts you enter as “Outside Accounts”, as defined below.) <strong>You also understand
    that you may not have access to all of the Services as described herein.</strong> The Services are offered
  through the Morningstar Retirement Manager platform and may include “Managed by Morningstar” managed account
  service (“Managed Account Service”), or “Managed by You” advice program (“Advice Service”). You may also enter information about other non-employer-sponsored retirement accounts you have earmarked for use in retirement (“Outside Accounts”) to receive an overall asset allocation recommendation for those Outside Accounts.  If you select the
  Managed Account Service, we will actively manage your Account, as described below. If you select the Advice
  Service, or enter information about Outside Accounts, you are solely responsible for your investment decisions, including whether to accept, reject, or modify
  our investment recommendations or suggestions, and you are also responsible for implementing our recommendations.
  The Services and Outside Account recommendations are described below in greater detail.
</p>
<p>We will provide the Services and Retirement Recommendations to you at all times in good faith, and will use reasonable care, consistent with
  industry practices of similarly situated advisers, in providing the Services and Retirement Recommendations. However, we do not guarantee that
  the Services or Retirement Recommendations will be delivered to you without interruption, timely, error-free, or secure. Errors may occur in
  software-based Services and Retirement Recommendations as a result of programming errors, database errors, or other causes. We will provide the
  Services and Retirement Recommendations with that degree of prudence, diligence, care, and skill which a prudent person rendering similar services
  as an investment adviser would exercise under similar circumstances. The provisions of this Agreement shall not be
  interpreted to imply any other obligation on our part to observe any other standard of care. In the event an error
  occurs in our software-based Services or Retirement Recommendations, we reserve the right to correct such error in a manner that we deem prudent,
  subject to any applicable federal and state securities laws.
</p>
<p>The Services and Retirement Recommendations offered by us are for your personal use only and are not to be used for any commercial or business
  purposes. You agree that we may assume that all information provided to us by you, your Plan Sponsor, recordkeeper,
  or service provider in connection with our Services and Retirement Recommendations is true and accurate.
</p>
<p>The Services and Retirement Recommendations offered by us are to be used by you <strong>only</strong> in making decisions about the allocation of
  assets in your Account and/or Outside Accounts. The Services and Retirement Recommendations are not designed to provide investment advice for an account that will be used
  by you for non-retirement purposes. The Services estimate your federal, state income, and capital gains taxes based
  on marginal tax rate calculations (the marginal tax rate is the rate you pay on the taxable income that falls into
  the highest bracket you reach). These calculations are used when the Services conduct the income simulations. Tax
  data is updated annually based on United States Internal Revenue Code (IRC) and similar state tax data. The Services
  use income data for you, as well as your spouse/partner (if provided), to estimate federal and state tax exposure.
  Your tax exposure is appropriately reduced for pre-tax deferrals, tax-deferred capital gains, and yield and
  distribution of Roth proceeds. Based on the information we know about you, the Services provide an estimate of your
  tax exposure, but may not include all tax considerations. Retirement Recommendations do not consider your tax exposure. Please consult a tax adviser for a complete understanding
  of your tax situation.
</p>
<p>We cannot and do not make any guarantee about the future performance or profitability of your Account or Outside Accounts, nor do we
  promise that our investment allocation recommendations will be profitable. The investments that we may recommend
  may be subject to a variety of risks, including market, currency, and political risks. Please note that past
  performance of a mutual fund, stock, or other investment vehicle does not guarantee its future performance.
</p>
<p>You agree to use the Services and Retirement Recommendations in accordance with this Agreement. You are responsible for reviewing your Account
  periodically to monitor changes in your Account, including changes in the value of the investments in your Account.
  You also consent to the transmission of your personal information between us and your service provider or
  recordkeeper, and you acknowledge that you have received our privacy policy.
</p>
<p>The projections, recommendations and suggestions offered under the Services and Retirement Recommendations are based on information you provide
  about your current financial situation, personal status, as well as general market and financial conditions
  existing on the date you use the Services. You agree to provide complete and accurate information to the extent
  you are asked for such information. You also agree to update that information when your personal or
  financial circumstances change. The Services take into consideration all assets that you choose to input to
  determine our investment recommendations (i.e., your Outside Accounts). You should
  consider your other assets, income, and investments in addition to your Account. The Services only provide
  recommendations or suggestions on how to structure the holdings within your Account, and those recommendations or
  suggestions are limited by the investment choices available within your Account. If you input your Outside Accounts, we will provide an asset class recommendation for those assets as a whole. You are solely responsible for determining whether and how to act upon those recommendations.  If you do make changes to your Outside Accounts, you should return to the Services and update your Outside Accounts information. Until you do, we will continue to rebalance your Account in accordance with the Outside Account information we have on file. You should consider consulting a
  professional financial adviser to discuss how other investment options outside of your Account might be combined
  with the Services to best meet your overall retirement goals.
</p>
</div>

<h1 class="feature-header">Managed Account Service</h1>
<div class="paragraph-small">
<p>The Managed Account Service is a discretionary asset management program designed for participants of a defined
  contribution or deferred compensation retirement plan, or owners of other types of retirement accounts, that are
  seeking a financial professional to manage the assets within their Account. If you elect to take part in the
  Managed Account Service by accepting this Agreement, we will act as your investment adviser, and you grant us
  authority as attorney-in-fact to act on your behalf and give us full decision-making authority over the investments
  in your Account without having to consult you in advance. We will have no responsibility or authority over (i) those
  assets that are subject to Plan Sponsor restrictions, (ii) those assets held in a self-directed brokerage window (if
  available under your plan), (iii) restricted employer company stock held in your Account, (iv) non-restricted
  employer company stock that you direct us to retain, and (v) any assets held outside of your Account. If you
  participate in the Managed Account Service, we acknowledge that we are an “investment manager” (as that term is
  defined in ERISA Section 3(38)) for your Account and a fiduciary of the Plan to the extent we have decision-making
  authority over the investments in your Account. You also understand that we <strong>will not</strong> vote proxies
  for the investment options in which you will be invested.
</p>
<p>In the Managed Account Service, we will typically review your Account on a quarterly basis and rebalance if
  necessary. However, please note that your plan recordkeeper or service provider may not be able to process
  rebalancing transactions if any investment option in your Account has any restriction (e.g., equity wash restriction)
  at the time the rebalancing transaction instruction is received by the plan recordkeeper or service provider. In
  addition, rebalancing transaction instructions may be rejected if any data validation error exists on your Account.
  In these instances, your Account may not be rebalanced until the next quarterly review period when all restrictions
  have been lifted and/or data validation errors have been corrected.
</p>
<p>You agree to pay us a fee for the services provided under the Managed Account Service (the “Managed Account Fee”). 
  The Managed Account Fee is 0.25% of your Account value. Fees are calculated based on your total current plan balance
  minus any amount in company stock, a brokerage window, or any outstanding loan balance. You authorize your service
  provider or recordkeeper to deduct the Managed Account Fee from your Account at the end of each calendar quarter in
  arrears and remit the Managed Account Fee to Morningstar. A prorated Managed Account Fee will be calculated for any
  individual not in the program a full month and shall be determined by the date of entry into or exit from the
  program. In the event that this Agreement is terminated, either by you or Morningstar, the monthly installment of
  the Managed Account Fee that you will be charged will be based on the number of days in the final month in which
  you receive the Managed Account Service.
</p>
<p>The Managed Account Fee does not include any redemption fees, charges or expenses imposed by any investment options
  (e.g., mutual funds) held within your Account. These investment options may be subject to separate investment
  advisory, administration, transfer agency, distribution, shareholder service and other expenses that are paid by
  you, indirectly, as a shareholder/unit holder. You may invest in the investment options without participating in
  the Managed Account Service (and paying us the Managed Account Fee), however, if you do so, you will not receive
  the discretionary asset management contemplated by this Agreement. The Managed Account Fee paid may not be the same
  as that charged to other clients of comparable size or with similar investment objectives. The payment arrangements
  depend on the agreements between your Plan Sponsor, your recordkeeper or service provider, and Morningstar. Your
  recordkeeper or service provider may also charge you or your Plan Sponsor a fee to cover the administrative and
  other recordkeeping costs associated with the Managed Account Service.
</p>
</div>

<h1 class="feature-header">Advice Service</h1>
<div class="paragraph-small">
<p>The Advice Service is offered to you for your use in making decisions about the allocation of assets in your
  Account. You are responsible for making your own investment allocation decisions, and you are free to accept or
  reject, in whole or in part, the investment allocation recommendations made by the Advice Service. The Advice
  Service does not make any investment decisions for you. We cannot monitor, review or update our recommendations or
  projections on an on-going basis, nor do we have the capability to monitor or review investment decisions you make
  based on our recommendations. Because the Advice Service depends on the completeness, accuracy and timeliness of
  the information you provide, you are solely responsible for reviewing and updating your individual financial
  information. You are responsible for tracking your Account and the market to be aware of any changes in the value
  of your Account. The payment arrangements for the Advice Service depend on the agreements between your Plan Sponsor,
  your recordkeeper or service provider, and Morningstar.
</p>
</div>

<h1 class="feature-header">Outside Accounts Recommendations</h1>
<div class="paragraph-small">
<p>Outside Accounts recommendations are designed to give you an overall asset allocation suggestion for your Outside Accounts. The information contained in Outside Accounts recommendations should not be considered advice to buy or sell a particular security, mutual fund or other investment.  You agree that you are responsible for determining the suitability for you of any particular security, mutual fund or other investment.
</p>
<p>We cannot monitor, review or update our suggestions or projections for Outside Accounts on an on-going basis, nor do we have the capability to monitor or review investment decisions you make in Outside Accounts. Because our Services and Retirement Recommendations depend on the completeness, accuracy and timeliness of the information you provide, you are solely responsible for reviewing and updating your individual financial information. You are responsible for tracking your Outside Accounts and the market to be aware of any changes in the value of your Outside Accounts.
</p>
<p>There is no fee to receive an Outside Accounts recommendation, however, you may incur redemption fees, transaction costs, other security or account level charges and expenses, and/or tax consequences for the securities in your Outside Accounts.  You should consult with a professional financial adviser or tax adviser if you have any questions prior to making any investment decisions.
</p>
</div>

<h1 class="feature-header">Company Stock</h1>
<div class="paragraph-small">
<p>If your Account includes securities issued by your employer that are freely marketable without restrictions imposed
  by your employer (“Non-Restricted Company Stock”), you may retain some or all of the Non-Restricted Company Stock,
  or you may direct us to sell the Non-Restricted Company Stock according to our methodology. If you are enrolled in
  the Managed Account Service, you hereby direct us to send a transaction to sell 25% of your Non-Restricted Company
  Stock upon you completing a Web session or upon the quarterly review of your Account, or 100% of your Non-Restricted
  Company Stock when the Non-Restricted Company Stock balance reaches $3,000 or 3% of your Account balance. We will
  sell 100% immediately if instructed to do so by you. These sales of Non-Restricted Company Stock will also include
  any new Non-Restricted Company Stock that is allocated automatically to your Account. In addition, in accordance
  with our methodology, you direct us to sell any future contributions of Non-restricted Company Stock. You have the
  ability to instead restrict the sale of your Non-Restricted Company Stock through the Managed Accounts website.
  Morningstar shall have no responsibility with respect to any securities issued by your employer that are not freely
  marketable or subject to any restrictions.
</p>
</div>

<h1 class="feature-header">Annual Spending Amount</h1>
<div class="paragraph-small">
<p>If you have begun to take withdrawals from your Account, as part of our proposed strategy in the Advice Service, we
  will display an Annual Spending Amount. The Annual Spending Amount does not represent a guarantee that you will
  receive a specific annual amount. Rather, we use your available information to come up with a projection that assists
  you in evaluating the amount of money derived from various sources of income that will fund your retirement. You should
  revisit our Services at least annually to review the Annual Spending Amount as changes to your financial situation may
  occur throughout the year.
</p>
</div>

<div class="paragraph-small">Below are some important questions and answers regarding the investment options available in
your plan:
</div><br>
<h2 class="subfeature-subheader">Who selected the investment options available in my plan?</h2>
<div class="paragraph-small">
<p>Your Plan Sponsor or service provider is responsible for determining what investment options are made available to
  you in your plan. The selection was done either by your Plan Sponsor or service provider alone or with the
  assistance of a consultant.
</p>
<p>In most cases, we have no involvement in the selection of the investment options available to you. However, there
  may be instances in which a Plan Sponsor or service provider uses us to assist it in the selection of the investment
  options available to you. This assistance is done separately and is not part of the Services.
</p>
</div>

<h2 class="subfeature-subheader">What are the past performances and historical rates of return of the investment options
available in my plan?
</h2>
<div class="paragraph-small">
<p>For information about the past performance and other pertinent information regarding the investment options
  available in your plan, please click on the Investment Research link within the Morningstar Retirement Manager
  website.
</p>
</div>

<h2 class="subfeature-subheader">Does Morningstar or its affiliates have any material affiliation or contractual
relationship with the investment options available in my plan?
</h2>
<div class="paragraph-small">
<p>In most cases, we do not have a contractual relationship with any of the investment options available in your
  plan. However, in some cases we or our affiliates provide advisory services to funds that may be available as an
  investment option in your plan. To mitigate the conflict of interest from this relationship, we will not include
  recommendations into these investment options through our Services.
</p>
<p>Additionally, we may have a contractual relationship with and may receive compensation from your plan’s service
  provider for making our Services available to your plan and to the individuals that use our Services. Additionally,
  one or more of the investment options available in your plan may be affiliated with your plan’s service provider.
  To mitigate a conflict of interest from this relationship, we base our fund recommendations on an objective
  methodology, and our compensation does not vary based on the funds that we recommend.
</p>
<p>In addition, our parent company, Morningstar, Inc., offers numerous products and services to the financial
  community. Therefore, there may be instances in which an investment options’ investment adviser uses Morningstar,
  Inc. products and services. A conflict of interest resulting from this kind of situation is mitigated by the fact
  that recommendations provided by us are derived from a quantitative process which in no way is influenced by the
  products and services provided by Morningstar, Inc.
</p>
</div>

<h1 class="feature-header">Miscellaneous</h1>
<div class="paragraph-small">
<p>We reserve the right, in our complete and sole discretion, to alter, modify, add, update or remove portions of
  this Agreement at any time. Please review this Agreement periodically for changes to its terms. Using the Services
  after we post changes constitutes your acceptance of any changed terms. We expressly reserve the right to monitor
  any and all use of the Services.
</p>
<p>All trademarks, service marks, trade names and other intellectual property displayed in connection with the
  Services are the property of Morningstar. You acknowledge that United States copyright law and other laws governing
  intellectual property protect the Services and the information contained in the Services. You also agree and
  acknowledge that the Services contain proprietary data and information of Morningstar, and you agree that you will
  not use such data or information for any unlawful purpose, or any commercial or business purpose.
</p>
<p>If there is a dispute between you and us about the Services that cannot be resolved, we each agree that the dispute
  will be resolved through binding arbitration to be conducted pursuant to the rules established by the American
  Arbitration Association. A panel of three arbitrators will be selected. Each party shall select one arbitrator, and
  the two arbitrators so selected shall then select the third. Each party shall bear their own expenses, including
  attorney’s fees, and the parties shall share the cost of the arbitration equally. By agreeing to arbitration, you
  are giving up the right to have your claim heard in a court of law, however, either party may bring an action in
  court to compel arbitration under this Agreement and to enforce an arbitration award. The arbitrators’ decision may
  not include factual findings or legal analysis. The rules of procedure for arbitration differ from the rules of
  court. Also, the right to appeal the decision of the arbitration panel is limited. <strong>Arbitration shall be
    final and binding upon the parties.</strong>
</p>
<p>We may terminate this Agreement and your access to the Morningstar Retirement Manager web site and Services
  immediately if we determine that you have breached this Agreement. We may terminate this Agreement and your access
  to the Morningstar Retirement Manager web site and the Services immediately if we do not receive timely payment for
  the Services. We may also terminate this Agreement and your access to the Morningstar Retirement Manager web site
  and the Services if the agreement between us and your service provider, recordkeeper or Plan Sponsor is terminated.
  You have the right to terminate this Agreement without penalty at any time.
</p>
<p>Termination of this Agreement will not affect the provisions of this Agreement relating to arbitration of disputes,
  the validity of any action taken prior to termination, or liabilities for actions taken prior to termination.
</p>
<p>Except as otherwise provided by law, we will not be responsible for (i) any loss or damages arising from any advice
  or recommendation made or any other action taken or omitted to be taken in good faith or (ii) any loss resulting
  from our use of inaccurate, outdated or incomplete information furnished by you or through your Plan Sponsor,
  service provider or recordkeeper. Federal and state securities laws and the Employee Retirement Income Security Act
  of 1974, as amended (“ERISA”), to the extent applicable, impose liabilities in certain circumstances on persons who
  act in good faith, and nothing in this Agreement waives or limits any rights you may have under those laws. We will
  not be responsible for any loss caused directly or indirectly by government restrictions, exchange or market rulings,
  suspension of trading (including suspension of redemption rights in your investment option), war, natural disasters,
  or other conditions beyond our control, including extreme market volatility.
</p>
<p>If any provision of these terms is deemed unlawful, void, or for any reason unenforceable, then that provision will
  be deemed severable from these terms and will not affect the validity and enforceability of the remaining provisions.
</p>
<p>The laws of the State of Illinois will govern this Agreement and its enforcement, except to the extent federal law
  preempts Illinois law. Nothing herein will be construed in any manner inconsistent with the Advisers Act, ERISA
  (if applicable), or any rule or order of the Securities and Exchange Commission.
</p>
</div>

<p class="advisoryagreementdate">June 2020</p>`;
