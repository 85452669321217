var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('MdsLayoutGrid',{staticClass:"confirm-enrollment"},[_c('MdsRow',{staticClass:"heading"},[_c('MdsCol',{attrs:{"cols":12}},[_c('h1',{staticClass:"heading--title",attrs:{"data-ee-test":"enroll-confirmation-heading"}},[_vm._v(" "+_vm._s(_vm.getHeaderMsg())+" ")])])],1),_c('MdsRow',{attrs:{"align-horizontal":"center"}},[_c('MdsCol',{attrs:{"cols":12,"cols-at-m":4,"align-vertical":"center"}},[_c('SVGImage',{staticClass:"svg",attrs:{"path":"enroll-illustration","name":"enroll-page-illustration"}})],1),_c('MdsCol',{staticClass:"content",attrs:{"cols":12,"cols-at-m":5}},[_c('h4',{attrs:{"data-ee-test":"enroll-confirmation-text"}},[_c('span',{domProps:{"innerHTML":_vm._s(
            _vm.$contentTransposer('enroll.confirmationText', {
              accountText: _vm.accountText,
              productName: _vm.getProductName
            })
          )}}),(_vm.model.email)?_c('span',{attrs:{"data-ee-test":"enroll-confirmation-email"},domProps:{"innerHTML":_vm._s(
            _vm.$contentTransposer('enroll.emailText', { email: _vm.model.email })
          )}}):_vm._e()]),_c('contribution-details')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }