<template>
  <div class="contribution-details">
    <h6 class="head" data-ee-test="contributions-details-header">
      <strong>{{ headerText }}</strong>
    </h6>
    <ul>
      <li :key="idx" v-for="(acc, idx) in accounts">
        <span>{{ acc.planInfo.name }}</span>
        <span>{{ acc.planInfo.label }}</span>
      </li>
    </ul>
  </div>
</template>
<script>
import store from '@/simpleStore';
import { mapGetters } from 'vuex';
export default {
  data() {
    return {
      hasMultipleAccounts: store.hasMultipleAccounts()
    };
  },
  computed: {
    accounts() {
      return this.getAccountDetails();
    },
    headerText() {
      return this.hasMultipleAccounts
        ? "Accounts We're Managing"
        : "Account We're Managing";
    }
  },
  methods: {
    ...mapGetters({
      getAccountDetails: 'getAccountDetails'
    })
  }
};
</script>
<style lang="scss" scoped>
@import '@/assets/css/client/default/contribution-details.scss';
</style>
