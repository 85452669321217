<template>
  <EeContentWrapper class="already-enrolled" has-special-offer>
    <MdsLayoutGrid class="already-enrolled-wrapper">
      <MdsRow>
        <MdsCol class="warning">
          <mds-alert :title="warningMsg" variation="warning" tinted>
          </mds-alert>
        </MdsCol>
      </MdsRow>
      <MdsRow class="heading">
        <MdsCol>
          <h1
            class="heading--title"
            data-ee-test="alreadyEnroll-confirmation-heading"
          >
            {{ $contentTransposer('alreadyEnrolled.header') }}
          </h1>
        </MdsCol>
      </MdsRow>
      <MdsRow class="message">
        <MdsCol>
          <h5
            :key="idx"
            v-for="(msg, idx) in $contentTransposer(
              'alreadyEnrolled.messageText'
            )"
          >
            {{ msg }}
          </h5>
        </MdsCol>
      </MdsRow>
    </MdsLayoutGrid>
    <about-morningstar />
  </EeContentWrapper>
</template>

<script>
import EeContentWrapper from '../layout/ee-content-wrapper';
import { MdsLayoutGrid, MdsRow, MdsCol } from '@mds/layout-grid';
import mdsAlert from '@mds/alert';
import AboutMorningstar from '../AboutMorningstar';
import store from '@/simpleStore';
import footerModalData from '../mixins/footer-modal-data';
import newRelicTracking from '@/shared/utils/newRelicTracking';
import { mapActions } from 'vuex';
import { toUSDate } from '@/shared/utils/date';

export default {
  components: {
    EeContentWrapper,
    AboutMorningstar,
    MdsLayoutGrid,
    MdsRow,
    MdsCol,
    mdsAlert
  },
  mixins: [footerModalData],
  data() {
    return {
      pageContentParams: {
        isAMA: store.isAMA(),
        advisoryFirmId: null,
        clientId: null
      },
      warningMsg: ''
    };
  },
  created() {
    localStorage.setItem('showDefaultLandingLinks', false);
    this.setHeroTitle();

    const { EnrollmentDate } = store.getEnrollmentInfo();
    this.warningMsg = this.$contentTransposer('alreadyEnrolled.warningMsg', {
      enrollmentDate: toUSDate(new Date(EnrollmentDate))
    });
  },
  mounted() {
    newRelicTracking();
  },
  methods: {
    ...mapActions({
      setHeroText: 'setHeroText'
    }),
    setHeroTitle() {
      const heroText = this.$contentTransposer('hero.title');
      this.setHeroText(heroText);
    }
  }
};
</script>
<style lang="scss" scoped>
@import '@/assets/css/client/default/already-enrolled.scss';
</style>
