import Vue from 'vue';
import App from './App.vue';
import axios from 'axios';
import smoothscroll from 'smoothscroll-polyfill';
import simpleStore from '@/simpleStore';
import router from './router';
import VueCarousel from 'vue-carousel';
import store from './store';
import contentTransposer from '../src/plugins/contentTransposer';
import SVGImage from '@/components/Common/SVGImage';

const isDevelopment = process.env.NODE_ENV === 'development';

// kick off the polyfill!
smoothscroll.polyfill();
simpleStore.debug = isDevelopment;
Vue.prototype.$http = axios;

// IE11 friendly scrollTo functionality
Vue.prototype.$scrollTo = cssSelector => {
  const $targetElement = document.querySelector(cssSelector);
  const distanceTop = Math.floor($targetElement.getBoundingClientRect().top);

  window.scrollBy({
    top: distanceTop,
    left: 0,
    behavior: 'smooth'
  });
};

Vue.config.productionTip = false;
Vue.use(VueCarousel);
Vue.use(contentTransposer);
Vue.component('SVGImage', SVGImage);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
