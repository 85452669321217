export default `
<h2 class="subfeature-subheader">
  PLEASE READ THE FOLLOWING CAREFULLY<br />
  It contains important information about Personalized Portfolios
</h2>

<div class="paragraph-small">
  <p>
    Morningstar Investment Management LLC (“Morningstar”) and Resources Investment Advisors, LLC (“Resources”)
    (collectively, “we”, “us”, or “our”), are investment
    advisers registered with the United States Securities and Exchange Commission (the “SEC”) pursuant to the Investment
    Advisers Act of 1940, as amended (the
    “Advisers Act”) unless otherwise exempt from such registration.
  </p>

  <p>
    Please carefully review this Investment Advisory Agreement (the “Agreement”). By clicking “I ACCEPT” displayed
    below, by stating your acceptance to a call center
    representative (“Representative”), or by signing a paper enrollment form, you (i) acknowledge having received, read
    and understood the Agreement and agree to be
    bound by it; and (ii) represent to us that you are a citizen and/or legal resident of the United States or any of
    its territories.
  </p>

  <p>
    By clicking “I ACCEPT” displayed below, stating your acceptance to a Representative, or by signing a paper
    enrollment form, you acknowledge that you have either
    agreed to the electronic delivery of Morningstar Investment Management’s Relationship Summary and our Firm Brochures
    and Brochure Supplements and have reviewed
    them, or that you have received a paper copy of Morningstar Investment Management’s Relationship Summary and our
    Firm Brochures and Brochure Supplements and have
    reviewed them, and that you have received copies of our privacy policies. You also agree that all communications
    from us may be sent to you by email or by other
    electronic format such as posting on Morningstar’s web site. You understand that your consent to such electronic
    delivery is effective immediately upon your
    acceptance of this Agreement and will remain in effect unless and until either you or we withdraw it. You may
    withdraw your consent to electronic delivery or
    request a paper copy of this Agreement and/or Morningstar Investment Management’s Relationship Summary and our Firm
    Brochures, Brochure Supplements, and Privacy
    Policies by contacting a Representative at the telephone number listed in the “Contact Us” link on Morningstar’s web
    site. You may also contact Morningstar by
    writing Morningstar Investment Management LLC, 22 W. Washington Street, Chicago, IL 60602 Attn: Compliance
    Department (to request copies of Morningstar’s
    Relationship Summary, Firm Brochure, Brochure Supplement and/or Privacy Policy) or Resources by writing Resources
    Investment Advisors, LLC, 4860 College Blvd.,
    Overland Park, KS 66211 Attn: Chief Compliance Officer (to request copies of Resources’ Firm Brochure, Brochure
    Supplement, and/or Privacy Policy.)
  </p>

  <p>
    <u>You have the right to terminate this Agreement without penalty at any time after entering into this
      Agreement</u>. Federal law prohibits us from assigning
    this Agreement (within the meaning of the Advisers Act) to another investment adviser without your consent. Where
    applicable, federal law governs the terms of
    this Agreement and the provision of our investment advisory services (the “Services”).
  </p>

  <p>
    We agree to provide you with the Services that your plan sponsor (the “Plan Sponsor”), recordkeeper or service
    provider has decided to offer and that you have
    decided to accept by accepting this Agreement. Our agreement with the Plan Sponsor describes the respective duties
    and obligations of Morningstar and Resources
    in further detail. Under our agreement with Your Plan Sponsor, Resources is responsible for determining which
    investments will be used to create your portfolio.
    Morningstar will be responsible for allocating those investments to create your specific portfolio. You, the Plan
    Sponsor, Morningstar, and Resources each agree
    and acknowledge that Morningstar and the Resources are individually responsible for performance of their respective
    obligations, and that a breach of duty or
    non-performance of an obligation of Morningstar or Resources shall not cause the non-breaching party to be deemed to
    be in breach of its obligations under this
    Agreement. You understand that your Plan Sponsor or designated investment manager is responsible for selecting the
    universe of investment options that are to be
    used in your employer-sponsored retirement plan or other retirement account (“Account”), and that your Plan Sponsor
    or investment manager may change these
    options over time and that these investment options may include those that are affiliated with your service
    provider. In addition, Resources may require that
    certain investment options be available solely for use through the Personalized Portfolios platform.
  </p>

  <p>
    We will provide the Services to you at all times in good faith, and will use reasonable care, consistent with
    industry practices of similarly situated advisers,
    in providing the Services. However, we do not guarantee that the Services will be delivered to you without
    interruption, timely, error-free, or secure. Errors
    may occur in software-based Services as a result of programming errors, database errors, or other causes. We will
    provide the Services as a fiduciary, pursuant
    to the standard of care set forth in ERISA section 404(a). In the event an error occurs in our software-based
    Services, we reserve the right to correct such
    error in a manner that we deem prudent, subject to any applicable federal and state securities laws.
  </p>

  <p>
    The Services offered by us are for your personal use only, and are not to be used for any commercial or business
    purposes. You agree that we may assume that all
    information provided to us by you, your Plan Sponsor, recordkeeper, or service provider in connection with our
    Services is true and accurate.
  </p>

  <p>
    The Services offered by us are to be used by you <strong>only</strong> in making decisions about the allocation of
    assets in your Account. The Services are not
    designed to provide investment advice for an account that will be used by you for non-retirement purposes. The
    Services estimate your federal, state income, and
    capital gains taxes based on marginal tax rate calculations (the marginal tax rate is the rate you pay on the
    taxable income that falls into the highest bracket
    you reach). These calculations are used when the Services conduct the income simulations. Tax data is updated
    annually based on United States Internal Revenue
    Code (IRC) and similar state tax data. The Services use income data for you, as well as your spouse/partner (if
    provided), to estimate federal and state tax
    exposure. Your tax exposure is appropriately reduced for pre-tax deferrals, tax-deferred capital gains, and yield
    and distribution of Roth proceeds. Based on the
    information we know about you, the Services provide an estimate of your tax exposure, but may not include all tax
    considerations. Please consult a tax adviser
    for a complete understanding of your tax situation.
  </p>

  <p>
    We cannot and do not make any guarantee about the future performance or profitability of your Account, nor do we
    promise that our investment allocation
    recommendations will be profitable or that you will meet your retirement income goals. The investments that we may
    recommend may be subject to a variety of
    risks, including market, currency, and political risks. Please note that past performance of a mutual fund, stock,
    or other investment vehicle does not guarantee
    its future performance.
  </p>

  <p>
    You agree to use the Services in accordance with this Agreement. You are responsible for reviewing your Account
    periodically to monitor changes in your Account,
    including changes in the value of the investments in your Account. You also consent to the transmission of your
    personal information between us and your service
    provider or recordkeeper, and you acknowledge that you have received our privacy policy.
  </p>

  <p>
    The projections, recommendations and suggestions offered under the Services are based on information you provide
    about your current financial situation, personal
    status, as well as general market and financial conditions existing on the date you use the Services. You agree to
    provide complete and accurate information to
    the extent that the Services ask for such information. You also agree to update that information when your personal
    or financial circumstances change. While the
    Services take into consideration all assets that you choose to input to determine our investment recommendations,
    the Services are not designed to provide
    recommendations on how to structure your overall retirement holdings (i.e., your assets both inside and outside of
    your Account). You should consider your other
    assets, income, and investments in addition to your Account. The Services only apply to structure the holdings
    within your Account, and our Services may be
    limited by the investment choices available within your Account. You should consider consulting a professional
    financial adviser to discuss how other investment
    options outside of your Account might be combined with the Services to best meet your overall retirement goals.
  </p>
</div>

<h1 class="feature-header">
  Personalized Portfolios* Service
</h1>
<div class="paragraph-small">
  <p>
    The Personalized Portfolios Service is a discretionary asset management program designed for participants of a
    defined contribution or deferred compensation
    retirement plan, or owners of other types of retirement accounts, that are seeking a financial professional to
    manage the assets within their Account. If you
    elect to take part in the Personalized Portfolios Service by accepting this Agreement, we will act as your
    investment manager with respect to the specific
    services undertaken by each of us as set forth above, and you grant us authority as attorney-in-fact to act on your
    behalf and give us full decision-making
    authority to manage the investments in your Account without having to consult you in advance. We will have no
    responsibility or authority over (i) those assets
    that are subject to Plan Sponsor restrictions, (ii) those assets held in a self-directed brokerage window (if
    available under your plan), (iii) restricted
    employer company stock held in your Account, and (iv) any assets held outside of your Account. If you participate in
    the Personalized Portfolios Service,
    Morningstar and Resources acknowledge that we are “investment managers” (as that term is defined in ERISA Section
    3(38)) for your Account and a fiduciary of the
    Plan to the extent we have decision-making authority over the investments in your Account. You also understand that
    we <strong>will not</strong> vote proxies for
    the investment options in which you will be invested.
  </p>

  <p>
    In the Personalized Portfolios Service, Morningstar will typically review your Account on a quarterly basis and
    rebalance if necessary. However, please note that
    your plan recordkeeper or service provider may not be able to process rebalancing transactions if any investment
    option in your Account has any restriction
    (e.g., equity wash restriction) at the time the rebalancing transaction instruction is received by the plan
    recordkeeper or service provider. In addition,
    rebalancing transaction instructions may be rejected if any data validation error exists on your Account. In these
    instances, your Account may not be rebalanced
    until the next quarterly review period when all restrictions have been lifted and/or data validation errors have
    been corrected.
  </p>

  <p>
    You can request that investment restriction(s) be placed on your Account at any time, where “restriction” means the
    exclusion from your Account of one or more
    specific investments. If you seek to impose a restriction on your Account, you may communicate the request to
    877-742-2022. Resources will evaluate your request
    to determine whether it is reasonable, as determined by Resources. If your request is deemed inconsistent with the
    purpose of the Services or the investment
    objective of your Account, you can modify your request or discontinue using the Services.
  </p>

  <p>
    You agree to pay a fee to Morningstar and Resources for the Services provided under the Personalized Portfolios Service
    (the “Personalized Portfolios Fee”). Your Plan Sponsor has agreed to pay the plan recordkeeper or service provider an
    administrative fee in addition to the Managed Account Fee, a pro rata portion of which is charged to your account. 
    The total Managed Account Fee and administrative fee is {{planFeeText}}. 
    For specific fee information, including the calculation and the schedule of fee deductions from your Account, please see
    your Plan Sponsor, recordkeeper or service provider or contact a Representative at the telephone number listed in the
    “Contact Us” link on Morningstar’s web site. You may also contact Morningstar by writing Morningstar Investment
    Management LLC, 22 W. Washington Street, Chicago, IL 60602 Attn: Morningstar Workplace Solutions. The managed account
    fees is calculated based on your total current plan balance
    minus any amount in company stock, a brokerage
    window, or any outstanding loan balance. You authorize your service provider or recordkeeper to deduct the
    Personalized Portfolios Fee from your Account at the
    end of each calendar quarter (based on the frequency that your
    recordkeeper deducts payment) in arrears and remit the Personalized Portfolios Fee to Morningstar and Resources. A
    prorated Personalized Portfolios Fee will be
    calculated for any individual not in the program a full calendar quarter and shall be determined by the date of
    entry into or exit from the program. In the event
    that this Agreement is terminated, either by you, Morningstar, or Resources, the quarterly installment of the
    Personalized Portfolios Fee that you will be
    charged will be based on the number of days in the final calendar quarter in which you receive the Personalized
    Portfolios Service.
  </p>

  <p>
    The Personalized Portfolios Fee does not include any redemption fees, charges or expenses imposed by any investment
    options (e.g., mutual funds) held within your
    Account. These investment options may be subject to separate investment advisory, administration, transfer agency,
    distribution, shareholder service and other
    expenses that are paid by you, indirectly, as a shareholder/unit holder. The Personalized Portfolios Fee paid may
    not be the same as that charged to other
    clients of comparable size or with similar investment objectives. The payment arrangements depend on the agreements
    between your Plan Sponsor, your recordkeeper
    or service provider, Morningstar, and Resources. Your recordkeeper or service provider may also charge you or your
    Plan Sponsor a fee to cover the administrative
    and other recordkeeping costs associated with the Personalized Portfolios Service.
  </p>

  <p>
    *Please note the Personalized Portfolio name was chosen by Resources. In Morningstar’s Relationship Summary, Firm
    Brochure and other documents, Personalized
    Portfolios are called “Managed Accounts.” Although a different name is used, references to Managed Accounts apply to
    the service you receive.
  </p>
</div>

<h1 class="feature-header">
  Company Stock
</h1>
<div class="paragraph-small">
  <p>
    If your Account includes securities issued by your employer that are freely marketable without restrictions
    (“Non-Restricted Company Stock”), you may choose to
    sell or retain the Non-Restricted Company Stock. Morningstar’s methodology provides that 25% of the Non-Restricted
    Company Stock in your Account will be sold
    each time your Account is reviewed by us. If you are enrolled in the Personalized Portfolios Service, and you choose
    to sell your Non-Restricted Company Stock,
    you hereby direct Morningstar to send a transaction to sell 25% of your Non-Restricted Company Stock upon you
    completing a web session or upon the quarterly
    review of your Account, or 100% of your Non-Restricted Company Stock when the Non-Restricted Company Stock balance
    reaches $3,000 or 3% of your Account balance.
    Morningstar will send a transaction to sell 100% immediately if instructed to do so by you. The sale of
    Non-Restricted Company Stock will also include the sale
    of any new Non-Restricted Company Stock that is allocated automatically to your Account. If you choose to retain
    Non-Restricted Company Stock in your Account,
    you may elect to do so through the Personalized Portfolios web site. Morningstar shall have no responsibility with
    respect to any securities issued by your
    employer that are not freely marketable or subject to any restrictions.
  </p>
</div>

<h1 class="feature-header">
  Annual Spending Amount
</h1>
<div class="paragraph-small">
  <p>
    If you have begun to take withdrawals from your Account, as part of our proposed strategy in both the Personalized
    Portfolios Service, Morningstar will display
    an Annual Spending Amount. The Annual Spending Amount does not represent a guarantee that you will receive a
    specific annual amount. Rather, Morningstar will use
    your available information to come up with a projection that assists you in evaluating the amount of money derived
    from various sources of income that will fund
    your retirement. You should revisit our Services at least annually to review the Annual Spending Amount as changes
    to your financial situation may occur
    throughout the year.
  </p>
  <p>
    Below are some important questions and answers regarding the investment options available in your plan:
  </p>
</div><br>

<h2 class="subfeature-subheader">
  Who selected the investment options available in my plan?
</h2>
<div class="paragraph-small">
  <p>
    Your Plan Sponsor or the investment manager appointed by your Plan Sponsor is responsible for determining what
    investment options are made available to you in
    your plan. There may be instances in which the Plan Sponsor or investment manager were required to use, or
    prohibited from using, certain investment options as a
    pre-requisite of utilizing our Personalized Portfolios Services.
  </p>
</div>

<h2 class="subfeature-subheader">
  What are the past performances and historical rates of return of the investment options available in my plan?
</h2>
<div class="paragraph-small">
  <p>
    For information about the past performance and other pertinent information regarding the investment options
    available in your plan, please click on the
    Investment Research link within the Personalized Portfolios web site.
  </p>
</div>

<h2 class="subfeature-subheader">
  Does Morningstar or its affiliates have any material affiliation or contractual relationship with the investment
  options available in my plan?
</h2>
<div class="paragraph-small">
  <p>
    In most cases, Morningstar does not have a contractual relationship with any of the investment options available in
    your plan. However, in some cases Morningstar
    or its affiliates provide advisory services to funds that may be available as an investment option in your plan. To
    mitigate the conflict of interest from this
    relationship, Morningstar will not include recommendations into these investment options through the Services.
  </p>
  <p>
    Additionally, Morningstar may have a contractual relationship with and may receive compensation from your plan’s
    service provider for making the Services
    available to your plan and to the individuals that use the Services. Additionally, one or more of the investment
    options available in your plan may be affiliated
    with your plan’s service provider. To mitigate a conflict of interest from this relationship, the fund
    recommendations are based on an objective methodology, and
    our compensation does not vary based on the funds that Resources recommends.
  </p>
  <p>
    In addition, Morningstar’s parent company, Morningstar, Inc., offers numerous products and services to the financial
    community. Therefore, there may be instances
    in which an investment options’ investment adviser uses Morningstar, Inc. products and services. A conflict of
    interest resulting from this kind of situation is
    mitigated by the fact that recommendations provided by us are derived from a quantitative process which in no way is
    influenced by the products and services
    provided by Morningstar, Inc.
  </p>
</div>
<h2 class="subfeature-subheader">
  Does Resources or its affiliates have any material affiliation or contractual relationship with the investment options
  available in my plan?
</h2>
<div class="paragraph-small">
  <p>
    Resources does not receive any direct or indirect compensation from product providers for the investment products it
    recommends, except to the extent those
    sources are used to pay the compensation due under this Agreement or to cover expenses incurred by Resources’
    employees and agents to attend industry and
    educational events, as well as to learn about the provider’s products and to host client events. (Resources
    maintains a register of such payments and will make
    this information available to you upon request.) Resources may serve as the investment manager for some or all of
    the investments it selects for use in the
    Personalized Portfolios Service. However, in that event, Resources will not receive any compensation for its service
    as the investment manager for those
    investments. Therefore, the only compensation Resources derives from its provision of the Services under this
    Agreement are the fees referenced herein.
  </p>
</div>

<h1 class="feature-header">
  Miscellaneous
</h1>
<div class="paragraph-small">
  <p>
    We reserve the right, in our complete and sole discretion, to alter, modify, add, update or remove portions of this
    Agreement with appropriate advanced notice to
    you. Using the Services after we provide notice of any changes constitutes your acceptance of any changed terms. We
    expressly reserve the right to monitor any
    and all use of the Services.
  </p>
  <p>
    All trademarks, service marks, trade names and other intellectual property displayed in connection with the Services
    are the property of Morningstar and
    Resources. You acknowledge that United States copyright law and other laws governing intellectual property protect
    the Services and the information contained in
    the Services. You also agree and acknowledge that the Services contain proprietary data and information of
    Morningstar and Resources, and you agree that you will
    not use such data or information for any unlawful purpose, or any commercial or business purpose.
  </p>
  <p>
    We may terminate this Agreement and your access to the Morningstar Personalized Portfolios web site and Services
    immediately if we determine that you have
    breached this Agreement. We may terminate this Agreement and your access to the Personalized Portfolios web site and
    the Services immediately if we do not
    receive timely payment for the Services. We may also terminate this Agreement and your access to the Personalized
    Portfolios web site and the Services if the
    agreement between us and your service provider, recordkeeper or Plan Sponsor is terminated. You have the right to
    terminate this Agreement without penalty at any
    time.
  </p>
  <p>
    Termination of this Agreement will not affect the provisions of this Agreement relating to the validity of any
    action taken prior to termination, or liabilities
    for actions taken prior to termination.
  </p>
  <p>
    Except as otherwise provided by law, we will not be responsible for any loss resulting from our use of inaccurate,
    outdated or incomplete information furnished
    by you or through your Plan Sponsor, service provider or recordkeeper. Federal and state securities laws and the
    Employee Retirement Income Security Act of 1974,
    as amended (“ERISA”), to the extent applicable, impose liabilities in certain circumstances and nothing in this
    Agreement waives or limits any rights you may
    have under those laws. We will not be responsible for any loss caused directly or indirectly by government
    restrictions, exchange or market rulings, suspension
    of trading (including suspension of redemption rights in your investment option), war, natural disasters, or other
    conditions beyond our control, including
    extreme market volatility.
  </p>
  <p>
    If any provision of these terms is deemed unlawful, void, or for any reason unenforceable, then that provision will
    be deemed severable from these terms and will
    not affect the validity and enforceability of the remaining provisions.
  </p>
  <p>
    Nothing herein will be construed in any manner inconsistent with the Advisers Act, ERISA (if applicable), or any
    rule or order of the SEC.
  </p>
</div>

`;
