<template>
  <div class="strategy-details">
    <MdsLayoutGrid>
      <MdsRow align-horizontal="center" class="header">
        <MdsCol :cols-at-m="10">
          <h2 class="header--title" data-ee-test="advice-strategy-header">
            {{ $contentTransposer('advice.strategy.header') }}
          </h2>
          <p
            v-if="isNonVRA"
            class="header--sub-title"
            data-ee-test="advice-strategy-sub-header"
          >
            {{ $contentTransposer('advice.strategy.subHeader') }}
          </p>
        </MdsCol>
      </MdsRow>
      <MdsRow
        :key="idx"
        v-for="(strategy, idx) in getStrategies"
        :data-ee-test="`strategy-details-row-${idx}`"
        class="content-wrapper"
        align-horizontal="center"
      >
        <MdsCol :cols-at-m="10">
          <div class="content">
            <div>
              <SVGImage
                path="check-mark"
                name="Check-icon"
                class="content--icon"
              />
            </div>
            <div>
              <p
                v-html="strategy"
                class="content--text"
                data-ee-test="advice-strategy-content"
              />
            </div>
          </div>
        </MdsCol>
      </MdsRow>
    </MdsLayoutGrid>
  </div>
</template>

<script>
import { MdsLayoutGrid, MdsRow, MdsCol } from '@mds/layout-grid';
import store from '@/simpleStore';

export default {
  components: {
    MdsRow,
    MdsCol,
    MdsLayoutGrid
  },
  computed: {
    getStrategies() {
      return this.$contentTransposer('advice.strategy.contents');
    },
    isNonVRA() {
      return !(
        store.getClientId().toUpperCase() === 'INGG' &&
        store.getVersion().toUpperCase() === 'VRA'
      );
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/client/default/strategy-details.scss';
@import '@/assets/css/client/ingg/strategy-details.scss';
</style>
