<template>
  <MdsLayoutGrid class="about-morningstar">
    <h3 class="ee__section-heading">
      {{ $contentTransposer('aboutMorningStar.header') }}
    </h3>
    <MdsRow class="wrapper" align-horizontal="space-around">
      <MdsCol
        :cols="12"
        :cols-at-m="4"
        :key="idx"
        v-for="(column, idx) in getColumns"
      >
        <SVGImage
          :path="`${column.imagePath}`"
          :name="`${column.imageID}`"
          :isClientSpecificSVG="getShowInggVraUX"
          class="icon"
        />
        <p :class="`sub-header sub-header--${column.className}`">
          {{ column.subHeader }}
        </p>
        <p class="content">
          {{ column.description }}
        </p>
      </MdsCol>
    </MdsRow>
  </MdsLayoutGrid>
</template>

<script>
import { mapGetters } from 'vuex';
import { MdsLayoutGrid, MdsRow, MdsCol } from '@mds/layout-grid';

export default {
  components: {
    MdsLayoutGrid,
    MdsRow,
    MdsCol
  },
  computed: {
    ...mapGetters({
      getShowInggVraUX: 'getShowInggVraUX'
    }),
    getColumns() {
      return this.$contentTransposer('aboutMorningStar.columns');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/client/default/about-morningstar.scss';
@import '@/assets/css/client/ingg/about-morningstar.scss';
</style>
