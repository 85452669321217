import incomeProjectionData from '../../assets/json/incomeProjectionData.json';
import { closest } from '../../components/utils';

const estimatedSalaryRanges = {
  '1': '$1 - $24,999',
  '2': '$25,000 - $49,999',
  '3': '$50,000 - $74,999',
  '4': '$75,000 - $99,999',
  '5': '$100,000 - $124,999',
  '6': '$125,000 - $149,999',
  '7': '$150,000 - $174,999',
  '8': '$175,000 - $199,999',
  '9': '$200,000 - $224,999',
  '10': '$225,000 - $249,999',
  '11': '$250,000 - $274,999',
  '12': '$275,000 - $299,999',
  '13': '$300,000 - $324,999'
};

export const getIncomeProjectionData = yearOfBirth => {
  const recommendedRetirementAge = getRecommendedRetirementAge(yearOfBirth);
  const data = pick(
    incomeProjectionData,
    ...Object.keys(incomeProjectionData).filter(d =>
      Object.values(incomeProjectionData[d]).some(v =>
        v.some(e => +e.age === recommendedRetirementAge)
      )
    )
  );
  if (data[yearOfBirth] !== undefined) {
    return data[yearOfBirth];
  }
  const closestYear = closest(yearOfBirth, Object.keys(data)) + '';
  return data[closestYear];
};

export const getEstimatedSalaryRanges = (
  incomeProjectionData,
  recommendedAge
) => {
  return Object.keys(incomeProjectionData)
    .filter(key =>
      incomeProjectionData[key].some(e => +e.age === recommendedAge)
    )
    .map(key => ({
      text: estimatedSalaryRanges[key],
      value: key
    }));
};

export const getRecommendedRetirementAge = yearOfBirth => {
  const currentAge = new Date().getFullYear() - yearOfBirth;
  return currentAge < 67 ? 67 : currentAge + 1;
};

const pick = (obj, ...props) => {
  return props.reduce(function(result, prop) {
    result[prop] = obj[prop];
    return result;
  }, {});
};
