export default `<div class="row">
<div class="col-xs-12">
    <p class="paragraph-small">
        Please read this document carefully as it contains important information about Morningstar Investment
        Management LLC's Privacy Policy
    </p>
</div>

<div class="col-xs-3">
    <h2 class="subfeature-subheader">Why we need your personal information</h2>
</div>
<div class="col-xs-9">
    <p class="paragraph-small">
        Financial companies can choose how they share your personal information. Federal law gives consumers the
        right to limit some, but not all sharing. Federal law also requires us to tell you how we collect, share,
        and protect your personal information. Please read this notice carefully to understand what we do.<br><br>
        As necessary, we seek certain personal information about you to provide you with services. This information
        is used primarily to provide you with investment advice, but is also used to perform such activities as
        responding to your requests and inquiries. By using our services, you consent to the collection and use of
        your personal information and any related information in the manner described in this document.
    </p>
</div>
</div>

<div class="row">
<div class="col-xs-3">
    <h2 class="subfeature-subheader">Personal information we need</h2>
</div>
<div class="col-xs-9 paragraph-small">
    <p>
        The personal information we collect depends on which product or service you use. This information can
        include:
    </p>
    <ul>
        <li>Your name, address, phone number, and email address</li>
        <li>Your Social Security number or other unique identifier</li>
        <li>Your account information, such as account balance, contributions, etc.</li>
        <li>Your demographic information, such as age, gender, salary, etc.</li>
        <li>Your usage data, such as number of logins or number of transactions generated, etc.</li>
    </ul>
    <p>
    We may share some of this data in order to conduct our everyday business. We do not share any of your
    information when you are no longer our client.
    </p>
</div>
</div>

<div class="row">
<div class="col-xs-3">
    <h2 class="subfeature-subheader">Why we may share your personal information</h2>
</div>
<div class="col-xs-9">
    <p class="paragraph-small">
        All financial companies need to share clients’ personal information to run their everyday business. In the
        section below, we list the reasons financial companies can share their clients’ personal information, the
        reasons we choose to share, and whether you can limit this sharing.
    </p>
</div>
</div>

<div class="col-xs-12">
<div class="row border-bottom-dark margin-top-fuller">
    <div class="col-xs-6">
        <p class="subfeature-subheader">Reasons financial companies can share your personal information</p>
    </div>
    <div class="col-xs-3">
        <p class="subfeature-subheader">Do we share?</p>
    </div>
    <div class="col-xs-3">
        <p class="subfeature-subheader">Can you limit this sharing?</p>
    </div>
</div>
<div class="row border-bottom-dark">
    <div class="col-xs-6">
        <p class="paragraph-small">
            For our everyday business purposes—such as to process your transactions, maintain your account(s), or
            respond to court orders and legal investigations
        </p>
    </div>
    <div class="col-xs-3">
        <p class="paragraph-small">Yes. See “Other important information” below.</p>
    </div>
    <div class="col-xs-3">
        <p class="paragraph-small">No</p>
    </div>
</div>
<div class="row border-bottom-dark">
    <div class="col-xs-6">
        <p class="paragraph-small">For our marketing purposes—to offer our products and services</p>
    </div>
    <div class="col-xs-3">
        <p class="paragraph-small">Yes. See “Other important information” below.</p>
    </div>
    <div class="col-xs-3">
        <p class="paragraph-small">No</p>
    </div>
</div>
<div class="row border-bottom-dark">
    <div class="col-xs-6">
        <p class="paragraph-small">For joint marketing with other financial companies</p>
    </div>
    <div class="col-xs-3">
        <p class="paragraph-small">No</p>
    </div>
    <div class="col-xs-3">
        <p class="paragraph-small">N/A</p>
    </div>
</div>
<div class="row border-bottom-dark">
    <div class="col-xs-6">
        <p class="paragraph-small">For our affiliates’ everyday business purposes— information about your
            transactions and experiences
        </p>
    </div>
    <div class="col-xs-3">
        <p class="paragraph-small">No</p>
    </div>
    <div class="col-xs-3">
        <p class="paragraph-small">N/A</p>
    </div>
</div>
<div class="row border-bottom-dark">
    <div class="col-xs-6">
        <p class="paragraph-small">For our affiliates’ everyday business purposes— information about your credit
            worthiness
        </p>
    </div>
    <div class="col-xs-3">
        <p class="paragraph-small">No</p>
    </div>
    <div class="col-xs-3">
        <p class="paragraph-small">N/A</p>
    </div>
</div>
<div class="row border-bottom-dark">
    <div class="col-xs-6">
        <p class="paragraph-small">For our affiliates to market to you</p>
    </div>
    <div class="col-xs-3">
        <p class="paragraph-small">No</p>
    </div>
    <div class="col-xs-3">
        <p class="paragraph-small">N/A</p>
    </div>
</div>
<div class="row border-bottom-dark">
    <div class="col-xs-6">
        <p class="paragraph-small">For nonaffiliates to market to you</p>
    </div>
    <div class="col-xs-3">
        <p class="paragraph-small">No</p>
    </div>
    <div class="col-xs-3">
        <p class="paragraph-small">N/A</p>
    </div>
</div>
<div class="row">
    <div class="col-xs-6">
        <p class="paragraph-small">For our research purposes</p>
    </div>
    <div class="col-xs-3">
        <p class="paragraph-small">Yes. See "Other important information" below.</p>
    </div>
    <div class="col-xs-3">
        <p class="paragraph-small">No</p>
    </div>
</div>
</div><br>
<h2 class="subfeature-subheader">How we protect your personal information</h2>
<div class="paragraph-small">
<p>
    <em>How does Morningstar Investment Management protect my personal information?</em><br>
    We use appropriate security measures to protect against unauthorized access, alteration, disclosure or destruction
    of personal information. These measures include computer safeguards and physical security measures to guard against
    unauthorized access to systems where we store personal data. We operate secure data networks protected by industry
    standard firewall and password protection systems.
    <br><br>
    We use cookies that store session information in numerical value form and time stamp. This information also allows
    us to collect general usage data such as which features have been utilized.
    <br><br>
    <em>How does Morningstar Investment Management collect my personal information?</em><br>
    We collect your personal information from a variety of sources, for example:
</p>
<ul>
    <li>from you when you access our service directly</li>
    <li>from your authorized financial professional (if applicable)</li>
    <li>from Your Employer or an agent of Your Employer</li>
    <li>from your plan record-keeper or plan service provider</li>
</ul>
<p>
    <em>Why can’t I limit all sharing?</em>
    <br>Federal law only gives you the right to limit:
</p>
<ul>
    <li>sharing for affiliates' everyday business purposes &mdash; information about your creditworthiness</li>
    <li>affiliates from using your information to market to you</li>
    <li>sharing with nonaffiliates for the purpose of their marketing to you</li>
    <li>State laws and individual companies' policies may give you additional rights to limit sharing</li>
</ul>
</div>

<h2 class="subfeature-subheader">Definitions</h2>
<div class="paragraph-small">

<p class="affiliateDefinition">
    <em>Affiliates</em><br>
    Companies related by common ownership or control. They can be financial and nonfinancial companies.
</p>
<ul>
    <li class="affiliateBullet">
        Our affiliates include companies within the Morningstar, Inc. family of companies, including Morningstar Investment Services LLC.
    </li>
</ul>
<p class="nonAffiliateDefinition">
    <em>Nonaffiliates</em>
    <br>Companies not related by common ownership or control. They can be financial and nonfinancial companies.
</p>
<ul>
    <li class="nonAffiliateBullet">
        Morningstar Investment Management does not share your personal information with nonaffiliates for the purpose of their marketing their services to you.
    </li>
</ul>
<p class="jointMarketingDefinition">
    <em>Joint marketing</em>
    <br>A formal agreement between nonaffiliated financial companies that together market financial products or services to you.
</p>
<ul>
    <li class="jointMarketingBullet">
        Morningstar Investment Management does not share your personal information for any such joint marketing activities.
    </li>
</ul>

</div>

<h2 class="subfeature-subheader">Other important information</h2>
<p class="paragraph-small">
As a general rule, we will not make your personal information available to anyone outside of Morningstar Investment
Management or our affiliates, except as instructed by you or where required to comply with law. Please note,
however, that there are some exceptions to this policy. We may share your personal information with third parties
who provide contractually specified services, such as performing record-keeping, producing reports and assisting us
with our marketing activities. Additionally, we may share certain types of anonymized personal information, such as
your anonymized usage data, with select third parties for the purposes of their conducting research studies (e.g.,
on investor behavior) and publishing the results of those research studies in publicly-available research
reports/papers. Anonymized information means your personally identifiable information will be removed, and data is
aggregated for statistical purposes. In either case, we limit access to your personal information to those third
parties that have agreed to keep it strictly confidential. We may use your information which includes, but is not
limited to, your name and email address, to contact you directly for research opportunities (e.g. product surveys)
conducted by Morningstar Investment Management or our affiliates. We will not sell your personal information to anyone. As
noted above, we may disclose personal information as permitted by Regulation S-P to nonaffiliates that provide
services relating to maintaining or servicing accounts, such as a record-keeper or retirement account service
provider.
<br><br>
We reserve the right to change this policy at any time by distributing and/or posting a new privacy policy without
notice. We encourage you to review our privacy policy on a regular basis so that you are aware of any changes.
</p>

<h2 class="subfeature-subheader">Questions?</h2>
<p class="paragraph-small">
If you have further questions, contact us at
<a href="mailto:compliancemail@morningstar.com?Subject=Privacy%20Policy%20Question">compliancemail@morningstar.com</a>.
</p>
`;
