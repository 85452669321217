import {
  LAST_NAME,
  ENTRY_CODE,
  DATE_OF_BIRTH,
  INVALID_GENERIC_ERROR_ID,
  INVALID_VALUE_ERROR_ID,
  INVALID_DAY_ERROR_ID,
  INVALID_MONTH_ERROR_ID,
  INVALID_YEAR_ERROR_ID,
  errorMessages
} from '../constants';

const isCorrectLength = (value, length) => value.length === length;
// https://www.regextester.com/
const onlyAlphaNumeric = /^[a-z0-9]+$/i;
const onlyNumeric = /^[0-9]+$/i;
// MM/DD/YYYY format
const validDateFormat = /^(\d\d[/]\d\d[/]\d\d\d\d)$/;
const validMonthValue = /^(0[1-9]|1[012])/;
const validDayValue = /^.{3}(0[1-9]|[12][0-9]|3[01])/;
const validYearValue = /^.{6}(19|20)\d\d/;
const validLastNameValue = /^[A-Za-z-]+$/;
const isNotOnlyAphaNumeric = value => !onlyAlphaNumeric.test(value);
const isNotOnlyNumeric = value => !onlyNumeric.test(value);
const isNotValidDateFormat = value => !validDateFormat.test(value);
const isNotValidDayValue = value => !validDayValue.test(value);
const isNotValidMonthValue = value => !validMonthValue.test(value);
const isNotValidYearValue = value => !validYearValue.test(value);
const isNotValidLastNameValue = value => !validLastNameValue.test(value);
const enrollmentData = {
  LastName: null,
  ProposalRefId: null,
  DOB: null
};

const getLastNameValidationCheck = fieldValue => {
  if (isNotValidLastNameValue(fieldValue)) {
    return INVALID_VALUE_ERROR_ID;
  }
};

const getRefIDValidationCheck = fieldValue => {
  if (isNotOnlyAphaNumeric(fieldValue)) {
    return INVALID_VALUE_ERROR_ID;
  }
  if (
    (isNotOnlyNumeric(fieldValue) && !isCorrectLength(fieldValue, 15)) ||
    (!isNotOnlyNumeric(fieldValue) && fieldValue.length < 6)
  ) {
    return INVALID_GENERIC_ERROR_ID;
  }
};

const getDOBValidationCheck = fieldValue => {
  if (isNotValidDateFormat(fieldValue)) {
    return INVALID_VALUE_ERROR_ID;
  }
  if (isNotValidMonthValue(fieldValue)) {
    return INVALID_MONTH_ERROR_ID;
  }
  if (isNotValidDayValue(fieldValue)) {
    return INVALID_DAY_ERROR_ID;
  }
  if (isNotValidYearValue(fieldValue)) {
    return INVALID_YEAR_ERROR_ID;
  }
};

const validators = (fieldId, fieldValue) => {
  switch (fieldId) {
    case ENTRY_CODE:
      return getRefIDValidationCheck(fieldValue);
    case DATE_OF_BIRTH:
      return getDOBValidationCheck(fieldValue);
    case LAST_NAME:
      return getLastNameValidationCheck(fieldValue);
    default:
      return '';
  }
};

export const validate = (fieldId, fieldValue) =>
  validators(fieldId, fieldValue);

export const getErrorMessage = (fieldId, errorId) =>
  errorMessages[fieldId][errorId];

export const getEnrollmentData = fields => {
  Object.keys(enrollmentData).forEach((item, idx) => {
    enrollmentData[item] = fields[idx].value;
  });
  return enrollmentData;
};

export const closest = (value, group) => {
  return group.reduce((previousValue, currentValue) => {
    const previousDiff = Math.abs(previousValue - value);
    const currentDiff = Math.abs(currentValue - value);

    if (previousDiff === currentDiff) {
      return previousValue > currentValue ? previousValue : currentValue;
    } else {
      return currentDiff < previousDiff ? currentValue : previousValue;
    }
  });
};
