import store from '../simpleStore';

export const calculateProgramFees = () => {
  const programFees = store.getProgramFees();
  const planFees = programFees[0].PlanFees;
  const ignoreClientFee = planFees.IgnoreClientFee;
  const ignoreClientPlanFee = planFees.IgnoreClientPlanFee;
  const ignorePlanFee = planFees.IgnorePlanFee;
  const planProgramFees = planFees.Fees;
  const clientFees = programFees[0].ClientFees;
  const clientProgramFee = getNominalProgramFee(clientFees);
  const feeStatement = constructAdvisoryFeeStatement(
    ignorePlanFee,
    clientProgramFee,
    planProgramFees,
    ignoreClientFee,
    ignoreClientPlanFee
  );
  return feeStatement;
};

const getNominalProgramFee = clientFees =>
  clientFees.PlanProgramFeeOverride || clientFees.ProgramFee || 0;

const constructAdvisoryFeeStatement = (
  ignorePlanFee,
  clientProgramFee,
  planProgramFees,
  ignoreClientFee,
  ignoreClientPlanFee
) => {
  const clientFee = setClientFee(
    ignoreClientPlanFee,
    ignoreClientFee,
    clientProgramFee
  );
  const usePlanFee = planFeeUsable(
    ignoreClientPlanFee,
    ignorePlanFee,
    planProgramFees
  );
  if (usePlanFee) {
    return planFeeAdvisoryStatement(planProgramFees, clientFee);
  }
  return singleTierFeeAdvisoryStatement(clientFee);
};

const setClientFee = (
  ignoreClientPlanFee,
  ignoreClientFee,
  clientProgramFee
) => {
  const isClientFeeIgnored = ignoreClientPlanFee || ignoreClientFee;
  return isClientFeeIgnored ? 0 : clientProgramFee;
};

const planFeeUsable = (ignoreClientPlanFee, ignorePlanFee, planProgramFees) => {
  const isPlanFeeIgnored = ignoreClientPlanFee || ignorePlanFee;
  const planFeeExists = planProgramFees && planProgramFees.length;
  return !isPlanFeeIgnored && planFeeExists;
};

export const planFeeAdvisoryStatement = (planFeeArray, clientProgramFee) => {
  const feeTiers = countOfFeeTiers(planFeeArray);
  if (feeTiers < 2) {
    return singleTierFeeAdvisoryStatement(
      planFeeArray[0].BasisPoints + clientProgramFee
    );
  }
  return planFeeArray.reduce((statement, tier, index) => {
    const { AssetMax, AssetMin, BasisPoints } = tier;
    if (!statement) {
      return `${basisPointsToPercentage(
        BasisPoints
      )}% on the first $${AssetMax}`;
    }
    if (index === feeTiers - 1) {
      return `${statement} and ${basisPointsToPercentage(
        BasisPoints
      )}% on any balance above $${AssetMin - 1} of your Account value`;
    }
    return `${statement}, ${basisPointsToPercentage(
      BasisPoints
    )}% on the next $${AssetMax}`;
  }, '');
};

export const singleTierFeeAdvisoryStatement = singleTierFee => {
  return `${basisPointsToPercentage(singleTierFee)}% of your Account value`;
};

const countOfFeeTiers = planFeeArray => planFeeArray.length;

const basisPointsToPercentage = basisPoints => (basisPoints * 0.01).toFixed(2);
