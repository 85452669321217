<template>
  <MdsLayoutGrid class="confirm-enrollment">
    <MdsRow class="heading">
      <MdsCol :cols="12">
        <h1 class="heading--title" data-ee-test="enroll-confirmation-heading">
          {{ getHeaderMsg() }}
        </h1>
      </MdsCol>
    </MdsRow>
    <MdsRow align-horizontal="center">
      <MdsCol :cols="12" :cols-at-m="4" align-vertical="center">
        <SVGImage
          path="enroll-illustration"
          name="enroll-page-illustration"
          class="svg"
        />
      </MdsCol>
      <MdsCol :cols="12" :cols-at-m="5" class="content">
        <h4 data-ee-test="enroll-confirmation-text">
          <span
            v-html="
              $contentTransposer('enroll.confirmationText', {
                accountText: accountText,
                productName: getProductName
              })
            "
          />
          <span
            v-if="model.email"
            v-html="
              $contentTransposer('enroll.emailText', { email: model.email })
            "
            data-ee-test="enroll-confirmation-email"
          />
        </h4>
        <contribution-details />
      </MdsCol>
    </MdsRow>
  </MdsLayoutGrid>
</template>
<script>
import { MdsLayoutGrid, MdsRow, MdsCol } from '@mds/layout-grid';
import store from '@/simpleStore';
import { toLower, upperFirst } from 'lodash';
import pluralize from 'pluralize';
import ContributionDetails from './ContributionDetails';

export default {
  components: {
    MdsLayoutGrid,
    MdsRow,
    MdsCol,
    ContributionDetails
  },
  props: {
    isSameSavingsRate: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      model: {
        name: null,
        email: null,
        enableContributionChanges: store.getContributionChangesFlag()
      }
    };
  },
  computed: {
    accountText() {
      return pluralize('account', store.getTotalAccounts());
    },
    getProductName() {
      return this.$contentTransposer('advisorFirmInfo.productName');
    },
    isActive() {
      const accounts = store.getAccounts();
      return accounts.some(account => account.Status === 'Active');
    }
  },
  created() {
    const { FirstName, Email } = store.getParticipantInfo();
    this.model.name = upperFirst(toLower(FirstName));
    this.model.email = Email;
    this.getHeaderMsg();
  },
  methods: {
    getHeaderMsg() {
      let headerMsg = null;
      const path = 'enroll.header';
      headerMsg =
        !this.model.enableContributionChanges &&
        !this.isSameSavingsRate &&
        this.isActive
          ? this.$contentTransposer(`${path}.done`)
          : this.$contentTransposer(`${path}.set`);
      return `${headerMsg}${this.model.name}.`;
    }
  }
};
</script>
<style lang="scss" scoped>
@import '@/assets/css/client/default/confirm-enrollment.scss';
@import '@/assets/css/client/ingg/confirm-enrollment.scss';
</style>
