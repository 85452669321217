export default `
<h2 class="subfeature-subheader">
  PLEASE READ THE FOLLOWING CAREFULLY<br>
  It contains important information about Managed Accounts
</h2>
<br>
<div class="paragraph-small">
  <p>
    Morningstar Investment Management LLC (“Morningstar”) and Creative Planning, LLC (“CREATIVE”)
    (collectively, “we”, “us”, or “our”), are investment advisers registered with the United States Securities and
    Exchange Commission (the “SEC”) pursuant to the Investment Advisers Act of 1940, as amended (the “Advisers Act”)
    unless otherwise exempt from such registration.
  </p>
  <p>
    Please carefully review this Investment Advisory Agreement (the “Agreement”). By clicking “I ACCEPT” displayed
    below, by stating your acceptance to a call center representative (“Representative”), or by signing a paper
    enrollment form, you (i) acknowledge having received, read and understood the Agreement and agree to be bound by it;
    and (ii) represent to us that you are a citizen and/or legal resident of the United States or any of its territories.
  </p>
  <p>
    By clicking “I ACCEPT” displayed below, stating your acceptance to a Representative, or by signing a paper
    enrollment form, you acknowledge that you have either agreed to the electronic delivery of our Relationship
    Summaries and our Firm Brochures and Brochure Supplements and have reviewed them, or that you have received
    a paper copy of our Relationship Summaries and our Firm Brochures and Brochure Supplements and have reviewed
    them, and that you have received copies of our Privacy Policies.  You also agree that all communications from us
    may be sent to you by email or by other electronic format such as posting on Morningstar’s web site.  You understand
    that your consent to such electronic delivery is effective immediately upon your acceptance of this Agreement and
    will remain in effect unless and until either you or we withdraw it.  You may withdraw your consent to electronic
    delivery or request a paper copy of this Agreement and/or Morningstar Investment Management’s Relationship Summary
    and our Firm Brochures, Brochure Supplements, and Privacy Policies by contacting a Representative at the telephone
    number listed in the “Contact Us” link on Morningstar’s web site.  You may also contact Morningstar by writing
    Morningstar Investment Management LLC, 22 W. Washington Street, Chicago, IL 60602 Attn: Compliance Department
    (to request copies of Morningstar’s Relationship Summary, Firm Brochure, Brochure Supplement and/or Privacy Policy)
    or CREATIVE by writing Creative Planning, 5454 W 110th Street, Overland Park, KS 66211 Attn: Managed Account Compliance 
    Coordinator (to request copies of CREATIVE’s Relationship Summary, Firm Brochure, Brochure Supplement, and/or Privacy Policy.)
  </p>
  <p>
    <u>You have the right to terminate this Agreement without penalty at any time after entering into this
      Agreement.</u> Federal law prohibits us from assigning this Agreement (within the meaning of the Advisers Act) to
    another investment adviser without your consent. Where applicable, federal law governs the terms of this Agreement
    and the provision of our investment advisory services (the “Services”).
  </p>
  <p>
    We agree to provide you with the Services described in this Agreement that you have decided to accept.  Our
    agreement with the sponsor of your plan (“Plan Sponsor”) describes the respective duties and obligations of
    Morningstar and CREATIVE in further detail.  CREATIVE is responsible for creating the plan level portfolio(s) that Morningstar
    will be responsible for assigning your account to and periodically rebalancing the investments in your account. You,
    Morningstar, and CREATIVE each agree and acknowledge that Morningstar and CREATIVE are individually responsible for
    performance of their respective obligations, and that a breach of duty or non-performance of an obligation by
    Morningstar or CREATIVE shall not cause the non-breaching party to be in breach of its obligations under this Agreement.
    You understand that your Plan Sponsor or other service provider is responsible for selecting the universe of
    investment options that are available under your employer-sponsored retirement plan or other retirement account
    (“Account”), and that your Plan Sponsor or service provider may change those options over time and that these
    investment options may include those that are affiliated with your service provider.
  </p>
  <p>
    We do not guarantee that the Services will be delivered to you without interruption, timely, error-free, or secure.
    Errors may occur in software-based Services as a result of programming errors, database errors, or other causes.
    We will provide the Services with that degree of prudence, diligence, care, and skill which a prudent person
    rendering similar services as an investment adviser would exercise under similar circumstances. The provisions of
    this Agreement shall not be interpreted to imply any other obligation on our part to observe any other standard of
    care. In the event an error occurs in our software-based Services, we reserve the right to correct such error in a
    manner that we deem prudent, subject to any applicable federal and state securities laws or ERISA.
  </p>
  <p>
    The Services offered by us are for your personal use only, and are not to be used for any commercial or business
    purposes. You agree that we may assume that all information provided to us by you, your Plan Sponsor, recordkeeper,
    and/or service provider in connection with the Services is true and accurate.
  </p>
  <p>
    The Services are not designed for an account that will be used by you for non-retirement purposes.  The Services
    estimate your federal, state income, and capital gains taxes based on marginal tax rate calculations
    (the marginal tax rate is the rate you pay on the taxable income that falls into the highest bracket you reach).
    These calculations are used when the Services conduct the income simulations. Tax data is updated annually based on
    United States Internal Revenue Code (IRC) and similar state tax data. The Services use income data for you, as well
    as your spouse/partner (if provided), to estimate federal and state tax exposure. Your tax exposure is appropriately
    reduced for pre-tax deferrals, tax-deferred capital gains, and yield and distribution of Roth proceeds. Based on
    the information we know about you, the Services provide an estimate of your tax exposure, but may not include all
    tax considerations. Please consult a tax adviser for a complete understanding of your tax situation. CREATIVE and
    Morningstar have no liability for tax exposure resulting from use of the Services.
  </p>
  <p>
    We cannot and do not make any guarantee about the future performance or profitability of your Account, nor do we
    promise that our investment allocation under the Services will be profitable or that you will meet your retirement
    income goals. The investment allocations under the Services may be subject to a variety of risks, including market,
    currency, and political risks.  Please note that past performance of a mutual fund, stock, or other investment
    vehicle does not guarantee its future performance.
  </p>
  <p>
    You agree to use the Services in accordance with this Agreement.  You are responsible for reviewing your Account
    periodically to monitor changes in your Account, including changes in the value of the investments in your Account.
    You also consent to the transmission of your personal information between us and your service provider or
    recordkeeper, and you acknowledge that you have received our privacy policy.
  </p>
  <p>
    The Services are based on information you provide about your current financial situation, personal status, as well
    as general market and financial conditions existing on the date you use the Services.  You agree to provide
    complete and accurate information to the extent that the Services ask for such information.  You also agree to
    update that information when your personal or financial circumstances change.  While the Services take into
    consideration all assets that you choose to input, the Services are not designed to provide recommendations on
    how to structure your overall retirement holdings (i.e., your assets both inside and outside of your Account).
    You should consider your other assets, income, and investments in addition to your Account.  As stated below,
    the Services only relate to the holdings within your Account, and those Services are limited by the investment
    choices available within your Account.  You should consider consulting a professional financial adviser to discuss
    how other investment options outside of your Account might be combined with the Services to best meet your overall
    retirement goals.
  </p>
</div>

<h1 class="feature-header">Managed Account Service</h1>
<div class="paragraph-small">
  <p>
    The Managed Account Service is a discretionary asset management program designed for participants of a defined
    contribution or deferred compensation retirement plan, or owners of other types of retirement accounts, that are
    seeking a financial professional to manage the assets within their Account. If you elect this Service by accepting
    this Agreement, we will act as your investment adviser with respect to the specific services undertaken by each of us
    as set forth above, and you grant us authority as attorney-in-fact to act on your behalf and give us full
    decision-making authority over the investments in your Account without having to consult you in advance. We will have
    no responsibility or authority over (i) those assets that are subject to Plan Sponsor restrictions, (ii) those assets
    held in a self-directed brokerage window (if available under your plan), (iii) employer company stock held in your
    Account, and (iv) any assets held outside of your Account. If you participate in the FinanceGPS Managed Accounts
    Service, Morningstar and CREATIVE each acknowledge that it is an “investment manager” (as that term is defined in ERISA
    Section 3(38)) for your Account and a fiduciary of the Plan to the extent it has decision-making authority over the
    investments in your Account. You also understand that we <strong>will not</strong> vote proxies for the investment options in
    which you will be invested.
  </p>
  <p>
    In the FinanceGPS Managed Accounts Service, Morningstar will typically review your Account on a quarterly basis and
    rebalance if necessary. However, please note that your plan recordkeeper or service provider may not be able to
    process rebalancing transactions if any investment option in your Account has any restriction
    (e.g., equity wash restriction) at the time the rebalancing transaction instruction is received by the plan
    recordkeeper or service provider. In addition, rebalancing transaction instructions may be rejected if any data
    validation error exists on your Account. In these instances, your Account may not be rebalanced until the next
    quarterly review period when all restrictions have been lifted and/or data validation errors have been corrected.
  </p>
  <p>
    You can request that investment restriction(s) be placed on your Account at any time, where “restriction” means the
    exclusion from your Account of one or more specific investments.  If you seek to impose a restriction on your
    Account, you may communicate the request to CREATIVE at  (844) 569-5442. CREATIVE will evaluate your request to determine
    whether it is reasonable, as determined by CREATIVE.  If your request is deemed inconsistent with the purpose of the
    Services or the investment objective of your Account, you can modify your request or discontinue using the Services.
  </p>
  <p>
    You agree to pay a fee to Morningstar and CREATIVE for the Services provided under the Managed Account Service
    (the “Managed Account Fee”). Your Plan Sponsor has agreed to pay the plan recordkeeper or service provider an
    administrative fee in addition to the Managed Account Fee, a pro rata portion of which is charged to your account.
    The total Managed Account Fee and administrative fee is {{planFeeText}}. For specific fee information, including the calculation and the schedule of fee deductions from your
    Account, please see your Plan Sponsor, recordkeeper or service provider or contact a Representative at the telephone number listed
    in the “Contact Us” link on Morningstar’s web site.  You may also contact Morningstar by writing Morningstar
    Investment Management LLC, 22 W. Washington Street, Chicago, IL 60602 Attn: Morningstar Workplace Solutions.
    The managed account fee is calculated based on your total current plan balance minus any amount in company
    stock, a brokerage window, or any outstanding loan balance. You authorize your service provider or
    recordkeeper to deduct the Managed Account Fee from your Account at the end of each calendar quarter
    in arrears and remit the Managed Account Fee to Morningstar and CREATIVE. A prorated Managed Account Fee will be
    calculated for any individual not in the program a full calendar quarter and shall be determined by the
    date of entry into or exit from the program. In the event that this Agreement is terminated, either by you,
    Morningstar, or CREATIVE, the quarterly installment of the Managed Account Fee that you will be charged will be
    based on the number of days in the final calendar quarter in which you receive the Managed Account Service.
  </p>
  <p>
    The Managed Account Fee does not include any redemption fees, charges or expenses imposed by any investment options
    (e.g., mutual funds) held within your Account. These investment options may be subject to separate investment
    advisory, administration, transfer agency, distribution, shareholder service and other expenses that are paid by
    you, indirectly, as a shareholder/unit holder. <strong>If the portfolios are derived from investment options otherwise
    available in the plan core line up, you may invest in those investment options without participating in the
    FinanceGPS Managed Accounts Service (and paying us the Managed Account Fee), however, if you do so, you will not
    receive the Services contemplated by this Agreement.</strong> The Managed Account Fee paid may be different than the fee
    charged to other clients of comparable size or with similar investment objectives. The payment arrangements depend
    on the agreements between your Plan Sponsor, your recordkeeper or service provider, Morningstar, and CREATIVE. Your
    recordkeeper or service provider may also charge you or your Plan Sponsor a fee to cover the administrative and
    other recordkeeping costs associated with the Managed Account Service.
  </p>
  <p>
    *Please note the FinanceGPS Managed Accounts name was chosen by CREATIVE.  In Morningstar’s Relationship Summary, Firm
    Brochure and other documents, FinanceGPS Managed Accounts is called “Managed Accounts.”  Although a different name
    is used, references to Managed Accounts apply to the services you receive under this Agreement.
  </p>
</div>

<h1 class="feature-header">Company Stock</h1>
<div class="paragraph-small">
  <p>
    If your Account includes securities issued by your employer (“Company Stock”) your employer may choose to have
    Morningstar retain and not sell any Company Stock in your Account. Otherwise, your employer may elect to allow you
    to choose to sell or retain the Non-Restricted Company Stock. Morningstar’s methodology provides that 25% of the
    Non-Restricted Company Stock in your Account will be sold each time your Account is reviewed by us. If you are
    enrolled in the FinanceGPS Managed Accounts Service, and you choose to sell your Non-Restricted Company Stock,
    you hereby direct Morningstar to send a transaction to sell 25% of your Non-Restricted Company Stock upon you
    completing a web session or upon the quarterly review of your Account, or 100% of your Non-Restricted Company Stock
    when the Non-Restricted Company Stock balance reaches $3,000 or 3% of your Account balance. Morningstar will send a
    transaction to sell 100% immediately if instructed to do so by you. The sale of Non-Restricted Company Stock will
    also include the sale of any new Non-Restricted Company Stock that is allocated automatically to your Account. If
    you choose to retain Non-Restricted Company Stock in your Account, you may elect to do so through the Managed
    Accounts web site.
  </p>
  <p>
    Except as expressly set forth in this Agreement, Morningstar and CREATIVE shall have no responsibility with respect to
    any Company Stock.
  </p>
</div>

<h1 class="feature-header">Annual Spending Amount</h1>
<div class="paragraph-small">
  <p>
    If you have begun to take withdrawals from your Account, as part of our proposed strategy in FinanceGPS Managed
    Accounts Service, Morningstar will display an Annual Spending Amount. The Annual Spending Amount does not represent
    a guarantee that you will receive a specific annual amount. Rather, Morningstar will use your available information
    to come up with a projection that assists you in evaluating the amount of money derived from various sources of
    income that will fund your retirement. You should revisit our Services at least annually to review the Annual
    Spending Amount as changes to your financial situation may occur throughout the year.  CREATIVE shall have no
    responsibility with respect to the Annual Spending Amount.
  </p>
  <p>
    Below are some important questions and answers regarding the investment options available in your plan:
  </p>
</div>
<h2 class="subfeature-subheader">Who selected the investment options available in my plan?</h2>
<div class="paragraph-small">
  <p>
    Your Plan Sponsor with CREATIVE’s assistance, or CREATIVE as the plan’s investment advisor is responsible for determining
    what investment options are made available to you in your plan.
  </p>
  <p>
    In most cases, we have no involvement in the selection of the investment options available to you. However, there
    may be instances in which a Plan Sponsor uses CREATIVE to assist it in the selection of the investment options available
    under the plan. This assistance is done separately and is not part of the Services.
  </p>
</div>

<h2 class="subfeature-subheader">What are the past performances and historical rates of return of the investment
  options available in my plan?</h2>
<div class="paragraph-small">
  <p>
    For information about the past performance and other pertinent information regarding the investment options
    available in your plan, please click on the Investment Research link within the Managed Accounts web site.
  </p>
</div>

<h2 class="subfeature-subheader">Does Morningstar or its affiliates have any material affiliation or contractual
  relationship with the investment options available in my plan?</h2>
<div class="paragraph-small">
  <p>
    In most cases, Morningstar does not have a contractual relationship with any of the investment options available in
    your plan. However, in some cases Morningstar or its affiliates provide advisory services to funds that may be
    available as an investment option in your plan. To mitigate the conflict of interest from this relationship,
    Morningstar will not include recommendations into these investment options through the Services.
  </p>
  <p>
    Additionally, Morningstar may have a contractual relationship with and may receive compensation from your plan’s
    service provider for making the Services available to your plan and to the individuals that use the Services.
    Additionally, one or more of the investment options available in your plan may be affiliated with your plan’s
    service provider. To mitigate a conflict of interest from this relationship, the fund recommendations are based on
    an objective methodology, and our compensation does not vary based on the funds that are recommended under the
    Services.
  </p>
  <p>
    In addition, Morningstar’s parent company, Morningstar, Inc., offers numerous products and services to the
    financial community. Therefore, there may be instances in which an investment options’ investment adviser uses
    Morningstar, Inc. products and services. A conflict of interest resulting from this kind of situation is mitigated
    by the fact that recommendations provided by us are derived from a quantitative process which in no way is
    influenced by the products and services provided by Morningstar, Inc.
  </p>
</div>

<h2 class="subfeature-subheader">Does CREATIVE or its affiliates have any material affiliation or contractual relationship
  with the investment options available in my plan?</h2>
<div class="paragraph-small">
  <p>
    In most cases, CREATIVE does not have a contractual relationship with any of the investment options available in your
    plan. In some cases, CREATIVE may have an arrangement that results in better pricing for plans and its participants
    based on the volume of assets invested.
  </p>
  <p>
    Additionally, one or more of the investment options available in your plan may be affiliated with your plan’s
    service provider. To mitigate a conflict of interest from this relationship, the fund recommendations are based on
    an objective methodology, and our compensation does not vary based on the funds that are included in the portfolios
    or recommended under the Services.
  </p>
</div>

<h1 class="feature-header">Miscellaneous</h1>
<div class="paragraph-small">
  <p>
    We reserve the right, in our complete and sole discretion, to alter, modify, add, update or remove portions of this
    Agreement at any time. Please review this Agreement periodically for changes to its terms. Using the Services after
    we post changes constitutes your acceptance of any changed terms. We expressly reserve the right to monitor any and
    all use of the Services.
  </p>
  <p>
    All trademarks, service marks, trade names and other intellectual property displayed in connection with the Services
    are the property of Morningstar and CREATIVE. You acknowledge that United States copyright law and other laws governing
    intellectual property protect the Services and the information contained in the Services. You also agree and
    acknowledge that the Services contain proprietary data and information of Morningstar and CREATIVE, and you agree that
    you will not use such data or information for any unlawful purpose, or any commercial or business purpose.
  </p>
  <p>
    If there is a dispute between you and us about the Services that cannot be resolved, we each agree that the dispute
    will be resolved through binding arbitration to be conducted pursuant to the rules established by the
    American Arbitration Association. A panel of three arbitrators will be selected. Each party shall select one
    arbitrator, and the two arbitrators so selected shall then select the third. Each party shall bear their own
    expenses, including attorney’s fees, and the parties shall share the cost of the arbitration equally. <strong>By
    agreeing to arbitration, you are giving up the right to have your claim heard in a court of law, however, either
    party may bring an action in court to compel arbitration under this Agreement and to enforce an arbitration award.
    The arbitrators’ decision may not include factual findings or legal analysis. The rules of procedure for
    arbitration differ from the rules of court. Also, the right to appeal the decision of the arbitration panel is
    limited. Arbitration shall be final and binding upon the parties.</strong>
  </p>
  <p>
    We may terminate this Agreement and your access to the FinanceGPS Managed Accounts web site and Services
    immediately if we determine that you have breached this Agreement. We may terminate this Agreement and your access
    to the FinanceGPS Managed Accounts web site and the Services immediately if we do not receive timely payment for
    the Services. We may also terminate this Agreement and your access to the FinanceGPS Managed Accounts web site and
    the Services if the agreement between us and your service provider, recordkeeper or Plan Sponsor is terminated, or
    the arrangement between Morningstar and CREATIVE is terminated. You have the right to terminate this Agreement without
    penalty at any time.
  </p>
  <p>
    Termination of this Agreement will not affect the provisions of this Agreement relating to arbitration of disputes,
    the validity of any action taken prior to termination, or liabilities for actions taken prior to termination.
  </p>
  <p>
    Except as otherwise provided by law, we will not be responsible for (i) any loss or damages arising from any advice
    or recommendation made or any other action taken or omitted to be taken in good faith or (ii) any loss resulting
    from our use of inaccurate, outdated or incomplete information furnished by you or through your Plan Sponsor,
    service provider or recordkeeper. Morningstar is not responsible for any nonperformance by CREATIVE and CREATIVE is not
    responsible for any nonperformance by Morningstar.  Federal and state securities laws and the Employee Retirement
    Income Security Act of 1974, as amended (“ERISA”), to the extent applicable, impose liabilities in certain
    circumstances on persons who act in good faith, and nothing in this Agreement waives or limits any rights you
    may have under those laws. We will not be responsible for any loss caused directly or indirectly by government
    restrictions, exchange or market rulings, suspension of trading (including suspension of redemption rights in your
    investment option), war, natural disasters, or other conditions beyond our control, including extreme market
    volatility.
  </p>
  <p>
    If any provision of these terms is deemed unlawful, void, or for any reason unenforceable, then that provision will
    be deemed severable from these terms and will not affect the validity and enforceability of the remaining provisions.
  </p>
  <p>
    The laws of the State of Illinois will govern this Agreement and its enforcement, except to the extent federal law
    preempts Illinois law. Nothing herein will be construed in any manner inconsistent with the Advisers Act, ERISA
    (if applicable), or any rule or order of the SEC.
  </p>
</div>
`;
