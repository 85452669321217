<template>
  <div class="special-offer">
    <h4 v-html="feeText()" class="text" />
  </div>
</template>

<script>
import store from '@/simpleStore';
import { toUSDate } from '@/shared/utils/date';

export default {
  name: 'SpecialOffer',
  data() {
    return {
      feePercent: '',
      feeDollarAmount: '',
      expireDate: ''
    };
  },
  mounted() {
    const {
      feePercent,
      feeDollarAmount
    } = store.getFeesPercentDollarAmountTotalAccountBalance();
    const { offerExpirationDate } = store.getEligibleInformation();

    this.feePercent = feePercent;
    this.feeDollarAmount = feeDollarAmount;
    this.expireDate = this.getOfferDate(offerExpirationDate);
  },
  methods: {
    feeText() {
      let expirationText = '';
      const feeText = this.$contentTransposer('advice.fee.feeText', {
        percentage: this.feePercent,
        amount: Math.round(this.feeDollarAmount),
        date: this.expireDate
      });

      if (this.expireDate) {
        expirationText = this.$contentTransposer('advice.fee.expirationText', {
          date: this.expireDate
        });
      }

      return `${feeText} ${expirationText}`;
    },
    getOfferDate(offerExpirationDate) {
      if (!offerExpirationDate) {
        return '';
      }

      return toUSDate(new Date(offerExpirationDate), {
        month: 'long',
        day: 'numeric'
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/client/default/special-offer.scss';
@import '@/assets/css/client/ingg/special-offer.scss';
</style>
