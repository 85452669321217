import advisoryAgreement from './advisory-agreement';
import privacyPolicy from './privacy-policy';
import methodology from './methodology';
import easyEnrollMethodology from './easy-enroll-methodology';
import {
  ADVISORY_AGREEMENT,
  PRIVACY_POLICY,
  METHODOLOGY
} from '../../../constants';

const deafultCombinedMethodlogy = `${easyEnrollMethodology}${methodology}`;

export default {
  [ADVISORY_AGREEMENT]: advisoryAgreement,
  [PRIVACY_POLICY]: privacyPolicy,
  [METHODOLOGY]: deafultCombinedMethodlogy
};
