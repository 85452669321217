export default {
  footer: {
    footerLinks: {
      advisoryAgreement: {
        title: 'CBIZ Investment Advisory Services, LLC. Advisory Agreement',
        url:
          'https://work-rm-compliance-docs.s3.amazonaws.com/Advisory+Agreement_CBIZ.pdf'
      },
      privacyPolicy: {
        url: '/Merged_docs/privacy_policy/CBIZ_DMP_privacy_policy_merge.pdf'
      },
      firmBrochure: {
        url: '/Merged_docs/firm_brochure/CBIZ_DMP_firm_brochure_merge.pdf'
      },
      relationshipSummary: {
        url:
          '/Merged_docs/relationship_summary/CBIZ_DMP_relationship_summary_merge.pdf'
      },
      conversationStarters: {
        url:
          '/Merged_docs/conversation_starters/CBIZ_DMP_conversation_starters_merge.pdf'
      }
    }
  },
  advisorFirmInfo: {
    advisorFirmName: 'CBIZ Investment Advisory Services, LLC'
  }
};
