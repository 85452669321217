export default {
  footer: {
    footerLinks: {
      advisoryAgreement: {
        title:
          'Mesirow Financial Investment Management, Inc. Advisory Agreement'
      },
      privacyPolicy: {
        url: '/Merged_docs/privacy_policy/MESIROW_DMP_privacy_policy_merge.pdf'
      },
      firmBrochure: {
        url: '/Merged_docs/firm_brochure/MESIROW_DMP_firm_brochure_merge.pdf'
      },
      relationshipSummary: {
        url:
          '/Merged_docs/relationship_summary/MESIROW_DMP_relationship_summary_merge.pdf'
      },
      conversationStarters: {
        url:
          '/Merged_docs/conversation_starters/MESIROW_DMP_conversation_starters_merge.pdf'
      }
    }
  },
  advisorFirmInfo: {
    advisorFirmName: 'Mesirow Financial Investment Management, Inc.'
  }
};
