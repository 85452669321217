<template>
  <EligibilityForm />
</template>

<script>
import EligibilityForm from './EligibilityForm';

export default {
  name: 'ManagedAccounts',
  components: {
    EligibilityForm
  }
};
</script>
