<template>
  <div class="status-card-container">
    <MdsLayoutGrid>
      <MdsRow align-horizontal="center">
        <MdsCol :cols="12">
          <slot />
        </MdsCol>
      </MdsRow>
    </MdsLayoutGrid>
  </div>
</template>

<script>
import { MdsLayoutGrid, MdsRow, MdsCol } from '@mds/layout-grid';

export default {
  components: {
    MdsLayoutGrid,
    MdsRow,
    MdsCol
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/client/default/status-card-container.scss';
</style>
