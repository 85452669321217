<template>
  <div class="accounts">
    <p
      v-if="totalAccounts > 1"
      v-html="totalAccountsLabel"
      class="total-accounts"
    />
    <Carousel
      :navigationEnabled="true"
      :perPage="1"
      :paginationEnabled="false"
      :mouseDrag="false"
      @pageChange="onCarouselChange"
      navigation-next-label="<span class='nav-button'>Next &gt;</span>"
      navigation-prev-label="<span class='nav-button'>&lt; Previous</span>"
    >
      <Slide :key="idx" v-for="(acc, idx) in accounts">
        <div class="slide">
          <h3 class="account">
            <strong>{{ acc.planInfo.name }}</strong>
          </h3>
          <h6 class="plan-info">
            {{
              $contentTransposer('advice.planInfo.labelAndBalance', {
                planInfolabel: acc.planInfo.label,
                planInfobalance: acc.planInfo.balance
              })
            }}
          </h6>
        </div>
      </Slide>
    </Carousel>
  </div>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel';

export default {
  name: 'Accounts',
  components: {
    Carousel,
    Slide
  },
  props: {
    accounts: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      currentIndex: 0
    };
  },
  computed: {
    totalAccounts() {
      return this.accounts.length;
    },
    totalAccountsLabel() {
      const fixedIndex = this.currentIndex + 1;

      return `<strong>${fixedIndex}</strong> of ${this.totalAccounts}`;
    }
  },
  methods: {
    onCarouselChange(val) {
      this.currentIndex = val;

      this.$emit('on-carousel-change', val);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/client/default/accounts.scss';
</style>
