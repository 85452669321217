<template>
  <div class="header">
    <SVGImage
      :path="getLogoInfo.path"
      :name="getLogoInfo.name"
      :isLogo="true"
    />
  </div>
</template>

<script>
import store from '@/simpleStore';

export default {
  name: 'Header',
  computed: {
    getLogoInfo() {
      let logoName = 'rm-logo';
      if (store.getCustomizationId()) {
        logoName = `RM_${store.getCustomizationId()}`;
      }
      if (store.isAMA()) {
        logoName = store.getAdvisoryFirmId();
      }
      if (store.getVersion()) {
        logoName = `${store.getCustomizationId()}_${store.getVersion()}`;
      }
      return { path: `logos/${logoName}`, name: `logo-${logoName}` };
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/client/default/header.scss';
</style>
