<template>
  <EeContentWrapper class="error-page" has-special-offer>
    <MdsLayoutGrid class="error-page-wrapper">
      <MdsRow>
        <MdsCol class="warning">
          <Mds-alert
            :title="alertTitle"
            :text="alertText"
            variation="error"
            tinted
          >
          </Mds-alert>
        </MdsCol>
      </MdsRow>
      <MdsRow class="heading">
        <MdsCol class="centered">
          <h1
            class="heading--title"
            data-ee-test="errorPage-confirmation-heading"
          >
            {{ $contentTransposer('errorPage.header') }}
          </h1>
        </MdsCol>
      </MdsRow>
      <MdsRow>
        <MdsCol class="centered">
          <h5 class="message">
            {{ $contentTransposer('errorPage.messageText') }}
          </h5>
        </MdsCol>
      </MdsRow>
      <MdsRow class="centered">
        <MdsButton
          @click="getContent(CONTACT_US)"
          :data-ee-test="`error-page-contact-button`"
          variation="primary"
          size="large"
          class="button"
        >
          {{ $contentTransposer('errorPage.contactButton') }}
        </MdsButton>
      </MdsRow>
    </MdsLayoutGrid>
    <about-morningstar />
    <ClientContentModal
      v-if="showModal"
      @footer-modal-dismissed="showModal = $event"
      :model="showModal"
      :content="modalData"
    />
  </EeContentWrapper>
</template>

<script>
import EeContentWrapper from '../layout/ee-content-wrapper';
import { MdsLayoutGrid, MdsRow, MdsCol } from '@mds/layout-grid';
import MdsAlert from '@mds/alert';
import AboutMorningstar from '../AboutMorningstar';
import store from '@/simpleStore';
import footerModalData from '../mixins/footer-modal-data';
import newRelicTracking from '@/shared/utils/newRelicTracking';
import { mapActions } from 'vuex';
import { MdsButton } from '@mds/button';
import ClientContentModal from '../layout/footer-modals/ClientContentModal';
import { CONTACT_US } from '../../constants';

export default {
  components: {
    EeContentWrapper,
    AboutMorningstar,
    MdsLayoutGrid,
    MdsRow,
    MdsCol,
    MdsAlert,
    MdsButton,
    ClientContentModal
  },
  mixins: [footerModalData],
  data() {
    return {
      pageContentParams: {
        isAMA: store.isAMA(),
        advisoryFirmId: null,
        clientId: null
      },
      warningMsg: '',
      CONTACT_US
    };
  },
  created() {
    localStorage.setItem('showDefaultLandingLinks', false);
    this.setHeroTitle();
    this.alertTitle = this.$contentTransposer('errorPage.alertTitle');
    this.alertText = this.$contentTransposer('errorPage.alertText');
  },
  mounted() {
    newRelicTracking();
  },
  methods: {
    ...mapActions({
      setHeroText: 'setHeroText'
    }),
    setHeroTitle() {
      const heroText = this.$contentTransposer('hero.title');
      this.setHeroText(heroText);
    }
  }
};
</script>
<style lang="scss" scoped>
@import '@/assets/css/client/default/error-page.scss';
</style>
