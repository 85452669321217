export default `
<p class="paragraph-small-custom margin-3-x-top">
    <strong>Important Note</strong>:  This website uses a streamlined process to enroll you in 
    the Managed Accounts service and may not consider all information relevant to your financial 
    situation.  You can access our full enrollment process by logging into Morningstar® Retirement 
    Manager<sup>SM</sup> through your retirement plan provider’s website.  The full enrollment process allows 
    you to provide us with additional information about your retirement situation and goals so that we 
    can further customize your retirement strategy.  If you have additional assets outside your employer’s 
    retirement plan, have a spouse or partner you’d like us to consider, want to restrict certain securities 
    from being used in your retirement account, or want to change the suggestions we’ve made for you here 
    or see how changes would impact your retirement strategy, we encourage you to use our full enrollment 
    process instead of the streamlined process. 
</p>
<p class="paragraph-small-custom">
    The level of strategy customization you receive depends on the amount of information you 
    share with us. The streamlined process takes into account your age, retirement plan type, and 
    the balance, fund allocation, and contributions for your retirement plan account as provided 
    by your retirement plan’s plan provider. We assume a retirement age of 65. 
</p>
<div class="custom-methodology">
    <h2 class="section-header4 margin-top-none nopadding-top title">Your Strategy Using This Site</h2>
    <h4 class="paragraph-margin-12">Principles</h4>
    <p class="paragraph-small-custom paragraph-margin-12 intropara1">
        Managed accounts is a retirement planning service offered through your employer's 
        retirement plan to help you make more informed decisions about investing your 
        retirement account. The fundamental principles of Managed Accounts are:
    </p>
        <ul class="list margin-top-full">
            <li>
                <p class="paragraph-small2 paragraph-margin-0 personalizedheader"><strong>Personalized:</strong></p>
                <p class="paragraph-small2 paragraph-margin-12 personalizedpara1">
                    The primary objective of Managed Accounts is to help you reach your desired retirement income goal 
                    by furnishing you with a personalized strategy on asset allocation, investments, savings, and 
                    retirement age. Your strategy is tailored to your specific circumstances, including financial 
                    situation, future retirement goals, and risk capacity (the amount of risk you may want to take 
                    to help reach your goals).
                </p>
            </li>
            <li><p class="paragraph-small2 paragraph-margin-0 goalsbasedheader"><strong>Goals-Based:</strong></p>
                <p class="paragraph-small2 paragraph-margin-12 goalsbasedpara1">
                    A prudent strategy must be built in relation to specific goals, and Managed 
                    Accounts helps you define those goals and develop a strategy aimed at reaching them.
                </p>
            </li>
            <li><p class="paragraph-small2 paragraph-margin-0 forwardlookingheader"><strong>Forward-Looking:</strong></p>
                <p class="paragraph-small2 paragraph-margin-12 forwardlookingpara1">
                    Rather than relying only on historical data (which may or may not have any 
                    relevance to future conditions), Managed Accounts incorporates forward-looking 
                    estimates for assumptions about investment returns and performance behavior.
                </p>
            </li>
        </ul>

    <h4 class="feature-header4 rmageheader">Retirement Age</h4>
    <p class="paragraph-small-custom rmagepara1">
        Managed Accounts assumes a default retirement age of your "Full Retirement Age," as 
        defined by the Social Security Administration. Your Full Retirement Age 
        depends on your birthday or your current age plus one year if you are older than your Full Retirement Age. 
    </p>
    <p class="paragraph-small-custom rmagepara2">
        Managed Accounts defines retirement age as the age at which you will begin withdrawing 
        money from your primary Employer-sponsored retirement plan or retirement account. 
    </p>
    <p class="paragraph-small-custom rmagepara3">
        One effective way to improve your chances of meeting your retirement goal is to 
        delay your retirement. That’s because delaying retirement allows your assets to stay invested 
        longer and it enables you to continue contributing to your account. As part of your strategy, Managed 
        Accounts may recommend that you delay your retirement by two or more years to help you meet your retirement goal.
    </p>

    <h4 class="feature-header4 salarygrowthheader">Salary Growth</h4>
    <p class="paragraph-small-custom salarygrowthpara1">
        To estimate your future salary, Managed Accounts uses a "salary growth curve" based on academic 
        research rather than assuming a single, fixed growth rate (for example, 5% per year). This curve 
        takes into account the fact that salaries tend to grow most rapidly for young employees, peak 
        around age 51 and then slightly decline later in life.
    </p>

    <h4 class="feature-header4 esttaxheader">Estimated Tax</h4>
    <p class="paragraph-small-custom esttaxpara1">
        Managed Accounts estimates your federal, state income, and capital gains taxes based on marginal 
        tax rate calculations (the marginal tax rate is the rate you pay on the taxable income that falls 
        into the highest bracket you reach).These calculations are used when conducting income simulations. Tax 
        data is updated annually based on United States Internal Revenue Code (IRC) and similar state tax data. Managed 
        Accounts uses income data for you, as well as your spouse/partner, to estimate federal and state 
        tax exposure. Your tax exposure is appropriately reduced for pre-tax deferrals, tax-deferred 
        capital gains, and yield and distribution of Roth proceeds. Based on the information  
        you provide, you’ll receive an estimate of your tax exposure, but it may not include all tax 
        considerations. Please consult a tax adviser for a complete understanding of your tax situation.
    </p>

    <h4 class="feature-header4 inflationasmheader">Inflation Assumptions</h4>
    <p class="paragraph-small-custom inflationasmpara1">
        When projecting the growth of your various income sources (such as pension, Social Security, outside 
        account balances, etc.), and expenses, Managed Accounts uses a variety of different inflation 
        rates. These rates are set by our investment research team. Different inflation rates are used 
        for different projections. For instance, Managed Accounts uses a long-term inflation rate 
        to help calculate retirement need and cash flows, and a simulated inflation rate for Social 
        Security calculations, pensions, and cost of living adjustments.
    </p>

    <h4 class="feature-header4 irslapheader">IRS Limitations and Application of Penalties</h4>
    <p class="paragraph-small-custom irslappara1">
        The IRS establishes rules for tax-deferred, tax-free, and taxable investment 
        accounts. These rules define the maximum contribution rates and amounts, eligibility 
        for contributing to an account type, and penalties for withdrawal. Managed Accounts 
        incorporates these IRS contribution limits, eligibility requirements, and withdrawal 
        penalties into its retirement strategies.
    </p>

    <h4 class="feature-header4 savingstrategyheader">Savings Strategy</h4>
    <p class="paragraph-small-custom savingstrategynote">
        The strategy provided by Managed Accounts exemplify the belief that increasing 
        the amount you save in your retirement account is one of the most effective ways to 
        improve the probability that you will reach your retirement goal; therefore, whenever 
        possible, your proposed strategy will include a recommendation for an optimal savings 
        allocation. Managed Accounts may recommend an increase in your savings rate to help you 
        get closer to your retirement goal.
    </p>

    <h4 class="feature-header4 riskstrategyheader">Risk Strategy</h4>
    <p class="paragraph-small-custom riskstrategypara1">
        Based on the information you have provided, Managed Accounts assigns you to one of 
        19 possible asset mixes (the combination of stocks, bonds, and cash) ranging from 97% stock to 10% stock.
    </p>
    <p class="paragraph-small-custom riskstrategypara2">
        To determine your risk capacity, Managed Accounts uses a concept called human capital. This 
        helps determine an appropriate target risk level for your retirement portfolio by considering 
        your risk exposure in all your other accounts that you've added to the site. The human capital 
        methodology accounts for an investor’s financial capital (total saved assets and tradable assets 
        such as stocks and bonds) as well as their human capital (future earnings and savings potential). Using 
        this methodology, Managed Account scan assign a target risk level that is based on your 
        total economic worth rather than just on a subjective assessment, such as a risk tolerance questionnaire.
    </p> 
    <p class="paragraph-small-custom riskstrategypara3">
        In general, human capital is a large percentage of total wealth for younger investors, which 
        means attaining the overall market portfolio allocation (the optimal portfolio for every investor 
        based upon each asset’s current market value) requires younger investors to allocate their financial 
        portfolio more heavily in equities. As the investor ages, the human capital portion of total wealth 
        declines, which means that older investors should consider investing their financial portfolios 
        more heavily in fixed-income investments, resulting in a more conservative risk capacity.
    </p>

    <h4 class="feature-header4 investstrategyheader">Investment Strategy</h4>
    <p class="paragraph-small-custom investstrategypara1">
        Managed Accounts will build a portfolio of investments designed to meet your assigned asset 
        mix while using funds available in your plan to do so.
    </p>
    <p class="paragraph-small-custom investstrategypara2">
        Fund-specific portfolios will be built that are focused on both accumulation and decumulation. This 
        allows for different asset class exposure among equity and fixed income allocations at different 
        points in your lifetime. The portfolios will range from 97% equity at the most aggressive to 10% 
        equity at the most conservative. You can omit certain funds from being used in your portfolio by 
        logging into Morningstar Retirement Manager through your plan provider’s website.
    </p>
    <p class="paragraph-small-custom investstrategypara3">
        For more information on how the portfolios are created, please review the Firm Brochure located in the footer.
    </p>
    <p class="paragraph-small-custom investstrategynote4">
        The target risk level changes over time to help ensure you are still investing in an optimal 
        portfolio for your specific situation and risk capacity. In general, Managed Accounts tries to 
        provide a smooth transition from an aggressive equity portfolio to a more conservative fixed 
        portfolio as you near retirement.
    </p>

    <h4 class="feature-header4 comstockstrategyheader">Company Stock Strategy</h4>
    <p class="paragraph-small-custom comstockstrategypara1">
        Managed Accounts subscribes to the belief that holding the stock of your employer greatly increases 
        your portfolio risk, particularly in large concentrations. Prudent financial planning principles hold 
        that any significant investment in a single stock creates a non-diversified situation in your 
        portfolio with greater risk of investment losses. Therefore, you may want to sell your holdings in 
        company stock down to zero and avoid making future contributions to company stock. In some 
        cases, your employer may restrict you from selling a portion of your company stock. In other cases, your 
        employer may provide contribution matches only in the form of company stock; in this case, you should 
        continue to take the matches.
    </p>
    <p class="paragraph-small-custom comstockstrategypara2">
        By enrolling in Managed Accounts, you may direct us to gradually sell off your non-restricted 
        company stockholdings over time or immediately. If you choose to sell off your balance gradually, you 
        direct us to submit instructions on your behalf to your recordkeeper in 25% increments each time 
        your account is reviewed or after you complete a session. (If at any point during the sell-off period 
        your company stock balance reaches less than or equal to $3,000 or 3% of your account balance, the 
        remainder of the balance will be sold off at that time.)
    </p>
    <p class="paragraph-small-custom comstockstrategypara3">
        If you have elected to sell off your balance immediately, Managed Accounts will submit 
        instructions on your behalf to your recordkeeper to do so once you finalize your current session. Each 
        time your account is reviewed, or when you enter the site and complete a session, Managed 
        Accounts will check your company stock balance. If it is higher than the amount you want 
        retained, Managed Accounts will act on your direction and submit instructions to your recordkeeper 
        on your behalf to sell off the surplus immediately.
    </p>
    <p class="paragraph-small-custom comstockstrategypara4">
        Retirement income projections will take into account any future reduction in company 
        stock. If your account includes a company stock position, Managed Accounts will model it 
        using the stock’s ticker. The ticker tells us the exact investment category (large, mid or small) 
        for that specific stock. Additionally, Managed Accounts assumes that all company stock has a 
        standard deviation (a measure of a fund’s risk) of twice its category. Managed Accounts models 
        it this way because this may more accurately mirror the risk associated with holding company stock.
    </p>
    <p class="paragraph-small-custom comstockstrategypara5">
        You can choose to retain some or all of your company stock holdings in your retirement account. If 
        you choose to do so, Managed Accounts will not be responsible for that portion of your retirement 
        plan account, although Managed Accounts will take it into consideration when creating your 
        investment strategy, as described above.  If you want to retain some or all of your company stock 
        holdings, you will need to enroll in Morningstar Retirement Manager through your plan provider’s website.
    </p>

    <h4 class="feature-header4 monitoringrebalancingheader">Monitoring and Rebalancing</h4>
    <p class="paragraph-small-custom monitoringrebalancingpara1">
        If you have selected the Managed Accounts service, your account will be periodically 
        reviewed (typically quarterly), and when necessary, your investment positions will be 
        rebalanced to the asset allocation targets. Your portfolio allocations will be adjusted annually, taking 
        into account your change in age and any other significant personal or financial changes to your 
        situation that you have added to the site.
    </p>

    <h4 class="feature-header4 disclosureheader">Important Disclosure Information</h4>
    <p class="paragraph-small-custom disclosurepara1">
        © 2021 Morningstar, Inc. All rights reserved. The Morningstar name and logo are registered 
        marks of Morningstar, Inc.
    </p>
    <p class="paragraph-small-custom disclosurepara2">
        The methodologies described in this document are the proprietary material of Morningstar Investment 
        Management LLC, a registered investment adviser and subsidiary of Morningstar, Inc. Morningstar 
        Investment Management is responsible for assigning you to a specific portfolio from those 
        created by the investment adviser. Morningstar Investment Management and the unaffiliated 
        investment adviser are each individually responsible for performance of their respective duties, and 
        are not responsible for actions of the other party. Please ask your financial adviser for 
        additional information, including a copy of their Form ADV Part 2 disclosure document, about this 
        registered investment adviser and the advice they provide. (Form ADV part 2 for Morningstar 
        Investment Management and the investment adviser can also be found at 
        <a href="https://www.adviserinfo.sec.gov/IAPD/Default.aspx" target="_blank">www.adviserinfo.sec.gov/IAPD/Default.aspx</a>.) 
        Managed Accounts are intended for citizens or legal residents of the United States or its territories.
    </p>
    <p class="paragraph-small-custom disclosurepara3">
        Investment advice delivered through Managed Accounts is based on the information 
        provided about you and limited to the investment options available in your defined 
        contribution plan. Projections and other information regarding the likelihood of various 
        retirement income and/or investment outcomes are hypothetical in nature, do not reflect actual 
        results, and are not guarantees of future results. Results may vary with each use and over time. All 
        investments involve risk, including the loss of principal. There can be no assurance that any 
        financial strategy will be successful.
    </p>
    <p class="paragraph-small-custom disclosurepara4">
        Reproduction, transcription, or other use of the information in this document, by 
        any means, in whole or in part, without the prior written consent of Morningstar Investment 
        Management, is prohibited. Opinions expressed are as of the current date; such opinions 
        are subject to change without notice. Morningstar Investment Management shall not be 
        responsible for any trading decisions, damages, or other losses resulting from, or 
        related to, the information, data, analyses or opinions or their use outside of Managed 
        Accounts. Past performance does not guarantee future results. Morningstar Investment 
        Management does not guarantee that the results of their methodologies or the objectives of a 
        strategy will be achieved.
    </p>
    <p class="paragraph-small-custom disclosurepara5">
        Assumptions involve known and unknown risks, uncertainties and other factors which may cause actual 
        results to differ materially and/or substantially from any expected future result, performance 
        or achievement expressed or implied by those assumptions for any reason.
    </p>
    <p class="paragraph-small-custom disclosurepara6">
        Asset allocation and diversification are investment methodologies that do not ensure a profit or 
        protect against a loss in a declining market.
    </p>
    <p class="paragraph-small-custom disclosurepara7">
        Monte Carlo is an analytical method used to simulate random returns of uncertain variables 
        to obtain a range of possible outcomes. Such probabilistic simulation does not analyze 
        specific security holdings, but instead analyzes asset classes. Simulations generated are 
        not a guarantee or projection of future results, but rather, a tool used to identify a range 
        of potential outcomes. Monte Carlo simulations are hypothetical in nature and for illustrative 
        purposes only. Results may vary with each use and over time.
    </p>
</div>
`;
