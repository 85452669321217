import { ENV } from '../constants';

export const getENVInfo = () => {
  let envInfo = ENV.QA;
  const hostName = window.location.hostname;
  switch (true) {
    case process.env.NODE_ENV === ENV.LOCALHOST:
      envInfo = ENV.LOCALHOST;
      break;
    case hostName.indexOf('-dev.morningstar.com') > -1:
      envInfo = ENV.DEV;
      break;
    case hostName.indexOf('-qa.morningstar.com') > -1:
      envInfo = ENV.QA;
      break;
    case hostName.indexOf('-uat.morningstar.com') > -1:
      envInfo = ENV.UAT;
      break;
    case hostName.indexOf('retirementmanager.morningstar.com') > -1:
      envInfo = ENV.PROD;
      break;
    default:
      break;
  }
  return envInfo;
};
