<template>
  <div class="card-wrapper">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'CardWrapper'
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/client/default/card-wrapper.scss';
</style>
