import apiService from './api-service';
import { VERIFY_PICKED_INFO } from '@/constants';

export const calculateSavingRate = async (cusId, salary, savingRate) => {
  const { data } = await apiService.postAPIRequest(VERIFY_PICKED_INFO, {
    cusId,
    salary: salary === '' ? undefined : salary,
    savingRate: savingRate === '' ? undefined : savingRate
  });
  return data;
};
