import advisoryAgreement from './advisory-agreement';
import methodology from './methodology';
import easyEnrollAMAMethodology from '../AMA/easy-enroll-methodology';
import rmDefaultAMAMethodology from '../AMA/rm-default-methodology';
import { ADVISORY_AGREEMENT, METHODOLOGY } from '../../../constants';

const sageviewCombinedMethodology = `${methodology}${easyEnrollAMAMethodology}${rmDefaultAMAMethodology}`;

export default {
  [ADVISORY_AGREEMENT]: advisoryAgreement,
  [METHODOLOGY]: sageviewCombinedMethodology
};
