import { ADVISORY_FIRM_ID, CLIENTS } from '../src/constants';
import localStorageService from './components/service/local-storage-service';
// based on https://vuejs.org/v2/guide/state-management.html#Simple-State-Management-from-Scratch
const simpleStore = {
  debug: true,
  state: {
    eligibleRefId: '',
    eligibleLastName: '',
    eligibleDateOfBirth: '',
    sessionExpired: false,
    isAMA: false,
    queryParams: '',
    advisoryFirmId: null,
    customizationId: null,
    entryCode: null,
    version: null,
    planVersion: null,
    lastName: '',
    RefId: '',
    dateOfBirth: ''
  },

  setStateProperty(property, newValue) {
    if (this.debug) {
      console.log(`set "${property}" with -> ${newValue}`); // eslint-disable-line no-console
    }

    this.state[property] = newValue;
  },
  setEligibleInformation(eligibleInformation, verified) {
    const { ProposalRefId, LastName, DOB } = eligibleInformation;

    this.setStateProperty('eligibleRefId', ProposalRefId);
    this.setStateProperty('eligibleLastName', LastName);
    this.setStateProperty('eligibleDateOfBirth', DOB);
    if (verified) {
      localStorageService.setItem('eligibleInformation', eligibleInformation);
    }
  },

  setPlanCustomization(clientId, planInfo) {
    let isVRAPlan = null;

    if (clientId && clientId === 'INGG') {
      planInfo.forEach(plan => {
        if (plan.PlanAttributes) {
          plan.PlanAttributes.forEach(planAttribute => {
            if (
              planAttribute.AttributeName === 'MorningstarRole' &&
              planAttribute.Attribute === 'IFE'
            ) {
              isVRAPlan = 'VRA';
            }
          });
        }
      });
    }
    this.setStateProperty('planVersion', isVRAPlan);
  },

  /* ---------- Phase2 State functions ---------- */
  setDbParticipantAdvice(dbParticipantAdvice) {
    const {
      Accounts: accounts,
      CalculatedFees: calculatedFees,
      ClientId: clientId,
      EnrollmentInfo: enrollmentInfo,
      ProposalAdvice: proposalAdvice,
      ParticipantInfo: participantInfo,
      ProviderContactsInfo: providerContactsInfo,
      PlanInfo: planInfo,
      ProgramFees: programFees
    } = dbParticipantAdvice;

    this.setproposalAdvice(proposalAdvice);
    localStorageService.setItem('calculatedFees', calculatedFees);
    localStorageService.setItem('clientId', clientId);
    localStorageService.setItem('participantInfo', participantInfo);
    localStorageService.setItem('providerContactsInfo', providerContactsInfo);
    localStorageService.setItem('planInfo', planInfo);
    localStorageService.setItem('programFees', programFees);
    localStorageService.setItem('accounts', accounts);
    window.sessionStorage.setItem('participantId', programFees[0].CusId);
    window.sessionStorage.setItem(
      'enrollmentInfo',
      JSON.stringify(enrollmentInfo)
    );
    this.setPlanCustomization(clientId, planInfo);
  },
  setSessionExpired(value) {
    if (value) {
      // Clear all storages if session is expired
      localStorageService.clear();
      window.sessionStorage.clear();
    }
    this.setStateProperty('sessionExpired', value);
  },
  setQueryParams(value) {
    const { customizationId, advisoryFirmId, entryCode, version } = value;
    const isAMA =
      customizationId &&
      advisoryFirmId &&
      CLIENTS.includes(customizationId) &&
      ADVISORY_FIRM_ID.includes(advisoryFirmId);
    this.setStateProperty('isAMA', isAMA);
    this.setStateProperty('queryParams', {
      cl: customizationId,
      ra: advisoryFirmId,
      code: entryCode,
      vr: version
    });
    this.setStateProperty('customizationId', customizationId);
    this.setStateProperty('advisoryFirmId', advisoryFirmId);
    this.setStateProperty('entryCode', entryCode);
    this.setStateProperty('version', version);
  },
  setDOB(value) {
    window.sessionStorage.setItem('DOB', value);
  },
  setproposalAdvice: value => {
    window.sessionStorage.setItem('proposalAdvice', JSON.stringify(value));
  },
  getCalculatedFees: () => localStorageService.getItem('calculatedFees'),
  getFeesPercentDollarAmountTotalAccountBalance: () => {
    const calculatedFees = simpleStore.getCalculatedFees();
    if (!calculatedFees) {
      return null;
    }
    const { EstimatedTotalAnnualFee, Accounts } = calculatedFees;
    let totalAccountBalance = 0;
    Accounts.forEach(account => {
      totalAccountBalance += account.TotalAccountBalance;
    });

    const fee = Math.round(EstimatedTotalAnnualFee) || 0;
    const feeDollarAmount = fee / 12;
    return {
      totalAccountBalance,
      feeDollarAmount,
      feePercent:
        feeDollarAmount > 0 || totalAccountBalance > 0
          ? ((feeDollarAmount / totalAccountBalance) * 100).toFixed(2)
          : '0.00'
    };
  },
  getProviderContactsInfo: () =>
    localStorageService.getItem('providerContactsInfo'),
  getParticipantInfo: () => localStorageService.getItem('participantInfo'),
  getClientId: () => {
    const clientId = localStorageService.getItem('clientId') || '';
    return !simpleStore.getDefaultLandingLinks() && clientId
      ? clientId.trim()
      : null;
  },
  getEligibleInformation: () =>
    localStorageService.getItem('eligibleInformation') || {},
  getContributionChangesFlag: () =>
    simpleStore
      .getPlanInfo()
      .every(planItem => planItem.EnableContributionChanges),
  getPlanInfo: () => localStorageService.getItem('planInfo'),
  getTotalAccounts: () => simpleStore.getCalculatedFees().Accounts.length,
  hasMultipleAccounts: () =>
    simpleStore.getCalculatedFees().Accounts.length > 1,
  getProgramFees: () => localStorageService.getItem('programFees'),
  getAccounts: () => localStorageService.getItem('accounts'),
  getDefaultLandingLinks: () =>
    JSON.parse(localStorage.getItem('showDefaultLandingLinks')),
  isAMA: () => simpleStore.state.isAMA,
  getQueryParams: () => {
    const filteredQueryParams = simpleStore.state.queryParams;
    for (const param in filteredQueryParams) {
      if (!filteredQueryParams[param]) {
        delete filteredQueryParams[param];
      }
    }
    return filteredQueryParams;
  },
  getAdvisoryFirmId: () => simpleStore.state.advisoryFirmId,
  getCustomizationId: () => simpleStore.state.customizationId,
  getEntryCode: () => simpleStore.state.entryCode,
  getVersion: () => simpleStore.state.version,
  getPlanVersion: () => simpleStore.state.planVersion,
  getEnrollmentInfo: () =>
    window.sessionStorage.getItem('enrollmentInfo')
      ? JSON.parse(window.sessionStorage.getItem('enrollmentInfo'))
      : null,
  getDOB: () => window.sessionStorage.getItem('DOB'),
  getParticipantId: () => window.sessionStorage.getItem('participantId'),
  getProposalAdvice: () =>
    JSON.parse(window.sessionStorage.getItem('proposalAdvice')),
  getEligibleLastName: () => simpleStore.state.eligibleLastName,
  getEligibleRefId: () => simpleStore.state.eligibleRefId,
  getEligibleDateOfBirth: () => simpleStore.state.eligibleDateOfBirth
};

export default simpleStore;
