<template>
  <div class="hero">
    <SessionExpired v-if="sessionExpired" />
    <h1 class="heading">{{ getHeroText }}</h1>
  </div>
</template>

<script>
import SessionExpired from '@/components/SessionExpired/SessionExpired';
import store from '@/simpleStore';
import { mapGetters } from 'vuex';

export default {
  components: {
    SessionExpired
  },
  data() {
    return {
      sessionExpired: store.state.sessionExpired
    };
  },
  computed: {
    ...mapGetters({
      getHeroText: 'getHeroText'
    })
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/client/default/hero.scss';
@import '@/assets/css/client/ingg/hero.scss';
</style>
