<template>
  <MdsLayoutGrid class="advice-intro">
    <MdsRow align-horizontal="space-around" align-vertical="center">
      <MdsCol :cols="12" :cols-at-m="5" :cols-at-xl="3" class="svg">
        <SVGImage
          path="advice-intro-illustration"
          name="landing-page-illustration"
          class="svg--resize"
        />
      </MdsCol>
      <MdsCol :cols="12" :cols-at-m="7" class="content">
        <h4
          v-html="
            $contentTransposer('landing.introductionTxt', {
              productName: $contentTransposer('advisorFirmInfo.productName')
            })
          "
          data-ee-test="advice-intro-content"
          class="content--text"
        />
      </MdsCol>
    </MdsRow>
  </MdsLayoutGrid>
</template>

<script>
import { MdsLayoutGrid, MdsRow, MdsCol } from '@mds/layout-grid';

export default {
  components: {
    MdsLayoutGrid,
    MdsRow,
    MdsCol
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/client/default/advice-intro.scss';
@import '@/assets/css/client/ingg/advice-intro.scss';
</style>
