import { METHODOLOGY, CONTACT_US } from '../../../constants';

export default {
  landing: {
    introductionTxt: `Most of us were never taught how to plan, save, and invest for retirement—and, if you were, you may not have the time or interest. That's why Voya Retirement Advisors, LLC (VRA), powered by Morningstar Investment Management LLC, created easy-to-use services designed to help you get and stay on track for the retirement you want.`,
    verifyForm: {
      subHeader:
        'Before we share our recommendations for you, we need to confirm a few details.',
      privacyPolicy: {
        text: 'Read our',
        link: 'Privacy Policy',
        url: 'https://www.voya.com/privacy-notice'
      }
    },
    footerLinks: {
      // This footer links is used to show on landing page for non AMA users because it doesn't have client specific content.
      privacyPolicy: {
        name: 'Privacy Policy',
        type: 'link',
        url: 'https://www.voya.com/privacy-notice',
        show: true
      },
      disclosureStatement: {
        name: 'Disclosure Statement',
        type: 'link',
        url:
          'https://work-rm-compliance-docs.s3.amazonaws.com/VRA+ADV+2A+03+17+2023.pdf',
        show: true
      },
      advisoryServicesAgreement: {
        name: 'Advisory Services Agreement',
        type: 'link',
        url:
          'https://work-rm-compliance-docs.s3.amazonaws.com/Voya_Mstar+Advisory+Services+Agreement.pdf',
        show: true
      },
      firmBrochure: {
        show: false
      },
      relationshipSummary: {
        show: false
      },
      conversationStarters: {
        show: false
      }
    }
  },
  enroll: {
    confirmationText: `Our team is thrilled to help you get and stay on track for your retirement goals. VRA is now managing the investments in your employer plan and will keep
      an eye on things for you.`
  },
  aboutMorningStar: {
    header: 'We’re about putting investors first—all investors.',
    columns: [
      {
        imageID: 'mission',
        imagePath: 'our-mission',
        className: 'purple',
        subHeader: 'Our mission',
        description:
          'When it comes to helping people reach their financial goals, we’re all in. We’re focused on providing practical, unbiased advice so you can make better decisions about your money.'
      },
      {
        imageID: 'history',
        imagePath: 'our-history',
        className: 'violet',
        subHeader: 'Our history',
        description:
          'We’re a registered investment advisor with over 20 years of experience; committed to help make it easier for you to grow, protect, and enjoy your retirement savings. Morningstar Investment Management LLC, our sub-adviser, has been helping investors reach for their financial goals since 1984.'
      },
      {
        imageID: 'commitment',
        imagePath: 'our-commitment',
        className: 'red',
        subHeader: 'Our commitment',
        description:
          'We don’t get paid extra to recommend certain securities to you. We work for you––your bottom line is the only one that matters.'
      }
    ]
  },
  advice: {
    fee: {
      feeText: `<strong>Special offer for you today</strong>: Try out Professional Management for <strong>90 days with no
        obligation or fee</strong> and you can cancel anytime. After that, if you would like to keep this service, there 
        will be a {{percentage}}% fee.`,
      expirationText: `This offer expires on {{date}}`
    },
    enhancementTitle: 'We recommend the following changes to your strategy:',
    strategy: {
      header:
        'You can take action on these recommendations in one of two ways:',
      subHeader: '',
      contents: [
        `Use Online Advice without a fee - Just follow the Get Investment Advice link on your plan's 
        <a href="https://www.voyaretirementplans.com" target="_blank">website</a>. OR`,
        `Enroll in Professional Management by clicking the Enroll Now button below. If you enroll in Professional 
        Management during this Special Offer, you can try it for 90 days with no obligation or fee and you can cancel 
        anytime. After that if you would like to keep this service. The fee referenced in the Special Offer above will apply.`
      ]
    },
    retirementIncome: {
      proposedRetirementAgeAndIncomeText: `If you follow our recommendations below, we project you may have <strong>&dollar;{{proposedRetirementIncome}} to spend each 
        month</strong> during your retirement!`
    },
    retirementAdvice: {
      description: `Whether you feel you need a lot of help or a little when it comes to your retirement, we're here for you.<br>
        Check out our options for ongoing advice that are designed to help position you for the retirement you want:
        `,
      options: [
        `Manage the account yourself with Online Advice. There are no fees for this service since you're
        monitoring and adjusting your investments yourself. You can access this do-it-yourself service by logging into your
        <a href="https://www.voyaretirementplans.com" target="_blank">plan website</a> and then clicking the Get Investment Advice link.`,
        `Leave the work to us with our Professional Management program. We'll implement the recommendations
        below and then continuously monitor your plan, making any recommended  adjustments to your investments automatically on an
        ongoing basis. The fee for this program is detailed below in our Special Offer.`
      ]
    },
    projectionDisclosure: {
      shortText: `IMPORTANT: Forecasts, projected outcomes or other information generated regarding the likelihood of various investment 
      outcomes are hypothetical in nature, do not reflect actual investment results and are not guarantees of future results. In addition, 
      results may vary each time a forecast is generated for you.`,
      fullText: `<p class="ee__body-text--s" id="projection-disclosure">IMPORTANT: The projections or other information generated 
      by this analysis regarding the likelihood of various investment outcomes are hypothetical in nature, do not reflect actual 
      investment results and are not guarantees of future results.  Results may vary with use and over time, reflecting any changed 
      circumstances, assumptions or variables upon which the analysis is based. The analysis relates only to the current and 
      proposed asset allocation strategies shown herein, other strategies not considered may have characteristics similar or 
      superior to those being analyzed. </p>
      <p class="ee__body-text--s">
      Projections involve known and unknown risks, uncertainties, and other factors which may cause actual results to differ 
      materially and substantially from any future results or performance expressed or implied by the projection for any reason. 
      Projections in no way represent a guarantee that a particular result will be produced or achieved. This analysis uses asset 
      classes as a proxy for investments. Security implementation decisions may result in significantly different outcomes.
      </p>
      `
    }
  },
  modals: {
    incomeProjection: {
      disclaimer: `The changes made here are exploratory only. To make official changes or explore how other 
      factors impact your account, such as adding additional accounts, changing savings rates, and adjusting 
      retirement age, please access advisory services via your plan web sites "Get Investment Advice" link 
      after enrollment.`
    },
    confirmEnrollment: {
      acknowledge: `I agree to the Fees and I acknowledge that I accept Voya Retirement Advisors’
        <a href="{{privacyPolicyLink}}" target="_blank">{{privacyPolicyName}}</a>,
        <a href="{{disclosureStatementLink}}" target="_blank">{{disclosureStatementName}}</a> and
        <a href="{{advisoryServicesAgreementLink}}" target="_blank">{{advisoryServicesAgreementName}}</a>. We will begin 
        deducting the Professional Management program fees from your account after the initial 90 day Special Offer period has ended. 
        You can cancel at any time. All advisory services are provided 
        by Voya Retirement Advisors, LLC (VRA), a federally registered investment advisor. Neither Voya Retirement Advisors 
        nor Morningstar Investment Management LLC guarantees future results. You should review the fund fact sheet/prospectus, 
        which is available from your plan’s web site, before you invest.`,
      bannerText: 'Confirm Enrollment',
      buttonBack: 'Back',
      buttonConfirm: 'Confirm Enrollment',
      description: `Confirm your enrollment in Professional Management today and let us guide you to the strategy we created for you.`,
      title: "You're almost there!"
    }
  },
  footer: {
    disclaimers: `<p class="ee__body-text--s">This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy"
      target="_blank">Privacy Policy</a> and <a href="https://policies.google.com/terms" target="_blank">
      Terms of Service</a> apply.</p>
      <p class="ee__body-text--s"><b>Advisory Services provided by Voya Retirement Advisors, 
      LLC (VRA)</b>. VRA is a member of the Voya Financial (Voya) family of companies.</p>
      <p class="ee__body-text--s">For more information, please read the Voya Retirement 
      Advisors Disclosure Statement and Advisory Services Agreement. and Fees information. 
      These documents may be viewed online by accessing the advisory services link(s) 
      through your plan's web site. You may also request these from a VRA Investment 
      Advisor Representative by calling your plan's information line. VRA has retained 
      Morningstar Investment Management LLC as an independent “financial expert” (as 
      defined in the Department of Labor’s Advisory Opinion 2001-09A) to develop, design, 
      and implement the asset allocations and investment recommendations generated by the 
      Advisory Services.</p>
      <p class="ee__body-text--s">Morningstar Investment Management LLC is a federally 
      registered investment adviser and wholly owned subsidiary of Morningstar, Inc. 
      Neither VRA nor Morningstar Investment Management LLC provides tax or legal advice. 
      If you need tax advice, consult your accountant or if you need legal advice consult 
      your lawyer.</p>
      <p class="ee__body-text--s">Future results are not guaranteed by VRA, Morningstar 
      Investment Management LLC or any other party and past performance is no guarantee 
      of future results. The Morningstar name and logo are registered trademarks of 
      Morningstar, Inc. All other marks are the exclusive property of their respective 
      owners. Morningstar Investment Management LLC and Morningstar, Inc. are not members 
      of the Voya family of companies.</p>`,
    disclaimersOfferedBy: '',
    footerLinks: {
      /* This footer links is used to show client specific content
      /* for AMA users from landing page onwards when isAMA flag is true in case valid url parameters exists.
      /* And also for nonAMA users from advice page onwards after verify api call.*/
      methodology: {
        name: 'Methodology',
        type: 'modal',
        modal: METHODOLOGY,
        title: 'Methodology',
        show: true
      },
      privacyPolicy: {
        id: 'privacyPolicyUrl',
        name: 'Privacy Policy',
        type: 'link',
        url: 'https://www.voya.com/privacy-notice',
        show: true
      },
      disclosureStatement: {
        id: 'disclosureStatementUrl',
        name: 'Disclosure Statement',
        type: 'link',
        url:
          'https://work-rm-compliance-docs.s3.amazonaws.com/VRA+ADV+2A+03+17+2023.pdf',
        show: true
      },
      advisoryServicesAgreement: {
        id: 'advisoryServicesAgreementUrl',
        name: 'Advisory Services Agreement',
        type: 'link',
        url:
          'https://work-rm-compliance-docs.s3.amazonaws.com/Voya_Mstar+Advisory+Services+Agreement.pdf',
        show: true
      },
      conversationStarters: {
        id: 'conversationStartersUrl',
        name: 'Conversation Starters',
        type: 'link',
        url:
          'https://work-rm-compliance-docs.s3.amazonaws.com/Form_ADV_Part_3_Conversation_Starters.pdf',
        show: false
      },
      contactUs: {
        name: 'Contact Us',
        type: 'modal',
        modal: CONTACT_US,
        title: 'Contact Us',
        show: true
      },
      advisoryAgreement: {
        show: false
      },
      firmBrochure: {
        show: false
      },
      relationshipSummary: {
        show: false
      }
    }
  }
};
