<template>
  <img
    :alt="getImageName"
    :src="require(`@/assets/images/${getImagePath}.svg`)"
    :data-ee-test="`ee-image-${getImageName}`"
  />
</template>

<script>
import store from '@/simpleStore';

export default {
  props: {
    name: {
      type: String,
      description: 'Image name',
      default: null
    },
    path: {
      type: String,
      description: 'path for accessiblity',
      required: true
    },
    isLogo: {
      type: Boolean,
      description:
        'Set to "true", if SVGs are loaded outside assets/images/client/default folder. For eg. client Logo(s)'
    },
    isClientSpecificSVG: {
      type: Boolean,
      description:
        'Set to "true", if SVGs needed to be load from client specific folder',
      default: false
    }
  },
  computed: {
    getImageName() {
      return this.name || this.path;
    },
    getImagePath() {
      if (this.isLogo) {
        return `${this.path}`;
      }
      let folderPath = 'default';
      if (this.isClientSpecificSVG) {
        folderPath = store.getClientId().toLowerCase();
      }
      return `client/${folderPath}/${this.path}`;
    }
  }
};
</script>
