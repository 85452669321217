export default {
  footer: {
    footerLinks: {
      advisoryAgreement: {
        title: 'Intellicents Advisory Agreement'
      },
      privacyPolicy: {
        url:
          '/Merged_docs/privacy_policy/INTELLICENTS_DMP_privacy_policy_merge.pdf'
      },
      firmBrochure: {
        url:
          '/Merged_docs/firm_brochure/INTELLICENTS_DMP_firm_brochure_merge.pdf'
      },
      relationshipSummary: {
        url:
          '/Merged_docs/relationship_summary/INTELLICENTS_DMP_relationship_summary_merge.pdf'
      },
      conversationStarters: {
        url:
          '/Merged_docs/conversation_starters/INTELLICENTS_DMP_conversation_starters_merge.pdf'
      }
    }
  },
  advisorFirmInfo: {
    advisorFirmName: 'Intellicents Investment Solutions Inc'
  }
};
