<template>
  <EeContentWrapper>
    <AdviceIntro />
    <ManagedAccounts />
  </EeContentWrapper>
</template>

<script>
import store from '@/simpleStore';
import EeContentWrapper from './layout/ee-content-wrapper/';
import AdviceIntro from './AdviceIntro';
import ManagedAccounts from './ManagedAccounts';
import { mapActions } from 'vuex';

export default {
  components: {
    EeContentWrapper,
    AdviceIntro,
    ManagedAccounts
  },
  created() {
    localStorage.setItem('showDefaultLandingLinks', `${!store.isAMA()}`);
    this.setDefaultHeroTitle();
  },
  methods: {
    ...mapActions({
      setHeroText: 'setHeroText'
    }),
    setDefaultHeroTitle() {
      const heroText = this.$contentTransposer('hero.title');

      this.setHeroText(heroText);
    }
  }
};
</script>
