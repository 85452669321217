<template>
  <div class="retirement-income">
    <MdsLayoutGrid>
      <MdsRow align-horizontal="center" class="content">
        <MdsCol
          :cols="12"
          :cols-at-m="6"
          align-vertical="center"
          class="advice-svg"
        >
          <SVGImage
            path="retirement-income-illustration"
            name="advice-page-illustration"
            class="advice-svg--resize"
          />
        </MdsCol>
        <MdsCol :cols="12" :cols-at-m="6" class="text">
          <h3
            v-html="getProposedRetirementAgeAndIncomeText"
            class="text--projection"
            data-ee-test="advice-proposed-retirement-age-and-income"
          />
          <p
            v-if="retirementAdviceDesc"
            v-html="retirementAdviceDesc"
            class="ee__body-text--s"
          />
          <ul
            v-if="retirementOptions && retirementOptions.length"
            class="ee__body-text--s"
          >
            <li
              :key="idx"
              v-for="(option, idx) in retirementOptions"
              v-html="option"
            />
          </ul>
          <ProjectionDisclosure show-full-text-method="scrollTo" />
          <p>
            <MdsButton
              @click="confirmEnrollment()"
              :disabled="isButtonDisabled"
              variation="primary"
              size="touch"
              data-ee-test="retirement-income-enrollment-button"
            >
              {{ $contentTransposer('button.enrollNow') }}
            </MdsButton>
          </p>
        </MdsCol>
      </MdsRow>
    </MdsLayoutGrid>
  </div>
</template>

<script>
import { MdsLayoutGrid, MdsRow, MdsCol } from '@mds/layout-grid';
import { MdsButton } from '@mds/button';
import { FAIR_MARKET } from '../../constants';
import ProjectionDisclosure from './ProjectionDisclosure';
import { mapGetters } from 'vuex';

export default {
  components: {
    MdsLayoutGrid,
    MdsRow,
    MdsCol,
    MdsButton,
    ProjectionDisclosure
  },
  props: {
    isButtonDisabled: { type: Boolean, required: true }
  },
  computed: {
    currentAdviceStrategy() {
      return this.getProposalAdvice().CurrentAdviceStrategy;
    },
    proposedAdviceStrategy() {
      return this.getProposalAdvice().ProposedAdviceStrategy;
    },
    retirementAge() {
      return this.proposedAdviceStrategy
        ? this.proposedAdviceStrategy.RetirementAge
        : '';
    },
    getProposedRetirementAgeAndIncomeText() {
      return this.$contentTransposer(
        'advice.retirementIncome.proposedRetirementAgeAndIncomeText',
        {
          retirementAge: this.retirementAge,
          proposedRetirementIncome: this.getProposedRetirementIncome()
        }
      );
    },
    retirementAdviceDesc() {
      return this.$contentTransposer('advice.retirementAdvice.description');
    },
    retirementOptions() {
      return this.$contentTransposer('advice.retirementAdvice.options');
    }
  },
  methods: {
    ...mapGetters({
      getProposalAdvice: 'getProposalAdvice'
    }),
    confirmEnrollment() {
      this.$emit('confirm-enrollment', 'Top');
    },
    getProposedRetirementIncome() {
      const fairMarketIncome = this.proposedAdviceStrategy
        ? this.proposedAdviceStrategy.EstimatedRetirementIncome[FAIR_MARKET]
        : '';
      return fairMarketIncome
        ? Math.round(fairMarketIncome / 12).toLocaleString('en')
        : 0;
    },
    getCurrentRetirementIncome() {
      const fairMarketIncome = this.currentAdviceStrategy
        ? this.currentAdviceStrategy.EstimatedRetirementIncome[FAIR_MARKET]
        : '';
      return fairMarketIncome
        ? Math.round(fairMarketIncome / 12).toLocaleString('en')
        : 0;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/client/default/retirement-income.scss';
@import '@/assets/css/client/ingg/retirement-income.scss';
</style>
