export default {
  footer: {
    footerLinks: {
      advisoryAgreement: {
        url:
          'https://work-rm-compliance-docs.s3.amazonaws.com/Advisory_Agreement_TRPX.pdf'
      }
    }
  }
};
