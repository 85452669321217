// field ids
export const LAST_NAME = 'lastName';
export const ENTRY_CODE = 'entryCode';
export const DATE_OF_BIRTH = 'dateOfBirth';

// error ids
export const INVALID_LENGTH_ERROR_ID = 'length';
export const INVALID_VALUE_ERROR_ID = 'value';
export const INVALID_DAY_ERROR_ID = 'day';
export const INVALID_MONTH_ERROR_ID = 'month';
export const INVALID_YEAR_ERROR_ID = 'year';
export const INVALID_GENERIC_ERROR_ID = 'generic';

export const errorMessages = {
  [LAST_NAME]: {
    [INVALID_VALUE_ERROR_ID]: 'Please enter valid last name.'
  },
  [ENTRY_CODE]: {
    [INVALID_LENGTH_ERROR_ID]:
      'Your entry code should not exceed 15 characters.',
    [INVALID_VALUE_ERROR_ID]:
      'Your entry code should not contain special characters.',
    [INVALID_GENERIC_ERROR_ID]: 'Invalid entry code, please try again'
  },
  [DATE_OF_BIRTH]: {
    [INVALID_VALUE_ERROR_ID]:
      'Enter your date of birth in this format: MM/DD/YYYY.',
    [INVALID_DAY_ERROR_ID]: 'Enter a valid day value.',
    [INVALID_MONTH_ERROR_ID]: 'Enter a valid month value.',
    [INVALID_YEAR_ERROR_ID]: 'Enter a valid year value.'
  }
};

export const FAIR_MARKET = 'Fair Market';

// Footer Modal Names
export const ADVISORY_AGREEMENT = 'advisoryAgreement';
export const PRIVACY_POLICY = 'privacyPolicy';
export const METHODOLOGY = 'methodology';
export const CONTACT_US = 'contactUs';

export const CLIENTS_WITH_AMA_CONFIG = ['INGG', 'SCHWAB'];

//Client List
export const CLIENTS = [
  'INGG',
  'TRPX',
  'SGST',
  'USIG',
  'MILL',
  'DEVP20',
  'SCHWAB',
  'ADPRK'
];

// Advisory Firm Id list
export const ADVISORY_FIRM_ID = [
  'BFSG_DMP',
  'CAPTRUST_DMP',
  'CBIZ_DMP',
  'FISHER_DMP',
  'GRP_DMP',
  'HUB_DMP',
  'INTELLICENTS_DMP',
  'LOCKTON_DMP',
  'MARINER_DMP',
  'MESIROW_DMP',
  'NFP_DMP',
  'PENSIONMARK_DMP',
  'RESOURCES_DMP',
  'SAGEVIEW_DMP',
  'SOLTIS_DMP'
];

// Asset Classes Labels For UI
export const assetClass = {
  LargeCap: 'Large Cap Stock',
  MidCap: 'Mid-/Small-Cap Stock',
  International: 'International Stock',
  FixedIncome: 'Bonds',
  Cash: 'Cash Equivalents'
};

// Timer
export const SESSION_WARNING_TIMER = 600000;
export const SESSION_IDLE_TIMER = 900000;

export const CONTRIBUTION_TYPES_INFO = [
  { type: 'Pretax', IsRoth: false, messageText: 'Pre-Tax' },
  { type: 'PostTax', IsRoth: false, messageText: 'Post-Tax' },
  { type: 'Roth', IsRoth: true, messageText: 'Roth' },
  {
    type: 'Last3YearCatchup',
    IsRoth: false,
    messageText: 'Last 3 Year Catch-Up'
  },
  {
    type: 'Last3YearCatchup',
    IsRoth: true,
    messageText: 'Roth Last 3 Year Catch-Up'
  },
  {
    type: 'Item50YrCatchup',
    IsRoth: false,
    messageText: '50 Year Catch-Up'
  },
  {
    type: 'Item50YrCatchup',
    IsRoth: true,
    messageText: 'Roth 50 Year Catch-Up'
  },
  {
    type: 'LongTermCatchup',
    IsRoth: false,
    messageText: 'Long Term Catch-Up'
  },
  {
    type: 'LongTermCatchup',
    IsRoth: true,
    messageText: 'Roth Long Term Catch-Up'
  }
];

export const BLOCK_CLIENTS = ['SGST'];

export const ENROLLMENT_PATH = '/easy-enroll-enrollment';

export const VERIFY_PATH = '/easy-enroll-verify';

export const REVISED_STRATEGIES = '/easy-enroll/revisedStrategies';

export const VERIFY_PICKED_INFO = '/easy-enroll/pickedInfo';

export const ENV = {
  LOCALHOST: 'development',
  DEV: 'dev',
  QA: 'qa',
  UAT: 'uat',
  PROD: 'prod',
  PROD_DR: 'prod-dr'
};

export const UI_BASE_URL_QA = 'https://retirementmanager-qa.morningstar.com';

// Engagement Platform
export const EP_TRACKING_ADD_PATH = '/engagement-platform/addTracking';
export const EP_ENROLLMENT_CREATE_PATH =
  '/engagement-platform/createEnrollment';

export const PAGE_ID = {
  VERIFY: 1,
  ADVICE: 2,
  ENROLL: 3
};

export const VRA_DONUT_CHART_COLORS = [
  '#D75426', // Large Cap Stock - Dark Orange
  '#145A7B', // Mid-/Small-Cap Stock - Dark Blue
  '#6E6E6E', // International Stocks - Gray
  '#551B57', // Bonds - Plum
  '#0097A9', // Cash Equivalents - Teal
  '#B73F7C' // Other - Violet
];

export const DEFAULT_DONUT_CHART_COLORS = [
  '#1f55a5',
  '#a50032',
  '#f5c400',
  '#518428',
  '#00a8e1',
  '#6a4c9e',
  '#ef7622'
];
