<template>
  <mds-modal
    @mds-modal-dismissed="closeModal($event)"
    v-model="showModalFlag"
    :title="content.title"
    width="600px"
    class="ee-modal client-content-modal printable-area"
  >
    <template v-slot:mds-modal-actions>
      <MdsButton
        @click="print()"
        class="print-btn print-btn-margin"
        data-ee-test="print-btn"
        variation="icon-only"
        icon="print"
        type="button"
      />
      <MdsButton
        @click="closeModal(false)"
        data-ee-test="close-modal-btn"
        variation="icon-only"
        icon="remove"
        type="button"
      />
    </template>
    <div id="print" class="footer-modal-content">
      <div v-if="content.name === CONTACT_US">
        <ContactUs />
      </div>
      <div v-html="content.body"></div>
    </div>
  </mds-modal>
</template>

<script>
import MdsModal from '@mds/modal';
import { MdsButton } from '@mds/button';
import ContactUs from './ContactUs';
import { cloneDeep } from 'lodash';
import { CONTACT_US } from '../../../constants';

export default {
  components: {
    MdsModal,
    MdsButton,
    ContactUs
  },
  props: {
    content: {
      type: Object,
      required: true
    },
    model: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      toggle: false,
      showModalFlag: cloneDeep(this.model),
      CONTACT_US
    };
  },
  methods: {
    closeModal($event) {
      this.$emit('footer-modal-dismissed', $event);
    },
    print() {
      window.print();
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/client/default/rm-ui-footer-modals.scss';
@import '@/assets/css/client/default/client-content-modal.scss';
</style>
