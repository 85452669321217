var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"custom-saving-recommendation"},[(_vm.showDescription)?_c('p',[_vm._v(" Input your salary and current savings rate to calculate our recommended savings rate. ")]):_vm._e(),(!_vm.proposedSavingsRate)?_c('MdsInput',{staticClass:"input",style:({ marginTop: '15px' }),attrs:{"id":"salaryInput","microcopy-above":!_vm.proposedSavingsRate
        ? 'Input your salary to calculate your recommended saving.'
        : '',"microcopy-below":'We\'ll use this custom salary for today and all future sessions. You can update your salary information at any time post enrollment by visiting your plan provider website.',"size":"large","label":"Salary","required":""},on:{"input":function (value) { return _vm.handleSalaryChange(value); },"keypress":function($event){return _vm.handleKeyPress($event)}},model:{value:(_vm.salary),callback:function ($$v) {_vm.salary=$$v},expression:"salary"}}):_vm._e(),(!_vm.invalidSalary)?_c('MdsAlert',{attrs:{"id":"invalidSalary","variation":"error","title":"Input Error","text":"Invalid Salary, please try again. (Salary cannot be negative value)","persistent":""}}):_vm._e(),(!_vm.currentSavingsRate)?_c('MdsInput',{staticClass:"input",attrs:{"id":"savingRateInput","microcopy-above":_vm.isActive && !_vm.currentSavingsRate
        ? 'Input your saving rate to calculate your recommended saving.'
        : '',"disabled":!_vm.isActive,"microcopy-below":!_vm.isActive && _vm.isSuspended
        ? 'Your Employer has suspended your ability to contribute to your ' +
          'Account(s). However, you can still open and/or contribute to Your ' +
          'Other Accounts to get closer to your retirement goal. Do so by ' +
          'visiting the Accounts section within the full Retirement Manager ' +
          'experience through your plan provider website.'
        : !_vm.isActive && (_vm.isInactive || _vm.isTermed)
        ? 'You cannot contribute to this account anymore, because you no longer ' +
          'work for the Employer that sponsored it. However, you can still open ' +
          'and/or contribute to other accounts to get closer to your retirement goal.'
        : '',"required":_vm.isActive,"size":"large","label":"Savings Rate"},on:{"input":function (value) { return _vm.handleSavingRateChange(value); },"keypress":function($event){return _vm.handleKeyPress($event)},"keydown":function($event){return _vm.handleKeyDownRate($event)}},model:{value:(_vm.savingRate),callback:function ($$v) {_vm.savingRate=$$v},expression:"savingRate"}}):_vm._e(),(!_vm.invalidSavingRate)?_c('MdsAlert',{attrs:{"id":"invalidSavingRate","variation":"error","title":"Input Error","text":"Invalid Savings Rate, please try again. (Rate should be between 0 - 100)","persistent":""}}):_vm._e(),_c('div',{staticClass:"action"},[(!_vm.getSavingRecommendationShowLoader)?_c('mds-button',{attrs:{"disabled":_vm.isButtonDisabled,"loading":"","size":"touch"},on:{"click":function($event){return _vm.handleClick()}}},[_vm._v(" Calculate ")]):_c('MdsLoader',{attrs:{"size":"small"}})],1),_c('p',[_vm._v(" Based on your inputs, we recommend you "),_c('strong',[_vm._v("save "+_vm._s(_vm.calculatedSavingRecommendation)+" of each paycheck")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }