import store from '@/simpleStore';
import apiService from '../../components/service/api-service';
import {
  EP_TRACKING_ADD_PATH,
  EP_ENROLLMENT_CREATE_PATH
} from '../../constants';

export const epTracking = async (cusId, pageId, sessionId) => {
  const enrollmentCode =
    sessionStorage.getItem('EnrollmentCode') ||
    sessionStorage.getItem('URLEntryCode');
  const clientId = store.getCustomizationId();
  if (!clientId || !enrollmentCode) {
    return;
  }
  try {
    await apiService.postAPIRequest(EP_TRACKING_ADD_PATH, {
      cus_id: cusId,
      page_id: pageId,
      client_id: clientId,
      session_id: sessionId,
      enrollment_code: enrollmentCode
    });
  } catch (error) {
    console.log('Error epTracking', error);
  }
};

export const epEnrollment = async (cusId, sessionId) => {
  try {
    await apiService.postAPIRequest(EP_ENROLLMENT_CREATE_PATH, {
      cus_id: cusId,
      enrollment_status: 'Enrolled',
      enrollment_type: '4',
      enrollment_date: new Date().toISOString(),
      session_id: sessionId
    });
  } catch (error) {
    console.log('Error epEnrollment', error);
  }
};
