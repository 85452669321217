import Vue from 'vue';
import Router from 'vue-router';
import landing from '@/components/landing';
import advice from '@/components/Advice/index';
import enrollment from '@/components/Enrollment/index';
import advisoryAgreement from '@/components/AdvisoryAgreement/index';
import alreadyEnrolled from '@/components/AlreadyEnrolled/index';
import errorPage from '@/components/ErrorPage/index';
import store from '@/simpleStore';

const guardRoute = (to, from, next) => {
  if (to.name !== 'landing' && !store.getEnrollmentInfo()) {
    if (to.name === 'advice' || to.name === 'enrollment') {
      next({
        path: '/',
        query: to.query,
        replace: true
      });
    } else {
      next({
        path: '/',
        query: store.getQueryParams(),
        replace: true
      });
    }
  } else {
    next();
  }
};

Vue.use(Router);

const getRoutes = () => {
  return [
    {
      path: '/',
      name: 'landing',
      component: landing
    },
    {
      path: '/advice',
      name: 'advice',
      component: advice,
      beforeEnter: guardRoute
    },
    {
      path: '/enrollment',
      name: 'enrollment',
      component: enrollment,
      beforeEnter: guardRoute
    },
    {
      path: '/advisoryAgreement',
      name: 'advisoryAgreement',
      component: advisoryAgreement
    },
    {
      path: '/alreadyEnrolled',
      name: 'alreadyEnrolled',
      component: alreadyEnrolled,
      beforeEnter: guardRoute
    },
    {
      path: '/errorPage',
      name: 'errorPage',
      component: errorPage,
      beforeEnter: guardRoute
    }
  ];
};

export default new Router({
  routes: getRoutes(),
  scrollBehavior() {
    document.getElementById('app').scrollIntoView();
  }
});
