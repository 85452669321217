export default {
  footer: {
    disclaimersOfferedBy: `<p class="ee__body-text--s">The Morningstar name and logo are registered marks of
        Morningstar, Inc. Managed Accounts, Advice, and Guidance (the “Services”) are offered by Morningstar Investment
        Management LLC and are intended for citizens or legal residents of the United States or its territories.  The
        portfolios available to you through the Services were created by an investment adviser (the “IA”) chosen by
        your employer.  Morningstar Investment Management LLC, a registered investment adviser and subsidiary of
        Morningstar, Inc. is responsible for choosing the portfolio for your retirement plan account from those created
        by the IA. This website is an online platform designed to help retirement plan participants make more informed decisions
        about investing in their employer-sponsored retirement accounts. It includes three Service options – Managed
        Accounts, Advice, and Guidance. The Services available to you are chosen by your employer and may be branded under
        different names chosen by our clients. These names include, but are not limited to, “Managed by Morningstar”
        (Managed Accounts), “Managed by You” (Advice), or “Personalized Portfolios” (Managed Accounts). If you access a version
        of our platform with customized Service names, please note that we use “Managed Accounts”, “Advice”, and “Guidance”
        throughout our disclosures and other documents to describe the Services, but the information included still applies
        to your Service regardless of the name shown in this website. Please contact your plan sponsor, plan provider, or
        Morningstar Investment Management if you are unsure what Service(s) apply to you.</p>`,
    footerLinks: {
      advisoryAgreement: {
        title: 'Resources Investment Advisors, Inc. Advisory Agreement',
        url:
          'https://work-rm-compliance-docs.s3.amazonaws.com/Advisory+Agreement_Resources.pdf'
      },
      privacyPolicy: {
        url:
          '/Merged_docs/privacy_policy/RESOURCES_DMP_privacy_policy_merge.pdf'
      },
      firmBrochure: {
        url: '/Merged_docs/firm_brochure/RESOURCES_DMP_firm_brochure_merge.pdf'
      },
      relationshipSummary: {
        url:
          '/Merged_docs/relationship_summary/RESOURCES_DMP_relationship_summary_merge.pdf'
      },
      conversationStarters: {
        url:
          '/Merged_docs/conversation_starters/RESOURCES_DMP_conversation_starters_merge.pdf'
      }
    }
  },
  advisorFirmInfo: {
    productName: 'Personalized Portfolios',
    advisorFirmName: 'Resources Investment Advisors, LLC'
  }
};
