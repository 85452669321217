export default {
  advice: {
    strategy: {
      contents: [
        `The professionals at Morningstar Investment Management LLC will continue to monitor your retirement
          accounts and update them as needed, so your account can work harder for you on an ongoing basis to
          help you meet your retirement goals.`
      ]
    }
  }
};
