import { get, isEmpty, cloneDeep } from 'lodash';
import store from '../store';

/**
 * The regexSafe replaces the $ with $$$$ for IE bug.
 * Used this function from Ember application's i18next lib
 * NOTE: Other browsers are working fine with the regexSafe function.
 * @param {String} val The dynamic Content key
 */
const regexSafe = val =>
  typeof val === 'string' ? val.replace(/\$/g, '$$$$') : val;

export default {
  install(Vue) {
    // Add $contentTransposer instance method directly to Vue components
    Vue.prototype.$contentTransposer = (path = '', dynamicContent = {}) => {
      const content = get(store.state.content, path) || '';
      if (isEmpty(dynamicContent)) {
        return content;
      }
      let transposedContent = cloneDeep(content);

      Object.keys(dynamicContent).forEach(key => {
        const regExp = new RegExp(`{{${key}}}`, 'g');
        if (typeof transposedContent === 'object') {
          transposedContent = transposedContent.toString();
        }
        transposedContent = transposedContent.replace(
          regExp,
          regexSafe(dynamicContent[key])
        );
      });
      return transposedContent;
    };
  }
};
